/* eslint-disable */
import { Form, Modal, Switch, Input, Button } from 'antd';
import _, { last } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTable from '../../../../../common/CustomTable/CustomTable';
import FormFields from '../../../../../common/FormFields/FormFields';
import Line from '../../../../../common/Graphs/Line/Line';
import Loader from '../../../../../common/Loader/Loader';
import { DownloadOutlined } from '@ant-design/icons';
import {
  validateNonNegativeFloat,
  validatePositiveFloat,
} from '../../../../../common/methods';
import ConfirmModal from '../../../../../common/Modal/Modal';
import Notification, {
  Types,
} from '../../../../../common/Notification/Notification';
import {
  CurvesMessages,
  DISCARD_CHANGES,
  FORM_DISCARD_MSG,
} from '../../../../../constants/messages';
import { ButtonVariables } from '../../../../../constants/variables';
import * as coefficient from '../../../../../libs/coefficient';
import { getDataUnits } from '../../../../../redux/actions/dataUnit.action';
import { updateFormEdit } from '../../../../../redux/actions/formEdit.action';
import { toggleDetectState } from "../../../../../redux/actions/curve.action";
import {
  AsTesteCurveFormField,
  AsTesteCurveFormFieldOperational,
  AsTesteCurveFormFieldOperationalPerc,
  asTestedCurves,
  COMPONENT,
  curvesLabel,
  curvesTableAddRow,
  curvesTableColumns,
  dataUnitsVariables,
  graphDetails,
  operationalBaseLineCurves,
  curveInput,
  curveVariables,
  AsTesteCurveFormFieldSteamTurbine,
  curvesTableColumnsSteamTurbine,
  curvesTableAddRowSteamTurbine,
  AsTesteCurveFormFieldOperationalSteamTurbine,
} from './Curves.constants';
import './Curves.scss';
import { CSVLink, CSVDownload } from 'react-csv';

/**
 * @class Curve
 * @classdesc Resuable Component for Curves
 */

class Curve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastBaseLineData: [],
      percentageDeviation: {
        volumetricFlow: '',
        headDrop: '',
        efficiency: '',
        lastOperationalBaseLineCurvesData: [],
      },
      undoClicked: false,
      resetClicked: false,
      switchValue: false,
      applyClicked: false,
      modalClicked: false,
      preserveCurveData: {},
      editableRows: {
        asTestedCurves: [],
        operationalBaseLineCurves: [],
      },
      updatedFields: {
        asTestedCurves: [],
        operationalBaseLineCurves: [],
      },
      dirtyRows: {
        asTestedCurves: [],
        operationalBaseLineCurves: [],
      },
      asTestedCurves: [],
      operationalBaseLineCurves: [],
      addFieldValue: {
        volumetricFlow: '',
        headDrop: '',
        efficiency: '',
        flowCoefficient: '',
        headCoefficient: '',
        workInput: '',
        work: ''
      },
      addFieldValueBaseLine: {
        volumetricFlow: '',
        headDrop: '',
        efficiency: '',
        flowCoefficient: '',
        headCoefficient: '',
        workInput: '',
        work: ''
      },
      isError: {
        volumetricFlow: false,
        headDrop: false,
        efficiency: false,
      },
      isErrorBaseLine: {
        volumetricFlow: false,
        headDrop: false,
        efficiency: false,
      },
      unitData: [],
      runnerData: [],
      runnerType: null,
      visible: false,
      visibleGraph: false,
      visibleDelete: false,
      visibleOperational: false,
      volumetricFlowSI: 'value',
      headDropSI: 'value',
      efficiencySI: 'value',
      speedSI: 'value',
      visibleModal: false,
      xAxisValue: '',
      yAxisValue: '',
    };
  }

  /**
   *
   * @param {*} status
   */
  updateFormEdit(status) {
    this.props.updateFormEdit(status);
    const { asTestedCurves, operationalBaseLineCurves } =
      this.state.editableRows;
    if (
      status === false &&
      (asTestedCurves.length || operationalBaseLineCurves.length)
    ) {
      this.clearDirtyForm();
    }
  }

  // Duplicate Curve
  asTestedToOpertaional = () => {
    if (this.state.asTestedCurves.length) {
      if (this.state.dirtyRows.asTestedCurves.length === 0) {
        this.setState({
          visibleOperational: true,
        });
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    } else {
      Notification.show(Types.Info, CurvesMessages.DUPLICATE_MSG);
    }
  };

  /**
   *
   */
  handleCancelOperational() {
    this.setState({ visibleOperational: false });
  }

  /**
   *
   */
  handleOkOperational() {
    this.setState({ visibleOperational: false }, () => {
      this.setState({
        operationalBaseLineCurves: this.state.asTestedCurves,
        dirtyRows: {
          asTestedCurves: [],
          operationalBaseLineCurves: [],
        },
      });
      if (!(this.props.fixedSpeedCurve && this.props.curveId !== 'curve1')) {
        this.props.form.validateFields((error, value) => {
          this.props.form.setFieldsValue({
            speedOperational: value.speed,
          });
        });
      }
    });
  }

  /**
   *
   */
  renderOperationalModal() {
    if (this.state.visibleOperational) {
      return (
        <ConfirmModal
          title={CurvesMessages.COPY_TITLE}
          visible={this.state.visibleOperational}
          handleOk={() => this.handleOkOperational()}
          handleCancel={() => this.handleCancelOperational()}
          message={CurvesMessages.COPY_MSG}
        />
      );
    } else {
      return null;
    }
  }

  /**
   *
   */
  handleModalclick = (e) => {
    var path = e.path || (e.composedPath && e.composedPath());

    if (
      path &&
      path[3]?.innerText?.slice(0, 18) === 'Confirm Navigation' &&
      e.target.innerText === 'Proceed'
    ) {
      this.clearDirtyForm();
      this.clearPerDevFields(true);
    }
  };
  componentWillUnmount() {
    window.removeEventListener('click', this.handleModalclick, false);
  }
  componentDidMount() {
    const { curveId, curvesData, impellerData, dataUnitState } = this.props;
    const { unitData } = dataUnitState.apiState;

    this.setState(
      {
        preserveCurveData: curvesData[curveId],
        unitData: unitData && unitData.items ? unitData.items : [],
      },
      () => {
        this.updateFormEdit(false);
        this.setState({
          runnerData: this.getRunnerData(),
          runnerType:
            impellerData && impellerData.length ? impellerData[0].type : null,
        });
        const { asTestedCurves, operationalBaseLineCurves } =
          this.state.preserveCurveData;
        this.setCurveData(
          _.cloneDeep(asTestedCurves),
          _.cloneDeep(operationalBaseLineCurves)
        );
      }
    );
    if (!this.state.modalClicked) {
      window.addEventListener('click', this.handleModalclick, false);
    }
  }

  extractCurrentCurveData(curveId) {
    this.setState({
      percentageDeviation: {
        ...this.state.percentageDeviation,
        lastOperationalBaseLineCurvesData:
          this.props.curvesData[curveId].operationalBaseLineCurves.data,
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { curveId } = this.props;
    if (this.props.curvesData[curveId].operationalBaseLineCurves) {
      if (
        this.state.percentageDeviation.lastOperationalBaseLineCurvesData !==
        this.props.curvesData[curveId].operationalBaseLineCurves.data
      ) {
        this.extractCurrentCurveData(curveId);
      }
    }

    const { name } = this.props.location.state.componentData.assetInfo;
    if (prevProps.process !== this.props.process) {
      this.updateFormEdit(true);
    }
    if (
      prevProps.application !== this.props.application &&
      name === COMPONENT.EXPANDER
    ) {
      this.updateFormEdit(true);
    }
    if (
      prevProps.curvesData !== this.props.curvesData ||
      prevProps.activeKey !== this.props.activeKey
    ) {
      this.clearDirtyForm();
      this.clearPerDevFields(true)
      const { curveId, curvesData, impellerData, dataUnitState } = this.props;
      const { unitData } = dataUnitState.apiState;
      const { asTestedCurves, operationalBaseLineCurves } = curvesData[curveId];
      this.setState(
        {
          preserveCurveData: curvesData[curveId],
          unitData: unitData && unitData.items ? unitData.items : [],
        },
        () => {
          this.updateFormEdit(false);
          this.setState({
            runnerData: this.getRunnerData(),
            runnerType:
              impellerData && impellerData.length ? impellerData[0].type : null,
          });
          this.setCurveData(
            _.cloneDeep(asTestedCurves),
            _.cloneDeep(operationalBaseLineCurves)
          );
        }
      );
    }
  }

  /**
   *
   * @param {*} units
   */
  convertUnitToSI(units) {
    const { efficiency, headDrop, unitSpeed, volumetricFlow } = units;
    if (efficiency && headDrop && unitSpeed && volumetricFlow) {
      const { unitMapper } = this.props.dataUnitState.apiState;
      this.setState({
        volumetricFlowSI:
          !_.isEmpty(unitMapper) &&
          unitMapper[dataUnitsVariables.volumetricFlow][volumetricFlow],
        headDropSI:
          !_.isEmpty(unitMapper) &&
          unitMapper[dataUnitsVariables.headDrop][headDrop],
        efficiencySI:
          !_.isEmpty(unitMapper) &&
          unitMapper[dataUnitsVariables.efficiency][efficiency],
        speedSI:
          !_.isEmpty(unitMapper) &&
          unitMapper[dataUnitsVariables.speed][unitSpeed],
      });
    }
  }

  /**
   *
   * @param {*} variableName
   * @param {*} unit
   */
  getUnitInSI(variableName, unit) {
    if (variableName && unit) {
      const { unitMapper } = this.props.dataUnitState.apiState;
      const siValue = unitMapper[variableName][unit];
      return siValue;
    }
    return 'value';
  }

  /**
   *
   * @param {*} asTestedCurves
   * @param {*} operationalBaseLineCurves
   */
  setCurveData(asTestedCurves, operationalBaseLineCurves) {
    if (asTestedCurves && operationalBaseLineCurves) {
      const { speed, dataUnit, identifier } = asTestedCurves;
      const formData = {
        speed:
          (speed && speed.value) || (speed && speed.value === 0)
            ? speed.value + ''
            : '',
        speedOperational:
          (operationalBaseLineCurves['speed'] &&
            operationalBaseLineCurves['speed'].value + '') ||
          (operationalBaseLineCurves['speed'] &&
            operationalBaseLineCurves['speed'].value === 0)
            ? operationalBaseLineCurves['speed'].value + ''
            : '',
        unitSpeed: speed && speed.unit ? speed.unit : '',
        volumetricFlow:
          dataUnit && dataUnit['volumetricFlow']
            ? dataUnit['volumetricFlow']
            : '',
        headDrop: dataUnit && dataUnit['headDrop'] ? dataUnit['headDrop'] : '',
        efficiency:
          dataUnit && dataUnit['efficiency'] ? dataUnit['efficiency'] : '',
        outputPower:
          dataUnit && dataUnit['outputPower'] ? dataUnit['outputPower'] : '',
        throttleSteamMassFlow:
          dataUnit && dataUnit['throttleSteamMassFlow']
            ? dataUnit['throttleSteamMassFlow']
            : '',
        identifierValue: 
          identifier && identifier?.value.toString(),
        unitIdentifier:
          identifier && identifier.unit ? identifier.unit : '',
        workInput:
          dataUnit && dataUnit['workInput']
            ? dataUnit['workInput']
            : '',
      };
      this.setState({
        asTestedCurves: asTestedCurves.data ? [...asTestedCurves.data] : [],
        operationalBaseLineCurves: operationalBaseLineCurves.data
          ? [...operationalBaseLineCurves.data]
          : [],
      });
      this.props.form.setFieldsValue(formData, () => {
        this.convertUnitToSI(formData);
        this.updateFormEdit(false);
      });
    }
  }

  /**
   *
   * @param {*} values
   * @param {*} tableName
   */
  validateFields(values, tableName) {
    const { name } = this.props.location.state.componentData.assetInfo;
    let status = true;
    let temp = { ...values };
    if (tableName === asTestedCurves) {
      if (name !== 'Steam Turbine') {
        Object.keys(temp).forEach((item) => {
          if (
            (item === 'volumetricFlow' ||
              item === 'headDrop' ||
              item === 'efficiency' ||
              item === 'workUnit') &&
            (!temp[item] || !validateNonNegativeFloat(temp[item]))
          ) {
            temp[item] = true;
            status = false;
          } else {
            temp[item] = false;
          }
        });
        this.setState({ isError: temp });
      } else {
        Object.keys(temp).forEach((item) => {
          if (
            (item === 'throttleSteamMassFlow' || item === 'outputPower') &&
            (!temp[item] || !validateNonNegativeFloat(temp[item]))
          ) {
            temp[item] = true;
            status = false;
          } else {
            temp[item] = false;
          }
        });
        this.setState({ isError: temp });
      }
    } else if (tableName === operationalBaseLineCurves) {
      if (name !== 'Steam Turbine') {
        Object.keys(temp).forEach((item) => {
          if (
            (item === 'volumetricFlow' ||
              item === 'headDrop' ||
              item === 'efficiency' ||
              item === 'workUnit') &&
            (!temp[item] || !validateNonNegativeFloat(temp[item]))
          ) {
            temp[item] = true;
            status = false;
          } else {
            temp[item] = false;
          }
        });
        this.setState({ isErrorBaseLine: temp });
      } else {
        Object.keys(temp).forEach((item) => {
          if (
            (item === 'throttleSteamMassFlow' || item === 'outputPower') &&
            (!temp[item] || !validateNonNegativeFloat(temp[item]))
          ) {
            temp[item] = true;
            status = false;
          } else {
            temp[item] = false;
          }
        });
        this.setState({ isError: temp });
      }
    }
    return status;
  }

  /**
   *
   */
  getRunnerData() {
    const { impellerData } = this.props;
    if (impellerData) {
      const { apiState } = this.props.dataUnitState;
      const { unitMapper } = apiState;
      return impellerData.map((item) => {
        const toSI =
          unitMapper[dataUnitsVariables.diameter] &&
          unitMapper[dataUnitsVariables.diameter][item.unit];
        const valueInSI =
          toSI && eval(toSI.replace('value', parseFloat(item.diameter)));
        return valueInSI ? valueInSI : [];
      });
    }
    return [];
  }

  /**
   *
   * @param {*} values
   * @param {*} tableName
   */
  calculateCofficient(values, tableName) {
    let speedValue;
    if (tableName === operationalBaseLineCurves) {
      speedValue = this.props.form.getFieldValue('speedOperational')
        ? parseFloat(this.props.form.getFieldValue('speedOperational'))
        : 0;
    } else {
      speedValue = this.props.form.getFieldValue('speed')
        ? parseFloat(this.props.form.getFieldValue('speed'))
        : 0;
    }
    const { volumetricFlowSI, headDropSI, efficiencySI, speedSI } = this.state;
    const volumetricFlow = eval(
      volumetricFlowSI.replace(
        'value',
        parseFloat(values.volumetricFlow || 0) + ''
      )
    );
    const headDrop = eval(
      headDropSI.replace('value', parseFloat(values.headDrop || 0) + '')
    );
    const speed = eval(
      speedSI.replace('value', parseFloat(speedValue || 0) + '')
    );
    const efficiency = eval(
      efficiencySI.replace('value', parseFloat(values.efficiency || 0) + '')
    );

    const flowCoefficient = coefficient.flowCoefficient(
      volumetricFlow,
      speed,
      this.state.runnerData[0],
      this.state.runnerType
    );
    const headCoefficient = coefficient.headCoefficient(
      headDrop,
      speed,
      this.state.runnerData
    );
    const workInput = coefficient.workInputCoefficient(
      efficiency,
      headCoefficient
    );
    const work = coefficient.workCoefficient(
      values.headDrop,
      values.efficiency
    );
    return {
      volumetricFlow: parseFloat(values.volumetricFlow),
      headDrop: parseFloat(values.headDrop),
      efficiency: parseFloat(values.efficiency),
      flowCoefficient: flowCoefficient,
      headCoefficient: headCoefficient,
      workInput: workInput,
      work: work
    };
  }

  /**
   *
   * @param {*} tableName
   */
  addRow(tableName) {
    const { name } = this.props.location.state.componentData.assetInfo;
    if (
      (this.props.impellerData && this.props.impellerData.length >= 1) ||
      name === 'Steam Turbine'
    ) {
      if (tableName === asTestedCurves) {
        if (this.validateFields(this.state.addFieldValue, asTestedCurves)) {
          const { addFieldValue } = this.state;
          const tableData = this.state.asTestedCurves;
          let coefficients = this.calculateCofficient(
            addFieldValue,
            asTestedCurves
          );
          let data = [...tableData, { ...addFieldValue, ...coefficients }];
          this.setState({
            asTestedCurves: data,
            addFieldValue: {
              ...this.state.addFieldValue,
              volumetricFlow: '',
              headDrop: '',
              efficiency: '',
              flowCoefficient: '',
              headCoefficient: '',
              workInput: '',
              throttleSteamMassFlow: '',
              outputPower: '',
              work: ''
            },
          });
        } else {
          Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
        }
      } else if (tableName === operationalBaseLineCurves) {
        if (
          this.validateFields(
            this.state.addFieldValueBaseLine,
            operationalBaseLineCurves
          )
        ) {
          const { addFieldValueBaseLine } = this.state;
          const tableData = this.state.operationalBaseLineCurves;
          let coefficients = this.calculateCofficient(
            addFieldValueBaseLine,
            operationalBaseLineCurves
          );
          let data = [
            ...tableData,
            { ...addFieldValueBaseLine, ...coefficients },
          ];
          this.setState({
            operationalBaseLineCurves: data,
            addFieldValueBaseLine: {
              ...this.state.addFieldValueBaseLine,
              volumetricFlow: '',
              headDrop: '',
              efficiency: '',
              flowCoefficient: '',
              headCoefficient: '',
              workInput: '',
              throttleSteamMassFlow: '',
              outputPower: '',
              work: ''
            },
          });
        }
      } else {
        Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
      }
    } else {
      Notification.show(
        Types.Info,
        name === COMPONENT.EXPANDER
          ? CurvesMessages.REQUIRED_RUNNER
          : CurvesMessages.REQUIRED_IMPELLER
      );
    }
  }

  /**
   *
   * @param {*} row
   * @param {*} col
   * @param {*} field
   * @param {*} tableName
   */
  onAddFieldChange(row, col, field, tableName) {
    if (tableName === asTestedCurves) {
      const { addFieldValue } = this.state;
      let temp = addFieldValue;
      //TODO
      temp[field.name] = field.value;
      this.setState({ addFieldValue: temp }, () => {
        this.validateFields(this.state.addFieldValue, asTestedCurves);
        this.updateFormEdit(true);
      });
    } else if (tableName === operationalBaseLineCurves) {
      const { addFieldValueBaseLine } = this.state;
      let temp = addFieldValueBaseLine;
      //TODO
      temp[field.name] = field.value;
      this.setState({ addFieldValueBaseLine: temp }, () => {
        this.validateFields(
          this.state.addFieldValueBaseLine,
          operationalBaseLineCurves
        );
        this.updateFormEdit(true);
      });
    }
  }

  /**
   *
   * @param {*} row
   * @param {*} col
   * @param {*} field
   * @param {*} tableName
   */
  onEditFieldChange(row, col, field, tableName) {
    let speed;
    if (tableName === operationalBaseLineCurves) {
      speed = this.props.form.getFieldValue('speedOperational');
    } else {
      speed = this.props.form.getFieldValue('speed');
    }

    const tableData = [...this.state[tableName]];

    let temp = { ...tableData[row] };
    //TODO
    temp[field.name] = field.value;

    const { volumetricFlowSI, headDropSI, efficiencySI, speedSI } = this.state;
    const speedSIValue = eval(speedSI.replace('value', speed));

    if (field.name === 'volumetricFlow') {
      const volumetricFlow = eval(
        volumetricFlowSI.replace(
          'value',
          parseFloat(temp.volumetricFlow || 0) + ''
        )
      );
      temp['flowCoefficient'] = coefficient.flowCoefficient(
        volumetricFlow,
        speedSIValue,
        this.state.runnerData[0],
        this.state.runnerType
      );
    } else if (field.name === 'headDrop') {
      const headDrop = eval(
        headDropSI.replace('value', parseFloat(temp.headDrop || 0) + '')
      );
      const efficiency = eval(
        efficiencySI.replace('value', parseFloat(temp.efficiency || 0) + '')
      );
      temp['headCoefficient'] = coefficient.headCoefficient(
        headDrop,
        speedSIValue,
        [...this.state.runnerData]
      );
      temp['work'] = coefficient.workCoefficient(
        headDrop,
        efficiency
      );
    } else if (field.name === 'efficiency') {
      const efficiency = eval(
        efficiencySI.replace('value', parseFloat(temp.efficiency || 0) + '')
      );
      const headDrop = eval(
        headDropSI.replace('value', parseFloat(temp.headDrop || 0) + '')
      );
      temp['workInput'] = coefficient.workInputCoefficient(
        efficiency,
        temp['headCoefficient']
      );
      temp['work'] = coefficient.workCoefficient(
        headDrop,
        efficiency
      );
    }

    let tempCurveData = [...tableData];
    tempCurveData[row] = { ...temp };
    if (tableName === operationalBaseLineCurves) {
      this.setState(
        {
          operationalBaseLineCurves: tempCurveData,
        },
        () => {
          this.updateFormEdit(true);
          this.setDirtyRows(tableName, row);
        }
      );
    } else if (tableName === asTestedCurves) {
      this.setState(
        {
          asTestedCurves: tempCurveData,
        },
        () => {
          this.updateFormEdit(true);
          this.setDirtyRows(tableName, row);
        }
      );
    }
  }

  /**
   *
   * @param {*} tableName
   * @param {*} row
   */
  setDirtyRows(tableName, row) {
    const { dirtyRows } = this.state;
    let tableData = _.cloneDeep(this.state[tableName]);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    if (
      (tableData[row].volumetricFlow &&
        validatePositiveFloat(tableData[row].volumetricFlow + '', 1) &&
        tableData[row].headDrop &&
        validatePositiveFloat(tableData[row].headDrop + '', 2) &&
        tableData[row].efficiency &&
        validatePositiveFloat(tableData[row].efficiency + '', 3)) ||
      (tableData[row].outputPower &&
        validatePositiveFloat(tableData[row].outputPower + '', 1) &&
        tableData[row].throttleSteamMassFlow &&
        validatePositiveFloat(tableData[row].throttleSteamMassFlow + '', 2))
    ) {
      tempDirtyRows[tableName] = tempDirtyRows[tableName].filter(
        (e) => e !== row
      );
    } else {
      tempDirtyRows[tableName].push(row);
    }
    this.setState({
      dirtyRows: tempDirtyRows,
    });
  }

  /**
   *
   * @param {*} row
   * @param {*} tableName
   */
  edit(row, tableName) {
    const { updatedFields, editableRows } = this.state;
    const tableData = _.cloneDeep(this.state[tableName]);
    let updatedTemp = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    updatedTemp[tableName][row] = _.cloneDeep(tableData[row]);
    tempEditRows[tableName].push(row);
    this.setState(
      {
        updatedFields: _.cloneDeep(updatedTemp),
        editableRows: tempEditRows,
      },
      () => {
        this.updateFormEdit(true);
      }
    );
  }

  /**
   *
   * @param {*} row
   * @param {*} tableName
   */
  undo(row, tableName) {
    const { updatedFields, dirtyRows, editableRows } = this.state;
    let tempTableData = _.cloneDeep(this.state[tableName]);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    tempTableData[row] = _.cloneDeep(tempupdatedField[tableName][row]);
    tempEditRows[tableName] = tempEditRows[tableName].filter((e) => e !== row);
    tempDirtyRows[tableName] = tempDirtyRows[tableName].filter(
      (e) => e !== row
    );
    tempupdatedField[tableName][row] = undefined;
    if (tableName === operationalBaseLineCurves) {
      this.setState({
        operationalBaseLineCurves: tempTableData,
        dirtyRows: tempDirtyRows,
        updatedFields: tempupdatedField,
        editableRows: tempEditRows,
      });
    } else if (tableName === asTestedCurves) {
      this.setState({
        asTestedCurves: tempTableData,
        dirtyRows: tempDirtyRows,
        updatedFields: tempupdatedField,
        editableRows: tempEditRows,
      });
    }
  }

  /**
   *
   * @param {*} row
   * @param {*} tableName
   */
  deleteRow(row, tableName) {
    const { dirtyRows, updatedFields, editableRows } = this.state;
    let tempTableData = _.cloneDeep(this.state[tableName]);
    let tempDirtyRows = _.cloneDeep(dirtyRows);
    let tempupdatedField = _.cloneDeep(updatedFields);
    let tempEditRows = _.cloneDeep(editableRows);
    tempTableData.splice(row, 1);
    tempupdatedField[tableName].splice(row, 1);
    tempDirtyRows[tableName] = tempDirtyRows[tableName].filter(
      (e) => e !== row
    );
    tempEditRows[tableName] = tempEditRows[tableName].filter((e) => e !== row);
    if (tableName === operationalBaseLineCurves) {
      this.setState(
        {
          operationalBaseLineCurves: tempTableData,
          updatedFields: tempupdatedField,
          dirtyRows: tempDirtyRows,
          editableRows: tempEditRows,
        },
        () => {
          this.updateFormEdit(true);
        }
      );
    } else if (tableName === asTestedCurves) {
      this.setState(
        {
          asTestedCurves: tempTableData,
          updatedFields: tempupdatedField,
          dirtyRows: tempDirtyRows,
          editableRows: tempEditRows,
        },
        () => {
          this.updateFormEdit(true);
        }
      );
    }
  }

  /**
   *
   */
  clearDirtyForm() {
    this.props.onProcessChange();
    this.setState(
      {
        editableRows: {
          asTestedCurves: [],
          operationalBaseLineCurves: [],
        },
        updatedFields: {
          asTestedCurves: [],
          operationalBaseLineCurves: [],
        },
        dirtyRows: {
          asTestedCurves: [],
          operationalBaseLineCurves: [],
        },
        addFieldValue: {
          volumetricFlow: '',
          headDrop: '',
          efficiency: '',
          flowCoefficient: '',
          headCoefficient: '',
          workInput: '',
        },
        addFieldValueBaseLine: {
          volumetricFlow: '',
          headDrop: '',
          efficiency: '',
          flowCoefficient: '',
          headCoefficient: '',
          workInput: '',
        },
        isError: {
          volumetricFlow: false,
          headDrop: false,
          efficiency: false,
        },
        isErrorBaseLine: {
          volumetricFlow: false,
          headDrop: false,
          efficiency: false,
        },
      },
      () => {
        const { asTestedCurves, operationalBaseLineCurves } =
          this.state.preserveCurveData;
        this.updateFormEdit(false);
        this.setCurveData(asTestedCurves, operationalBaseLineCurves);
      }
    );
  }

  /**
   *
   */
  add() {
    this.props.onAdd();
  }

  /**
   *
   */
  reset(resetClicked = false) {
    this.setState({
      visible: true,
      resetClicked: resetClicked,
    });
  }

  /**
   *
   */
  delete() {
    this.setState({ visibleDelete: true });
  }

  /**
   *
   */
  clearPerDevFields(resetClicked = false) {
    if (resetClicked) {
      this.setState({
        percentageDeviation: {
          volumetricFlow: '',
          headDrop: '',
          efficiency: '',
        },
      });
    }
    this.setState({
      modalClicked: false,
      undoClicked: false,
      applyClicked: false,
      switchValue: resetClicked ? false : true,
    });
  }
  save() {
    const { asTestedCurves, operationalBaseLineCurves, dirtyRows } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (
          dirtyRows.asTestedCurves.length === 0 &&
          dirtyRows.operationalBaseLineCurves.length === 0
        ) {
          if (
            asTestedCurves.length >= 3 &&
            operationalBaseLineCurves.length >= 3
          ) {
            if (asTestedCurves.length === operationalBaseLineCurves.length) {
              const { curveId } = this.props;

              let percentageDeviation = {
                lastOperationalBaseLineCurvesData:
                  this.state.percentageDeviation
                    .lastOperationalBaseLineCurvesData,
              };
              this.props.onSave(
                curveId,
                { ...values },
                [...asTestedCurves],
                [...operationalBaseLineCurves],
                percentageDeviation
              );
              this.clearPerDevFields(true);
            } else {
              Notification.show(Types.Info, CurvesMessages.EQUAL_DATA);
            }
          } else {
            Notification.show(Types.Info, CurvesMessages.MIN_DATA);
          }
        } else {
          Notification.show(Types.Error, CurvesMessages.FIELDS_DIRTY);
        }
      }
    });
  }

  /**
   *
   */
  handleCancel() {
    this.setState({
      visible: false,
      resetClicked: false,
    });
  }

  /**
   *
   */

  undoOperationalBaseline() {
    let data = [];
    if (this.state?.preserveCurveData?.operationalBaseLineCurves) {
      data = this.state.preserveCurveData.operationalBaseLineCurves.data;
    }
    this.setState({
      operationalBaseLineCurves: data,
    });
  }
  handleOk() {
    if (this.state.resetClicked) {
      this.setState(
        {
          visible: false,
          undoClicked: false,
        },
        () => {
          this.clearDirtyForm();
        }
      );
      this.props.toggleDetectState(false);
    } else if (this.state.undoClicked) {
      this.setState(
        {
          visible: false,
        },
        () => {
          this.undoOperationalBaseline();
        }
      );
    }

    const { name } = this.props.location.state.componentData.assetInfo;

    (name === COMPONENT.EXPANDER || name === COMPONENT.COMPRESSOR) && this.props.onAppChange();
    (name === COMPONENT.COMPRESSOR) && this.props.onIdentifierChange();
    this.clearPerDevFields(this.state.resetClicked);
    this.setState({
      resetClicked: false,
    });

    if (this.state.applyClicked) {
      this.setState({
        applyClicked: false,
        undoClicked: false,
      });
    }
  }

  /**
   *
   */
  renderModal() {
    if (this.state.visible) {
      return (
        <ConfirmModal
          title={DISCARD_CHANGES}
          visible={this.state.visible}
          handleOk={() => this.handleOk()}
          handleCancel={() => this.handleCancel()}
          message={FORM_DISCARD_MSG}
        />
      );
    } else {
      return null;
    }
  }

  /**
   *
   */
  handleCancelDelete() {
    this.setState({ visibleDelete: false });
  }

  /**
   *
   */
  handleOkDelete() {
    this.setState({ visibleDelete: false }, () => {
      const { curveId } = this.props;
      this.props.onDelete(curveId);
    });
  }

  /**
   *
   */
  renderDeleteModal() {
    if (this.state.visibleDelete) {
      return (
        <ConfirmModal
          title={CurvesMessages.DELETE_TITLE}
          visible={this.state.visibleDelete}
          handleOk={() => this.handleOkDelete()}
          handleCancel={() => this.handleCancelDelete()}
          message={CurvesMessages.DELETE_MSG}
        />
      );
    } else {
      return null;
    }
  }

  /**
   *
   */
  handleOkGraph() {
    this.setState({ visibleGraph: false });
  }

  /**
   *
   */
  getGraphData() {
    if (
      this.state.operationalBaseLineCurves.length > 0 &&
      this.state.asTestedCurves.length > 0
    ) {
      const { xAxisValue, yAxisValue } = this.state;
      const operationalBaseLineCurves =
        this.state.operationalBaseLineCurves.map((item) => {
          /**TODO: Not able to form graph on 0 0 */
          return [item[xAxisValue], item[yAxisValue]];
        });

      const asTestedCurves = this.state.asTestedCurves.map((item) => {
        /**TODO: Not able to form graph on 0 0 */
        return [item[xAxisValue], item[yAxisValue]];
      });

      return { asTestedCurves, operationalBaseLineCurves };
    }
  }

  /**
   *
   */
  renderGraphModal() {
    if (this.state.visibleGraph) {
      const { xAxisValue, yAxisValue } = this.state;
      const graphData = this.getGraphData();
      return (
        <Modal
          title={graphDetails.title}
          visible={this.state.visibleGraph}
          onOk={() => this.handleOkGraph()}
          onCancel={() => this.handleOkGraph()}
          footer={null}
          className="curve-modal"
        >
          <div>
            <button
              className="btn-default btn-small"
              float="right"
              onClick={() => {
                this.showGraph();
              }}
            >
              BACK
            </button>
          </div>
          <div className="curve-plot">
            <div style={{ width: 500 }}>
              <Line
                graphData={graphData.asTestedCurves}
                xAxis={graphDetails[xAxisValue]}
                yAxis={graphDetails[yAxisValue]}
              />
              <span>{graphDetails.asTestedCurve}</span>
            </div>
            <div style={{ width: 500 }}>
              <Line
                graphData={graphData.operationalBaseLineCurves}
                xAxis={graphDetails[xAxisValue]}
                yAxis={graphDetails[yAxisValue]}
              />
              <span>{graphDetails.operationalBasedCurve}</span>
            </div>
          </div>
        </Modal>
      );
    }
    return null;
  }

  cancelModal() {
    this.setState({
      xAxisValue: '',
      yAxisValue: '',
    });
    this.setState({ visibleModal: false });
  }

  onChangeXAxes = (value) => {
    this.setState({ xAxisValue: value });
  };

  onChangeYAxes = (value) => {
    this.setState({ yAxisValue: value });
  };

  onSelectAxes() {
    const { xAxisValue, yAxisValue } = this.state;
    const xAxisTag = curveVariables[xAxisValue];
    const yAxisTag = curveVariables[yAxisValue];
    this.setState({ xAxisValue: xAxisTag });
    this.setState({ yAxisValue: yAxisTag });
    this.setState({ visibleModal: false });
    this.setState({ visibleGraph: true });
  }

  renderCurveInputModal() {
    if (this.state.visibleModal) {
      let data = [];
      const { name } = this.props.location.state.componentData.assetInfo;
      if (name === 'Steam Turbine') {
        data = ['Throttle Steam Mass Flow', 'Output Power'];
      } else {
        data = [
          'Efficiency',
          'Flow Coefficient',
          'Head Coefficient',
          'Head Drop',
          'Volumetric Flow',
          'Work Input',
        ];
      }
      const { xAxisValue, yAxisValue } = this.state;
      return (
        <Modal
          title={'Curve Input'}
          visible={this.state.visibleModal}
          onCancel={() => this.cancelModal()}
          footer={null}
          className="curve-modal"
        >
          <FormFields
            formFields={curveInput(data, xAxisValue, yAxisValue, {
              changeX: (e) => {
                this.onChangeXAxes(e);
              },
              changeY: (e) => {
                this.onChangeYAxes(e);
              },
            })}
            form={this.props.form}
          />
          {
            <div className="ok-button">
              <button
                className="btn-default btn-small"
                onClick={() => {
                  this.onSelectAxes();
                }}
                disabled={xAxisValue === '' || yAxisValue === ''}
              >
                OK
              </button>
            </div>
          }
        </Modal>
      );
    }
  }
  /**
   *
   */
  showGraph() {
    if (
      this.state.asTestedCurves.length >= 1 &&
      this.state.operationalBaseLineCurves.length >= 1
    ) {
      this.setState({
        xAxisValue: '',
        yAxisValue: '',
      });
      this.setState({ visibleGraph: false });
      this.setState({ visibleModal: true });
    } else {
      Notification.show(Types.Info, graphDetails.requiredMessage);
    }
  }

  onChangeSelect(value, key, tableName) {
    const { apiState } = this.props.dataUnitState;
    const { unitData } = apiState;
    const { items } = unitData;

    const obj = items.find((item) => item.dataVariable === key).conversion;
    const objSI = obj.find((item) => item.unit === value);
    if (!obj && !objSI.unit && !objSI.toSI) {
    } else if (typeof value !== 'object') {
      const { speedSI } = this.state;
      let speedValue = this.props.form.getFieldValue('speed')
        ? parseFloat(this.props.form.getFieldValue('speed'))
        : 0;
      let speedOperationalValue = this.props.form.getFieldValue(
        'speedOperational'
      )
        ? parseFloat(this.props.form.getFieldValue('speedOperational'))
        : 0;

      let speed = eval(speedSI.replace('value', speedValue));
      let speedOperational = eval(
        speedSI.replace('value', speedOperationalValue)
      );

      /**
       * volumetricFlow
       * flowCoefficient
       */
      if (key === dataUnitsVariables.volumetricFlow) {
        this.setState({ volumetricFlowSI: objSI.toSI }, () => {
          let {
            asTestedCurves,
            operationalBaseLineCurves,
            volumetricFlowSI,
            updatedFields,
          } = this.state;

          let tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
            let tempFieldValue = { ...fieldValue };
            const volumetricFlow = eval(
              volumetricFlowSI.replace(
                'value',
                parseFloat(tempFieldValue.volumetricFlow || 0) + ''
              )
            );

            tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
              volumetricFlow,
              speed,
              this.state.runnerData[0],
              this.state.runnerType
            );
            return tempFieldValue;
          });

          let tempUpdatedFieldAsTestedCurves = updatedFields.asTestedCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const volumetricFlow = eval(
                volumetricFlowSI.replace(
                  'value',
                  parseFloat(tempFieldValue.volumetricFlow || 0) + ''
                )
              );

              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speed,
                this.state.runnerData[0],
                this.state.runnerType
              );
              return tempFieldValue;
            }
          );

          let tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const volumetricFlow = eval(
                volumetricFlowSI.replace(
                  'value',
                  parseFloat(tempFieldValue.volumetricFlow || 0) + ''
                )
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedOperational,
                this.state.runnerData[0],
                this.state.runnerType
              );
              return tempFieldValue;
            }
          );

          let tempUpdatedFieldAsOperationBaseLineCurves =
            updatedFields.operationalBaseLineCurves.map((fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const volumetricFlow = eval(
                volumetricFlowSI.replace(
                  'value',
                  parseFloat(tempFieldValue.volumetricFlow || 0) + ''
                )
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedOperational,
                this.state.runnerData[0],
                this.state.runnerType
              );
              return tempFieldValue;
            });

          this.setState({
            asTestedCurves: tempAsTestedCurves,
            operationalBaseLineCurves: tempAsOperationBaseLineCurves,
            updatedFields: {
              asTestedCurves: tempUpdatedFieldAsTestedCurves,
              operationalBaseLineCurves:
                tempUpdatedFieldAsOperationBaseLineCurves,
            },
          });
        });
      } else if (key === dataUnitsVariables.headDrop) {
        /**
         * headDrop
         * headCoefficient
         * Done: workInput
         */
        this.setState({ headDropSI: objSI.toSI }, () => {
          let {
            asTestedCurves,
            operationalBaseLineCurves,
            headDropSI,
            updatedFields,
          } = this.state;

          let tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
            let tempFieldValue = { ...fieldValue };
            const headDrop = eval(
              headDropSI.replace(
                'value',
                parseFloat(tempFieldValue.headDrop || 0) + ''
              )
            );
            tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
              headDrop,
              speed,
              this.state.runnerData
            );
            //Added Later
            tempFieldValue['workInput'] = coefficient.workInputCoefficient(
              tempFieldValue['efficiency'],
              tempFieldValue['headCoefficient']
            );
            return tempFieldValue;
          });

          let tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace(
                  'value',
                  parseFloat(tempFieldValue.headDrop || 0) + ''
                )
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speed,
                this.state.runnerData
              );
              //Added Later
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            }
          );

          let tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace(
                  'value',
                  parseFloat(tempFieldValue.headDrop || 0) + ''
                )
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedOperational,
                this.state.runnerData
              );
              //Added Later
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            }
          );

          let tempUpdatedAsOperationBaseLineCurves =
            updatedFields.operationalBaseLineCurves.map((fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace(
                  'value',
                  parseFloat(tempFieldValue.headDrop || 0) + ''
                )
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedOperational,
                this.state.runnerData
              );
              //Added Later
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            });

          this.setState({
            asTestedCurves: tempAsTestedCurves,
            operationalBaseLineCurves: tempAsOperationBaseLineCurves,
            updatedFields: {
              asTestedCurves: tempUpdatedAsTestedCurves,
              operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
            },
          });
        });
      } else if (key === dataUnitsVariables.efficiency) {
        /**
         * efficiency
         * TODO: workInput
         */
        this.setState({ efficiencySI: objSI.toSI }, () => {
          let {
            asTestedCurves,
            operationalBaseLineCurves,
            efficiencySI,
            updatedFields,
          } = this.state;

          let tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
            let tempFieldValue = { ...fieldValue };
            if (efficiencySI === 'value') {
              tempFieldValue.efficiency = tempFieldValue.efficiency * 100;
            } else {
              tempFieldValue.efficiency = tempFieldValue.efficiency / 100;
            }
            return tempFieldValue;
          });

          let tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              if (efficiencySI === 'value') {
                tempFieldValue.efficiency = tempFieldValue.efficiency * 100;
              } else {
                tempFieldValue.efficiency = tempFieldValue.efficiency / 100;
              }
              return tempFieldValue;
            }
          );

          let tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              if (efficiencySI === 'value') {
                tempFieldValue.efficiency = tempFieldValue.efficiency * 100;
              } else {
                tempFieldValue.efficiency = tempFieldValue.efficiency / 100;
              }
              return tempFieldValue;
            }
          );

          let tempUpdatedAsOperationBaseLineCurves =
            updatedFields.operationalBaseLineCurves.map((fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              if (efficiencySI === 'value') {
                tempFieldValue.efficiency = tempFieldValue.efficiency * 100;
              } else {
                tempFieldValue.efficiency = tempFieldValue.efficiency / 100;
              }
              return tempFieldValue;
            });

          this.setState({
            asTestedCurves: tempAsTestedCurves,
            operationalBaseLineCurves: tempAsOperationBaseLineCurves,
            updatedFields: {
              asTestedCurves: tempUpdatedAsTestedCurves,
              operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
            },
          });
        });
      }
      //Speed
      else if (key === dataUnitsVariables.speed) {
        this.setState({ speedSI: objSI.toSI }, () => {
          let {
            asTestedCurves,
            operationalBaseLineCurves,
            headDropSI,
            volumetricFlowSI,
            updatedFields,
          } = this.state;

          let tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
            let tempFieldValue = { ...fieldValue };
            const headDrop = eval(
              headDropSI.replace('value', tempFieldValue.headDrop)
            );
            tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
              headDrop,
              speed,
              this.state.runnerData
            );
            const volumetricFlow = eval(
              volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
            );
            tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
              volumetricFlow,
              speed,
              this.state.runnerData[0],
              this.state.runnerType
            );
            tempFieldValue['workInput'] = coefficient.workInputCoefficient(
              tempFieldValue['efficiency'],
              tempFieldValue['headCoefficient']
            );
            return tempFieldValue;
          });

          let tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace('value', tempFieldValue.headDrop)
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speed,
                this.state.runnerData
              );
              const volumetricFlow = eval(
                volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speed,
                this.state.runnerData[0],
                this.state.runnerType
              );
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            }
          );

          let tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace('value', tempFieldValue.headDrop)
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedOperational,
                this.state.runnerData
              );
              const volumetricFlow = eval(
                volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedOperational,
                this.state.runnerData[0],
                this.state.runnerType
              );
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            }
          );

          let tempUpdatedAsOperationBaseLineCurves =
            updatedFields.operationalBaseLineCurves.map((fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace('value', tempFieldValue.headDrop)
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedOperational,
                this.state.runnerData
              );
              const volumetricFlow = eval(
                volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedOperational,
                this.state.runnerData[0],
                this.state.runnerType
              );
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            });

          this.setState({
            asTestedCurves: tempAsTestedCurves,
            operationalBaseLineCurves: tempAsOperationBaseLineCurves,
            updatedFields: {
              asTestedCurves: tempUpdatedAsTestedCurves,
              operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
            },
          });
        });
      }
    }
    // When speed value is changed
    else {
      const { speedSI } = this.state;
      if (tableName === asTestedCurves) {
        this.props.form.setFieldsValue({
          //TODO
          speed:
            parseFloat(value.target.value) === 0
              ? parseFloat(value.target.value)
              : parseFloat(value.target.value.replace(/^0+/, '')),
        });
        const speedValue = this.props.form.getFieldValue('speed');
        if (speedValue) {
          let { asTestedCurves, headDropSI, volumetricFlowSI, updatedFields } =
            this.state;

          const speedValueInSI = eval(speedSI.replace('value', speedValue));

          let tempAsTestedCurves = asTestedCurves.map((fieldValue) => {
            let tempFieldValue = { ...fieldValue };
            const headDrop = eval(
              headDropSI.replace('value', tempFieldValue.headDrop)
            );
            const volumetricFlow = eval(
              volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
            );
            tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
              headDrop,
              speedValueInSI,
              this.state.runnerData
            );
            tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
              volumetricFlow,
              speedValueInSI,
              this.state.runnerData[0],
              this.state.runnerType
            );
            tempFieldValue['workInput'] = coefficient.workInputCoefficient(
              tempFieldValue['efficiency'],
              tempFieldValue['headCoefficient']
            );
            return tempFieldValue;
          });

          let tempUpdatedAsTestedCurves = updatedFields.asTestedCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace('value', tempFieldValue.headDrop)
              );
              const volumetricFlow = eval(
                volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedValueInSI,
                this.state.runnerData
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedValueInSI,
                this.state.runnerData[0],
                this.state.runnerType
              );
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            }
          );

          this.setState({
            asTestedCurves: tempAsTestedCurves,
            updatedFields: {
              ...this.state.updatedFields,
              asTestedCurves: tempUpdatedAsTestedCurves,
            },
          });
        }
      } else if (tableName === operationalBaseLineCurves) {
        this.props.form.setFieldsValue({
          //TODO
          speedOperational:
            parseFloat(value.target.value) === 0
              ? parseFloat(value.target.value)
              : parseFloat(value.target.value.replace(/^0+/, '')),
        });
        const speedValue = this.props.form.getFieldValue('speedOperational');
        if (speedValue) {
          let {
            operationalBaseLineCurves,
            headDropSI,
            volumetricFlowSI,
            updatedFields,
          } = this.state;

          const speedValueInSI = eval(speedSI.replace('value', speedValue));

          let tempAsOperationBaseLineCurves = operationalBaseLineCurves.map(
            (fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace('value', tempFieldValue.headDrop)
              );
              const volumetricFlow = eval(
                volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedValueInSI,
                this.state.runnerData
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedValueInSI,
                this.state.runnerData[0],
                this.state.runnerType
              );
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            }
          );

          let tempUpdatedAsOperationBaseLineCurves =
            updatedFields.operationalBaseLineCurves.map((fieldValue) => {
              let tempFieldValue = { ...fieldValue };
              const headDrop = eval(
                headDropSI.replace('value', tempFieldValue.headDrop)
              );
              const volumetricFlow = eval(
                volumetricFlowSI.replace('value', tempFieldValue.volumetricFlow)
              );
              tempFieldValue['headCoefficient'] = coefficient.headCoefficient(
                headDrop,
                speedValueInSI,
                this.state.runnerData
              );
              tempFieldValue['flowCoefficient'] = coefficient.flowCoefficient(
                volumetricFlow,
                speedValueInSI,
                this.state.runnerData[0],
                this.state.runnerType
              );
              tempFieldValue['workInput'] = coefficient.workInputCoefficient(
                tempFieldValue['efficiency'],
                tempFieldValue['headCoefficient']
              );
              return tempFieldValue;
            });

          this.setState({
            operationalBaseLineCurves: tempAsOperationBaseLineCurves,
            updatedFields: {
              ...this.state.updatedFields,
              operationalBaseLineCurves: tempUpdatedAsOperationBaseLineCurves,
            },
          });
        }
      }
    }
  }

  /**
   *
   */
  renderAsTestedCurve() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const { identifierKey, identifierValue }  = this.props;
    return (
      <div>
        <div className="heading-table">
          <div className="type-heading">{curvesLabel.asTestedCurve}</div>
          <div className="unit-heading">
            {this.props.isSchematicEditable ? curvesLabel.selectUnits : 'Units'}
          </div>
        </div>
        <div>
          {name !== 'Steam Turbine' ? (
            <FormFields
              formFields={AsTesteCurveFormField(this.state.unitData, {
                change: (e, key) => {
                  this.onChangeSelect(e, key, asTestedCurves);
                },
                isCurveEditable: this.props.isSchematicEditable,
                name: name,
                process: this.props.process,
                identifierKey: identifierKey,
                identifierValue: identifierValue
              })}
              form={this.props.form}
            />
          ) : (
            <FormFields
              formFields={AsTesteCurveFormFieldSteamTurbine(
                this.state.unitData,
                {
                  change: (e, key) => {
                    this.onChangeSelect(e, key, asTestedCurves);
                  },
                  isCurveEditable: this.props.isSchematicEditable,
                  name: name,
                  process: this.props.process,
                }
              )}
              form={this.props.form}
            />
          )}
        </div>
        <div className="table-curve">
          {name !== 'Steam Turbine' ? (
            <CustomTable
              showHeader={true}
              columns={curvesTableColumns({
                isCurveEditable: this.props.isSchematicEditable,
                onChange: (row, col, field) => {
                  this.onEditFieldChange(row, col, field, asTestedCurves);
                },
                edit: (row) => {
                  this.edit(row, asTestedCurves);
                },
                deleteRow: (row) => {
                  this.deleteRow(row, asTestedCurves);
                },
                undo: (row) => {
                  this.undo(row, asTestedCurves);
                },
                name: name,
                process: this.props.process,
              })}
              editableRow={this.state.editableRows.asTestedCurves}
              data={this.state.asTestedCurves}
              renderAddRow={
                //To be configable
                this.props.isSchematicEditable
                  ? this.state.asTestedCurves.length < 15
                    ? curvesTableAddRow({
                        onChange: (row, col, field) => {
                          this.onAddFieldChange(
                            row,
                            col,
                            field,
                            asTestedCurves
                          );
                        },
                        submit: (e) => {
                          this.addRow(asTestedCurves);
                        },
                        isError: this.state.isError,
                      })
                    : null
                  : null
              }
              addFieldValues={this.state.addFieldValue}
            />
          ) : (
            <CustomTable
              showHeader={true}
              columns={curvesTableColumnsSteamTurbine({
                isCurveEditable: this.props.isSchematicEditable,
                onChange: (row, col, field) => {
                  this.onEditFieldChange(row, col, field, asTestedCurves);
                },
                edit: (row) => {
                  this.edit(row, asTestedCurves);
                },
                deleteRow: (row) => {
                  this.deleteRow(row, asTestedCurves);
                },
                undo: (row) => {
                  this.undo(row, asTestedCurves);
                },
                name: name,
                process: this.props.process,
              })}
              editableRow={this.state.editableRows.asTestedCurves}
              data={this.state.asTestedCurves}
              renderAddRow={
                //To be configable
                this.props.isSchematicEditable
                  ? this.state.asTestedCurves.length < 15
                    ? curvesTableAddRowSteamTurbine({
                        onChange: (row, col, field) => {
                          this.onAddFieldChange(
                            row,
                            col,
                            field,
                            asTestedCurves
                          );
                        },
                        submit: (e) => {
                          this.addRow(asTestedCurves);
                        },
                        isError: this.state.isError,
                      })
                    : null
                  : null
              }
              addFieldValues={this.state.addFieldValue}
            />
          )}
        </div>
      </div>
    );
  }

  /**
   *
   */
  renderOperationBaseLineCurve() {
    const { name } = this.props.location.state.componentData.assetInfo;
    const { curveId, identifierKey } = this.props;
    var lastBaseLineData = [];
 if ((name === 'Centrifugal compressor') || (name === "Centrifugal pump") || ( name=== "Centrifugal liquid expander" )){
   let dataCurve= (name=== 'Centrifugal compressor')
   ? this.props.configData?.curvesData?.curvesData[curveId]
   : this.props.configData.curvesData ;
  if (!_.isUndefined(this.props.curvesData[curveId]?.operationalBaseLineCurves )) {
        if (
          this.props.curvesData[curveId]?.operationalBaseLineCurves
            ?.percentageDeviation
        ) {
          lastBaseLineData =
            this.props.curvesData[curveId].operationalBaseLineCurves
              .percentageDeviation.lastOperationalBaseLineCurvesData;
        } else lastBaseLineData = [];
      } else if (!_.isUndefined(dataCurve) ){
        if (
          dataCurve
            ?.operationalBaseLineCurves
        ) {
          if (
            dataCurve
              .operationalBaseLineCurves?.percentageDeviation
          ) {
            lastBaseLineData =
              dataCurve
                 .operationalBaseLineCurves.percentageDeviation
                  .lastOperationalBaseLineCurvesData;
          }
        } else lastBaseLineData = [];
      } else lastBaseLineData = [];
    }

    return (
      <div>
        <div className="heading-table">
          <div className="type-heading">
            {curvesLabel.operationBaselineCurve}
          </div>
          <div className="unit-heading">
            {this.props.isSchematicEditable ? curvesLabel.selectUnits : 'Units'}
          </div>
        </div>
        <div>
          {name !== 'Steam Turbine' ? (
            <FormFields
              formFields={AsTesteCurveFormFieldOperational(
                this.state.unitData,
                {
                  change: (e, key) => {
                    this.onChangeSelect(e, key, operationalBaseLineCurves);
                  },
                  isCurveEditable: this.props.isSchematicEditable,
                  name: name,
                  process: this.props.process,
                  identifierKey: identifierKey,
                }
              )}
              form={this.props.form}
            />
          ) : (
            <FormFields
              formFields={AsTesteCurveFormFieldOperationalSteamTurbine(
                this.state.unitData,
                {
                  change: (e, key) => {
                    this.onChangeSelect(e, key, operationalBaseLineCurves);
                  },
                  isCurveEditable: this.props.isSchematicEditable,
                  name: name,
                  process: this.props.process,
                }
              )}
              form={this.props.form}
            />
          )}
        </div>
        <br />
          {(name === COMPONENT.COMPRESSOR || name == COMPONENT.EXPANDER || name === COMPONENT.PUMP) &&
          <div>
            <span>
              <p
                style={{
                  fontSize: '16px',
                  display: 'inline',
                  marginRight: '0.81rem',
                  marginLeft: '10px'
                }}
                className={'.unit-heading'}
              >
                {"Desired Change in Percentage"}
              </p>
              <Switch
                title="Percentage Deviation"
                checked={this.state.switchValue}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={() => {
                  this.togglePercDev();
                }}
              ></Switch>
            </span>
            <br />
            {this.state.switchValue && (
              <div style={{ marginTop: '1rem', display: 'flex' }}>
                {/* volumetricFlow */}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    display: 'inline',
                  }}
                >
                  <p
                    style={{
                      marginBottom: '0px',
                      color: ' #9a9a9a',
                      fontSize: ' 14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Volumetric Flow
                  </p>

                  <div style={{ width: '160px' }}>
                    <Input
                      type="number"
                      value={this.state.percentageDeviation.volumetricFlow}
                      addonAfter="%"
                      onKeyDown={(evt) =>
                        (evt.key === 'e' || evt.key === 'E') &&
                        evt.preventDefault()
                      }
                      onChange={(e) => {
                        this.setState({
                          percentageDeviation: {
                            ...this.state.percentageDeviation,
                            volumetricFlow: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>

                {/* head drop */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    display: 'inline',
                    marginLeft: '3rem',
                  }}
                >
                  <p
                    style={{
                      marginBottom: '0px',
                      color: ' #9a9a9a',
                      fontSize: ' 14px',
                      fontWeight: 'bold',
                    }}
                  >
                   { name === "Centrifugal compressor" ? "Adiabatic / Polytropic Head":"Head Drop" }
                  </p>

                  <div style={{ width: '160px' }}>
                    <Input
                      type="number"
                      value={this.state.percentageDeviation.headDrop}
                      addonAfter="%"
                      onKeyDown={(evt) =>
                        (evt.key === 'e' || evt.key === 'E') &&
                        evt.preventDefault()
                      }
                      onChange={(e) => {
                        this.setState({
                          percentageDeviation: {
                            ...this.state.percentageDeviation,
                            headDrop: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                {/* efficiency */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    display: 'inline',
                    marginLeft: '3rem',
                  }}
                >
                  <p
                    style={{
                      marginBottom: '0px',
                      color: ' #9a9a9a',
                      fontSize: ' 14px',
                      fontWeight: 'bold',
                    }}
                  >
                    { name === "Centrifugal compressor" ?"Adiabatic / Polytropic Efficiency": "Efficiency" }
                  </p>

                  <div style={{ width: '160px' }}>
                    <Input
                      type="number"
                      value={this.state.percentageDeviation.efficiency}
                      addonAfter="%"
                      onKeyDown={(evt) =>
                        (evt.key === 'e' || evt.key === 'E') &&
                        evt.preventDefault()
                      }
                      onChange={(e) => {
                        this.setState({
                          percentageDeviation: {
                            ...this.state.percentageDeviation,
                            efficiency: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                {this.state.applyClicked === false && (
                  <Button
                    style={{ marginTop: '1.2rem', marginLeft: '3rem' }}
                    type="primary"
                    onClick={() => {
                      this.onClickApply();
                    }}
                  >
                    {ButtonVariables.APPLY}
                  </Button>
                )}
                {this.state.applyClicked === true && (
                  <Button
                    style={{ marginTop: '1.2rem', marginLeft: '3rem' }}
                    type="primary"
                    onClick={() => {
                      this.setState({
                        undoClicked: true,
                        visible: true,
                      });
                      
                    }}
                  >
                    {ButtonVariables.UNDO}
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{ marginTop: '1.2rem', marginLeft: '3rem' }}
                  title="Download Previous Saved Data"
                  >
                  <CSVLink
                    className="csv-download"
                    filename={name}
                    data={lastBaseLineData.length===0
                      ? 
                      [ ["volumetricFlow", "HeadDrop", "efficiency","flowCoefficient","headCoefficient",
                      "workInput","volumetricFlowSIValue","headDropSIValue",],
                      ["", "", "","", "", "","", "", ""]]
                      : lastBaseLineData }
                  >
                    <DownloadOutlined
                      style={{
                        margin: 'auto',
                        fontSize: '1.6rem',
                        padding: '5px',
                      }}
                    />
                  </CSVLink>
                </Button>
              </div>
            )}
          </div>}
        
        <div className="table-curve">
          {name !== 'Steam Turbine' ? (
            <CustomTable
              showHeader={true}
              columns={curvesTableColumns({
                isCurveEditable: this.props.isSchematicEditable,
                onChange: (row, col, field) => {
                  this.onEditFieldChange(
                    row,
                    col,
                    field,
                    operationalBaseLineCurves
                  );
                },
                edit: (row) => {
                  this.edit(row, operationalBaseLineCurves);
                },
                deleteRow: (row) => {
                  this.deleteRow(row, operationalBaseLineCurves);
                },
                undo: (row) => {
                  this.undo(row, operationalBaseLineCurves);
                },
                name: name,
                process: this.props.process,
              })}
              editableRow={this.state.editableRows.operationalBaseLineCurves}
              data={this.state.operationalBaseLineCurves}
              renderAddRow={
                this.props.isSchematicEditable
                  ? this.state.operationalBaseLineCurves.length < 15
                    ? curvesTableAddRow({
                        onChange: (row, col, field) => {
                          this.onAddFieldChange(
                            row,
                            col,
                            field,
                            operationalBaseLineCurves
                          );
                        },
                        submit: (e) => {
                          this.addRow(operationalBaseLineCurves);
                        },
                        isError: this.state.isErrorBaseLine,
                      })
                    : null
                  : null
              }
              addFieldValues={this.state.addFieldValueBaseLine}
            />
          ) : (
            <CustomTable
              showHeader={true}
              columns={curvesTableColumnsSteamTurbine({
                isCurveEditable: this.props.isSchematicEditable,
                onChange: (row, col, field) => {
                  this.onEditFieldChange(
                    row,
                    col,
                    field,
                    operationalBaseLineCurves
                  );
                },
                edit: (row) => {
                  this.edit(row, operationalBaseLineCurves);
                },
                deleteRow: (row) => {
                  this.deleteRow(row, operationalBaseLineCurves);
                },
                undo: (row) => {
                  this.undo(row, operationalBaseLineCurves);
                },
                name: name,
                process: this.props.process,
              })}
              editableRow={this.state.editableRows.operationalBaseLineCurves}
              data={this.state.operationalBaseLineCurves}
              renderAddRow={
                this.props.isSchematicEditable
                  ? this.state.operationalBaseLineCurves.length < 15
                    ? curvesTableAddRowSteamTurbine({
                        onChange: (row, col, field) => {
                          this.onAddFieldChange(
                            row,
                            col,
                            field,
                            operationalBaseLineCurves
                          );
                        },
                        submit: (e) => {
                          this.addRow(operationalBaseLineCurves);
                        },
                        isError: this.state.isErrorBaseLine,
                      })
                    : null
                  : null
              }
              addFieldValues={this.state.addFieldValueBaseLine}
            />
          )}
        </div>
      </div>
    );
  }

  togglePercDev() {
    if (this.state.switchValue === true) {
      this.handleOk();
      this.setState({
        percentageDeviation: {
          volumetricFlow: '',
          headDrop: '',
          efficiency: '',
        },
        applyClicked: false,
      });
    }
    this.updateFormEdit(true);
    this.setState({
      switchValue: this.state.switchValue === true ? false : true,
      applyClicked: false,
    });
  }

  onClickApply() {
    if (
      _.isEmpty(this.state.percentageDeviation.volumetricFlow) &&
      _.isEmpty(this.state.percentageDeviation.headDrop) &&
      _.isEmpty(this.state.percentageDeviation.efficiency)
    ) {
      Notification.show(Types.Error, 'Atleast one value needs to be specified');
      return;
    }
    if (
      this.state.percentageDeviation.volumetricFlow < -100 ||
      this.state.percentageDeviation.headDrop < -100 ||
      this.state.percentageDeviation.efficiency < -100
    ) {
      Notification.show(Types.Error, 'No value can be less than -100');
      return;
    }
    this.updateFormEdit(true);
    let temp = _.cloneDeep(this.state.operationalBaseLineCurves);
    if (temp.length === 0) {
      Notification.show(Types.Error, 'Atleast one row required');

      this.clearPerDevFields();
      return;
    }

    let updatedOperationBaseLineCurves = temp.map((item) => {
      if (item.volumetricFlow) {
        item.volumetricFlow +=
          (this.state.percentageDeviation.volumetricFlow / 100) *
          item.volumetricFlow;
        item.volumetricFlow = parseFloat(item.volumetricFlow.toFixed(4));
      }

      if (item.headDrop) {
        item.headDrop +=
          (this.state.percentageDeviation.headDrop / 100) * item.headDrop;
        item.headDrop = parseFloat(item.headDrop.toFixed(4));
      }

      if (item.efficiency) {
        item.efficiency +=
          (this.state.percentageDeviation.efficiency / 100) * item.efficiency;
        item.efficiency = parseFloat(item.efficiency.toFixed(4));
      }

      if (!item.work) {
        item.work = coefficient.workCoefficient(item.headDrop, item.efficiency);
        item.work = parseFloat(item.work.toFixed(4));
      } else if(item.work) {
        item.work+= coefficient.workCoefficient(item.headDrop, item.efficiency);
        item.work = parseFloat(item.work.toFixed(4));
      }

      return item;
    });
     let newOperationBaseLineCurves = _.cloneDeep(updatedOperationBaseLineCurves)
     
    if (Array.isArray(newOperationBaseLineCurves) && 
          newOperationBaseLineCurves.length > 0) {
      newOperationBaseLineCurves.map((data, index) => {
        let coefficients = this.calculateCofficient(
          data,
          operationalBaseLineCurves
        );
        newOperationBaseLineCurves[index].flowCoefficient = coefficients.flowCoefficient;
        newOperationBaseLineCurves[index].headCoefficient = coefficients.headCoefficient;
        newOperationBaseLineCurves[index].work = coefficients.work;
        newOperationBaseLineCurves[index].workInput = coefficients.workInput;
      })}

    this.setState({
      operationalBaseLineCurves:newOperationBaseLineCurves,
      applyClicked: true,
    });
  }

  render() {
    const { loading } = this.props.curvesState;
    const dataUnitLoading = this.props.dataUnitState.loading;
    const { isSchematicEditable, isEdit, isSave, canAdd, canDelete } =
      this.props;
    const { changeDetected } = this.props;
    return (
      <div className="Curves">
        <div className="CurvesInner">
          {dataUnitLoading || loading ? <Loader /> : null}
          <div className="curves-buttons">
            <button
              className="btn-default btn-white"
              onClick={() => {
                this.showGraph();
              }}
            >
              {'View Curve Plot'}
            </button>
            {/* Uplaod file changed to update Operational Baseline Curves */}
            {isSchematicEditable && (
              <button
                className="btn-default btn-white"
                onClick={this.asTestedToOpertaional}
              >
                {'Duplicate'}
              </button>
            )}
          </div>

          {this.renderAsTestedCurve()}
          {this.renderOperationBaseLineCurve()}

          <div className="config-bottom-buttons">
            {this.state.applyClicked || (isEdit && isSchematicEditable) || (changeDetected) ? (
              <div>
                <button
                  className="btn-default btn-white"
                  onClick={() => {
                    this.reset(true);
                  }}
                >
                  {ButtonVariables.RESET}
                </button>
                <button
                  className="btn-default"
                  onClick={() => {
                    this.save();
                  }}
                >
                  {ButtonVariables.SAVE}
                </button>
              </div>
            ) : null}
            {canDelete && isSave && !isEdit && !changeDetected && (
              <button className="btn-default" onClick={() => this.delete()}>
                {'Delete'}
              </button>
            )}
            {canAdd && isSave && !isEdit && !changeDetected && (
              <button
                className="btn-default"
                onClick={() => {
                  this.add();
                }}
              >
                {'Add Curve'}
              </button>
            )}
          </div>
          {this.renderCurveInputModal()}
          {this.renderModal()}
          {this.renderGraphModal()}
          {this.renderDeleteModal()}
          {this.renderOperationalModal()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isEdit: state.formEditReducer.isEdit,
  isConnect: state.NetworkReducer.networkState,
  curvesState: state.CurvesReducer,
  dataUnitState: state.DataUnitReducer,
  runnerDataState: state.RunnerDataReducer,
  changeDetected: state.curveReducer.apiState.changeDetected,
});

const mapDispatchToProps = (dispatch) => ({
  updateFormEdit: (status) => {
    status
      ? window.dispatchEvent(new Event('form-edit'))
      : window.dispatchEvent(new Event('form-edit-false'));
    return dispatch(updateFormEdit(status));
  },
  getDataUnits: (payload) => {
    dispatch(getDataUnits(payload));
  },
  toggleDetectState: (payload) => {
    dispatch(toggleDetectState(payload));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'curveForm',
    onFieldsChange: (props, fields, allfields) => {
      props.updateFormEdit(true);
    },
  })(withRouter(Curve))
);
