import { Modal, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoyRide from "react-joyride";
import tourPages from "../../tourConstant";
import { addTourPage } from "../../../../redux/actions/login.action";
import "./Trends.scss";


const stepText = (text, tourNumber) => {
  if(tourNumber === 3){
    return(
      <>
      View the start / stop time for the trend here or  <span style={{fontSize: "1.1em", fontWeight:"bold"}}>{text}</span> the trend time range by clicking here.
      </>
    );
  }
  else if( tourNumber === 5){
    return(
      <>
      Trend time range can also be changed or <span style={{fontSize: "1.1em", fontWeight:"bold"}}>{text}</span> using the time drop down
      </>
    );
  }
};
const TOUR_STEPS = [
  {
    target: ".trend-sider",
    title:"Trend Panel",
    content: "A list of all the trends is shown in this panel. Click on a trend title to display that trend",
    disableBeacon: true,
    placement: "right-start"
  },
  {
    target: ".calendar-trends",
    title: "Trend Time Range",
    content: stepText("customize", 3),
    disableBeacon: true,
  },
  {
    target: ".selectDate",
    title:"Time Drop Down",
    content: stepText("customized", 5),
    disableBeacon: true,
    placement: "left"
  },
  {
    target: ".timeFormat",
    title:"Trend Time Stamp",
    content: "View the latest timestamp at which data has been received on the platform",
    disableBeacon: true,
  },
];

const Tour = () => {
  const data = useSelector((state) => state.LoginReducer.apiState);
  const dispatch = useDispatch();
  const [askTour, setAskTour] = useState(true);
  const [startTour, setStartTour] = useState(false);
  
  useEffect(() => {
    if(data.tourTaken){
      for(let i=0; i<data.tourTaken.length; i++){
        if(data.tourTaken[i]=== tourPages.trends){
          setAskTour(false);
          setStartTour(false);
        }
      }
    }
  }, [data]);
  
  const handleJoyrideCallback = data => {
    if (data.action ===  "skip") {
      setStartTour(false);
      setAskTour(false);
    }
    if (data.action ===  "reset") {
      setStartTour(false);
      setAskTour(false);
    }
    if(data.action === "close") {
      setStartTour(false);
      setAskTour(false);
    }
  };

  const demo = () => {
    if(askTour){
      return (
        <Modal visible={true}
          title="Alert"
          className="Trends"
          destroyOnClose={()=>{setStartTour(false); setAskTour(false);}}
          onCancel={()=>{setStartTour(false); setAskTour(false);}}
          footer={[
            <Button key="1" onClick= {() => {setStartTour(false); setAskTour(false); dispatch(addTourPage(data.userId, tourPages.trends));}}>No</Button>,
            <span className="trends-tour-popup">
              <Button key="2" onClick ={()=>{setStartTour(false); setAskTour(false);}}>Maybe Later</Button>,
              <Button key="3" type="primary" onClick= {() => {setStartTour(true); setAskTour(false); dispatch(addTourPage(data.userId, tourPages.trends));}}>
            Ok
              </Button>
            </span>
          ]}
        >
          <div> Would you like to take a tour of the new trends page ?</div>
          <br/>
        </Modal>
      );
    }
    else if(startTour ){
      return (
        <JoyRide
          steps={TOUR_STEPS}
          continuous={true}
          showSkipButton={true}
          locale={{
            last: "End tour",
            skip: "Skip",
          }}
          styles={{
            tooltipContainer: {
              textAlign: "center",
              letterSpacing:"1px"
            },
            buttonNext: {
              backgroundColor: "#363b4e",
              fontSize: "0.9em"
            },
            buttonBack: {
              marginRight: 20,
              color:"black",
              fontSize: "0.9em"
            },
            options:{
              backgroundColor:"white",
              arrowColor:"rgb(54, 59, 78)"
            }
          }}
          disableScrolling={true} 
          callback={handleJoyrideCallback} 
        />
      );
    }
    return(
      <></>
    );

  };
  return(
    <>
      {demo()}
    </>
  );
};
export default Tour;
