import { Modal, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JoyRide from 'react-joyride';
import { setTourState } from '../../../../redux/actions/iowTags.action';
import { userRole, addTourPage } from '../../../../redux/actions/login.action';
import './iow.scss';
import tourPages from "../../tourConstant";

const Tour = (isLoaded, setEditModal, handleIOWKeyChange) => {
  const user = useSelector((state) => state.LoginReducer.apiState);
  const dispatch = useDispatch();
  const [askTour, setAskTour] = useState(true);
  const [startTour, setStartTour] = useState(false);
  const [disableScroll, setDisableScroll] = useState(true);

  useEffect(() => { 
    if (user.tourTaken) {
      for (let i = 0; i < user.tourTaken.length; i++) {
        if (user.tourTaken[i] === tourPages.IOW_V3_0) {
          setAskTour(false);
          setStartTour(false);
          setEditModal(false);
        }
      }
    }
  }, [user,setEditModal]);

  const stepText = (text, tourNumber) => {
    if (tourNumber === 1) {
      return (
        <>
          The new <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span> 
          Tab is available at the facility, system and equipment levels.
        </>
      );
    }
    if (tourNumber === 2) {
      return (
        <>
          The left panel contains all the <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span> 
          available at the selected level.
        </>
      );
    }

    if (tourNumber === 3) {
      return (
        <>
          Search for <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span> 
          here by entering a part of the variable tag ID or variable name​.
        </>
      );
    } else if (tourNumber === 4) {
      return (
        <>
          This section contains <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span> 
          for the selected variable.
        </>
      );
    } else if (tourNumber === 5) {
      return (
        <>
          This section contains the <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span> 
          for the variable tag.
        </>
      );
    } else if (tourNumber === 6) {
      return (
        <>
          This section contains the {text}
          referenced to the start date.
        </>
      );
    } else if (tourNumber === 7) {
      return (
        <div className="runDetails">
          <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span> existing IOW limits and/or exposure 
          timers for a variable tag by clicking here.<br></br>
        </div>
      );
    } else if (tourNumber === 8) {
      return (
        <div className="runDetails">
          Add or Edit IOW limits in this section. <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span><br />
          Note that in order to generate an alert, the variable tag value should be continuously outside the
          ‘Standard’ limit for 30 minutes or the ‘Critical’ limit for 1 minute while the IOW analysis is enabled.
        </div>
      );
    }
     else if (tourNumber === 9) {
      return (
        <div className="runDetails">
          <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span>
          Set a ‘Reference Start Date’ to enable the calculation of the ‘Time in Optimal Range (Hr)’.
        </div>
      );
    } 
    else if (tourNumber === 10) {
      return (
        <>
          IOW Analysis for the variable tag can be <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{text}</span>
           A green background indicates IOW analysis is enabled for the tag.
        </>
      );
    }
  };
  const TOUR_STEPS = [
    {
      target: '.ant-tabs-tab-active',
      title: 'IOW Tab',
      content: stepText(' Integrity Operating Windows (IOW) ', 1),
      disableBeacon: true,
      placement: 'bottom',
    },
    {
      target: '.ant-layout-sider-children',
      title: 'Variable Tag Panel',
      content: stepText(' field variables and Turbomechanica calculated variable tags ',2),
      disableBeacon: true,
      placement: 'right-start',
    },
    {
      target: '.Search',
      title: 'Search Bar ',
      content: stepText(' variable tag(s) ', 3),
      disableBeacon: true,
      placement: 'right-start',
    },
    {
      target: '.KPIDetails',
      title: 'Variable Tag Info',
      content: stepText(' basic variable tag information ', 4),
      disableBeacon: true,
      placement: 'left-start',
    },
    {
      target: '.limits',
      title: 'IOW Limits',
      content: stepText(
        " 'Standard' and 'Critical' IOW limits, and the optimal target value ",5),
      disableBeacon: true,
      placement: 'top',
    },
    {
      target: '.exposure',
      title: 'IOW Exposure Timers',
      content: stepText(
        ' total time the variable tag has been outside the set IOW limit(s) while IOW was enabled and the total time in the optimal range ',6),
      disableBeacon: true,
      placement: 'top',
    },
    {
      target: '.edit-button',
      title: 'Edit IOW Data​',
      content: stepText(' Manually Edit ', 7),
      disableBeacon: true,
      placement: 'bottom',
    },

    {
      target: '.edit-button',
      title: 'Add or Edit IOW Limits',
      content: stepText(
        ' At least one Standard or Critical limit must be set to enable the IOW analysis for the variable tag. ',8),
      disableBeacon: true,
      placement: 'bottom',
      visible: 'true',
    },

    {
      target: '.edit-button',
      title: 'Add or Edit IOW Exposure Timers',
      content: stepText(' Add or edit IOW Exposure Timers here. ', 9),
      disableBeacon: true,
      placement: 'bottom',
      visible: true,
    },
    {
      target: '.switch',
      title: 'Enable/Disable IOW Analysis',
      content: stepText(' enabled or disabled using this toggle switch. ', 10),
      disableBeacon: true,
      placement: 'top',
    },
  ];

  const handleJoyrideCallback = (data) => {
    if ( data.action === 'skip' || data.action === 'reset' || data.action === 'close')
    {
      setStartTour(false);
      setAskTour(false);
      dispatch(addTourPage(user.userId, tourPages.IOW_V3_0))
      dispatch(setTourState(false));
      dispatch(userRole());      
    }  

   (( data.index > 6 && data.index < 9) && (data.action !== 'skip' || data.action !== 'reset' || data.action !== 'close'))
      ? setEditModal(true)
      : setEditModal(false);

    (data.index === 8)?handleIOWKeyChange('2'): handleIOWKeyChange('1');
  
    if (data.index === 4) {
      setDisableScroll(false);
    }
  };

  const demo = () => {
    if (askTour && isLoaded === true) {
      return (
        <Modal
          visible={true}
          maskClosable={false}
          title="Alert"
          className="Trends"
          destroyOnClose={() => {
            setStartTour(false);
            setAskTour(false);
          }}
          onCancel={() => {
            setStartTour(false);
            setAskTour(false);
          }}
          footer={[
            <Button
              key="1"
              onClick={() => {
                setStartTour(false);
                setAskTour(false);
                dispatch(setTourState(false));       
                dispatch(addTourPage(user.userId, tourPages.IOW_V3_0))        
              }}
            > No
            </Button>,
            <span className="trends-tour-popup">
              <Button
                key="2"
                onClick={() => {
                  setStartTour(false);
                  setAskTour(false);
                }}
              > Maybe Later
              </Button>
              ,
              <Button
                key="3"
                type="primary"
                onClick={() => {
                  setStartTour(true);
                  setAskTour(false);
                  dispatch(setTourState(true));
                  dispatch(addTourPage(user.userId, tourPages.IOW_V3_0))
                }}
              > Yes
              </Button>
            </span>,
          ]}
        >
          <div> Would you like to take a tour of the IOW page ?</div>
          <br />
        </Modal>
      );
    } else if(startTour) {
      return (
        <div className="runDetails">
          <JoyRide
            steps={TOUR_STEPS}
            continuous={true}
            showSkipButton={true}
            Modalvisible={true}
            locale={{
              last: 'End tour',
              skip: 'Skip',
            }}
            styles={{
              tooltipContainer: {
                textAlign: 'center',
                letterSpacing: '1px',
              },
              buttonClose: {
                display: 'none',
              },
              buttonNext: {
                backgroundColor: '#363b4e',
                fontSize: '0.9em',
              },
              buttonBack: {
                marginRight: 20,
                color: 'black',
                fontSize: '0.9em',
              },
              options: {
                backgroundColor: 'white',
                arrowColor: 'rgb(54, 59, 78)',
                zIndex: 1001,
              },
              tooltip: {
                fontSize: '0.9em',
                fontStyle: 'None',
              },
              tooltipTitle: {
                fontSize: '1.2em',
                fontStyle: 'None',
              },
              buttonSkip: {
                fontSize: '0.9em',
              },
            }}
            scrollOffset={50}
            showProgress={true}
            debug={true}
            callback={handleJoyrideCallback}
            scrollToFirstStep={true}
            disableCloseOnEsc={false}
            disableScrolling={disableScroll}
            disableOverlayClose={true}
            hideFooter={true}
            className="tour-class"
          />
        </div>
      );
    }
    return <></>;
    };
  return <>{demo()}</>;
};
export default Tour;