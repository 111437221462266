import _ from "lodash";
import moment from "moment";
import { getLumpSumValue } from "../../../../libs/valueFormatter";

export const transformXYBGraphData = (gdata, global) => {
  const series = [];
  if (gdata) {
    if (gdata.x && gdata.y && gdata.x[0]?.data) {
      const xydata = {
        type: "scatter",
        data: [],
        timestamps: [],
        xVariableName: global.x[0].variableName,
        yVariableName: global.y[0].variableName,
      };
      const limit =
        gdata.x[0]?.data.length > gdata.y[0]?.data.length ? gdata.y[0]?.data.length : gdata.x[0]?.data.length;

      for (let i = 0; i < limit; i++) {
        if (
          gdata.x[0]?.data[i] &&
          (gdata.x[0]?.data[i].plantValue || gdata.x[0]?.data[i].predictedValue) &&
          gdata.y[0]?.data[i] &&
          (gdata.y[0]?.data[i].plantValue || gdata.y[0]?.data[i].predictedValue)
        ) {
          let xValue, yValue;
          if (gdata.x[0].data[i].plantValue) { xValue = getLumpSumValue(gdata.x[0].data[i].plantValue) }
          else { xValue = getLumpSumValue(gdata.x[0].data[i].predictedValue) }
          if (gdata.y[0].data[i].plantValue) { yValue = getLumpSumValue(gdata.y[0].data[i].plantValue) }
          else { yValue = getLumpSumValue(gdata.y[0].data[i].predictedValue) }
          xydata.data.push({
            x: parseFloat(xValue),
            y: parseFloat(yValue),
            time: moment(gdata.x[0].data[i].timestamp).format("DD/MM/YY HH:mm:ss"),
            xunit: gdata.x[0].plantUnit,
            yunit: gdata.y[0].plantUnit,
            xPiTag: gdata.x[0]['_id'],
            yPiTag: gdata.y[0]['_id']
          });
          xydata.timestamps.push(moment(gdata.x[0].data[i].timestamp).format());
        }
      }
      series.push(xydata);
    }
    if (gdata.type === "Liquid expanders") {
      if (
        gdata.baselineCurvesData &&
        gdata.baselineCurvesData.curvesData &&
        gdata.baselineCurvesData.curvesData.operationalBaseLineCurves
      ) {
        const bdata = {
          name: "Baseline Curve",
          type: "spline",
          data: [],
        };
        const limit =
          gdata.baselineCurvesData.curvesData.operationalBaseLineCurves.length;
        const xBaselineTag = global.xAxisBaselineTag || "flowCoefficient";
        const yBaselineTag = global.yAxisBaselineTag || "headCoefficient";
        for (let i = 0; i < limit; i++) {
          if (
            gdata.baselineCurvesData.curvesData.operationalBaseLineCurves[i][
              xBaselineTag
            ].value &&
            gdata.baselineCurvesData.curvesData.operationalBaseLineCurves[i][
              yBaselineTag
            ].value
          ) {
            bdata.data.push({
              name: 'Baseline Curve',
              x:
                gdata.baselineCurvesData.curvesData.operationalBaseLineCurves[
                  i
                ][xBaselineTag].value,
              y:
                gdata.baselineCurvesData.curvesData.operationalBaseLineCurves[
                  i
                ][yBaselineTag].value,
              xPiTag: keyExchange[xBaselineTag],
              yPiTag: keyExchange[yBaselineTag],
              xunit:
                gdata.baselineCurvesData.curvesData.operationalBaseLineCurves[
                  i
                ][xBaselineTag].unit,
              yunit:
                gdata.baselineCurvesData.curvesData.operationalBaseLineCurves[
                  i
                ][yBaselineTag].unit,
            });
          }
        }
        series.push(bdata);
      }
    } else if (
      gdata.type === "Electric motors" ||
      gdata.type === "Synchronous motors"
    ) {
      if (
        gdata.baselineCurvesData &&
        gdata.baselineCurvesData.performanceCurvesData
      ) {
        const bdata = {
          name: "Baseline Curve",
          type: "spline",
          data: [],
        };
        const limit =
          gdata.baselineCurvesData.performanceCurvesData.data.length;
        const xBaselineTag = global.xAxisBaselineTag || "load";
        const yBaselineTag = global.yAxisBaselineTag || "current";
        for (let i = 0; i < limit; i++) {
          if (
            gdata.baselineCurvesData &&
            gdata.baselineCurvesData.performanceCurvesData
          ) {
            bdata.data.push({
              name: 'Baseline Curve',
              x:
                gdata.baselineCurvesData.performanceCurvesData.data[i][
                xBaselineTag
                ],
              y:
                gdata.baselineCurvesData.performanceCurvesData.data[i][
                yBaselineTag
                ],
              xPiTag: keyExchange[xBaselineTag],
              yPiTag: keyExchange[yBaselineTag],
              xunit: gdata.baselineCurvesData.performanceCurvesData.dataUnit[xBaselineTag],
              yunit: gdata.baselineCurvesData.performanceCurvesData.dataUnit[yBaselineTag],
            });
          }
        }
        series.push(bdata);
      }
    } else if (
      gdata.baselineCurvesData &&
      gdata.baselineCurvesData.curvesData &&
      gdata.baselineCurvesData.curvesData.curve1 &&
      gdata.baselineCurvesData.curvesData.curve1.operationalBaseLineCurves
    ) {
      // determined number of curves present
      const numberOfCurves = Object.keys(gdata.baselineCurvesData.curvesData)
        .length;

      for (let j = 0; j < numberOfCurves; j++) {
        const key = gdata.type === 'Reciprocating Compressor' ? 'inletPressure' : 'speed';
        const value = gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
          .operationalBaseLineCurves[key]['value'];
        const unit = gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
          .operationalBaseLineCurves[key]['unit'];
        const bdata = {
          name: gdata.type === 'Reciprocating Compressor' ? `Inlet Pressure: ${value} ${unit}` :
            `Speed: ${value} ${unit}`,
          type: "spline",
          data: [],
        };

        const limit =
          gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
            .operationalBaseLineCurves.data.length;
        const xBaselineTag = global.xAxisBaselineTag || "flowCoefficient";
        const yBaselineTag = global.yAxisBaselineTag || "headCoefficient";
        for (let i = 0; i < limit; i++) {
          if (
            gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
              .operationalBaseLineCurves.data[i][xBaselineTag] &&
            gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
              .operationalBaseLineCurves.data[i][yBaselineTag]
          ) {
            bdata.data.push({
              name: `Baseline curve${j + 1}`,
              x: parseFloat(
                getLumpSumValue(
                  gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
                    .operationalBaseLineCurves.data[i][xBaselineTag]
                )
              ),
              y: parseFloat(
                getLumpSumValue(
                  gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
                    .operationalBaseLineCurves.data[i][yBaselineTag]
                )
              ),
              xPiTag: keyExchange[xBaselineTag],
              yPiTag: keyExchange[yBaselineTag],
              xunit: gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
                .operationalBaseLineCurves.dataUnit[xBaselineTag],
              yunit: gdata.baselineCurvesData.curvesData[`curve${j + 1}`]
                .operationalBaseLineCurves.dataUnit[yBaselineTag],
            });
          }
        }
        series.push(bdata);
      }
    } else if (gdata.type === "Reciprocating Compressor") {
      const curveArray = global.curveType.map((item) => item.name);
      for (let curveInd = 0; curveInd < curveArray.length; curveInd++) {
        const curveData = gdata.baselineCurvesData.curvesData[curveArray[curveInd]];
        const numberOfCurves = Object.keys(curveData).length;
        for (let i = 0; i < numberOfCurves; i++) {
          const value =
            curveData[`curve${i + 1}`]?.operationalBaseLineCurves[
            'inletPressure'
            ]['value'];
          const unit =
            curveData[`curve${i + 1}`]?.operationalBaseLineCurves[
            'inletPressure'
            ]['unit'];
          const bdata = {
            name: `Inlet Pressure: ${value} ${unit}`,
            type: 'spline',
            data: [],
          };

          const limit =
            curveData[`curve${i + 1}`].operationalBaseLineCurves.data.length;
          const xBaselineTag = global.xAxisBaselineTag;
          const yBaselineTag = global.yAxisBaselineTag;
          for (let j = 0; j < limit; j++) {
            if (
              curveData[`curve${i + 1}`].operationalBaseLineCurves.data[j][
              xBaselineTag
              ] &&
              curveData[`curve${i + 1}`].operationalBaseLineCurves.data[j][
              yBaselineTag
              ]
            ) {
              bdata.data.push({
                name: `${curveArray[curveInd]} ${i + 1}`,
                x: parseFloat(
                  getLumpSumValue(
                    curveData[`curve${i + 1}`].operationalBaseLineCurves.data[j][
                    xBaselineTag
                    ]
                  )
                ),
                y: parseFloat(
                  getLumpSumValue(
                    curveData[`curve${i + 1}`].operationalBaseLineCurves.data[j][
                    yBaselineTag
                    ]
                  )
                ),
                xPiTag: keyExchange[xBaselineTag],
                yPiTag: keyExchange[yBaselineTag],
                xunit:
                  curveData[`curve${i + 1}`].operationalBaseLineCurves.dataUnit[
                  xBaselineTag
                  ],
                yunit:
                  curveData[`curve${i + 1}`].operationalBaseLineCurves.dataUnit[
                  yBaselineTag
                  ],
              });
            }
          }
          series.push(bdata);
        }

      }
    }
    else if (gdata.type === 'Screw Compressor') {
      const baseLineXAxis = global.xAxisBaselineTag,
        baseLineYAxis = global.yAxisBaselineTag,
        curveType = global.curveType[0] && global.curveType[0]['name'] ? global.curveType[0]['name'] : ""
      let curveData = gdata.baselineCurvesData['curvesData'],
        bdata = {
          name: curveType,
          type: 'spline',
          data: [],
        };
      const getCurveData = (curveName, baseLineXAxis, baseLineYAxis) => {
        let dataPoints = [],
          dataUnit = []
        if(curveName === "Adiabatic Efficiency" && curveData['adiabaticEfficiency'] && curveData['adiabaticEfficiency']['data']){
          dataPoints = curveData['adiabaticEfficiency']['data']['operationalBaseLineCurves']
          dataUnit = curveData['adiabaticEfficiency']['dataUnit']
        }
        else if (curveName === "Capacity Control" && curveData['capacityControl'] && curveData['capacityControl']['data']) {
          dataPoints = curveData['capacityControl']['data']['operationalBaseLineCurves']
          dataUnit = curveData['capacityControl']['dataUnit']
        }
        else if (curveName === "Volume Ratio" && curveData['volumetricRatio'] && curveData['volumetricRatio']['data']) {
          dataPoints = curveData['volumetricRatio']['data']['operationalBaseLineCurves']
          dataUnit = curveData['volumetricRatio']['dataUnit']
        }
        if (dataPoints && dataPoints[0]) {
          dataPoints.forEach(element => {
            bdata.data.push({
              name: "Screw Compressor",
              x: parseFloat(
                getLumpSumValue(
                  Number(element[baseLineXAxis])
                )
              ),
              y: parseFloat(
                getLumpSumValue(
                  Number(element[baseLineYAxis])
                )
              ),
              xPiTag: baseLineXAxis,
              yPiTag: baseLineYAxis,
              xunit: baseLineXAxis === 'suctionSlideValvePosition' ? dataUnit["suctionValvePosition"] :
                baseLineXAxis === 'dischargeSlideValvePosition' ? dataUnit["dischargeValvePosition"] : dataUnit[baseLineXAxis],
              yunit: baseLineYAxis === 'suctionSlideValvePosition' ? dataUnit["suctionValvePosition"] :
                baseLineYAxis === 'dischargeSlideValvePosition' ? dataUnit["dischargeValvePosition"] : dataUnit[baseLineYAxis],
            })
          });
        }
      }
      if (baseLineXAxis && baseLineYAxis && curveType !== "") {
        getCurveData(curveType, baseLineXAxis, baseLineYAxis)
      }
      series.push(bdata);
    }
    else if (gdata.type === 'Hydraulic Variable Speed Gear') {
      const baseLineXAxis = global.xAxisBaselineTag
      const baseLineYAxis = global.yAxisBaselineTag
      let dataPoints = []
      let curveData = gdata.baselineCurvesData['curvesData']

      if(curveData && curveData["torqueCurve"] && curveData["torqueCurve"]["curvesData"]){
        dataPoints = curveData["torqueCurve"]["curvesData"];
      }
      
      if (dataPoints && dataPoints[0]) {
        
        dataPoints.forEach((element) => {
          let curveName = "Guide Vane Position - " + element.identifier.design
          let bdata = {
            name: curveName,
            type: 'spline',
            data: [],
          };
          
          let curveDataPoints = element.data  
          curveDataPoints.forEach((dataPoint) => {
            let xValue = dataPoint[baseLineXAxis].design
            let yValue = dataPoint[baseLineYAxis].design
            let xUnit = dataPoint[baseLineXAxis].unit
            let yUnit = dataPoint[baseLineYAxis].unit

            bdata.data.push({
              name: curveName,
              x: parseFloat(getLumpSumValue(Number(xValue))),
              y: parseFloat(getLumpSumValue(Number(yValue))),
              xPiTag: baseLineXAxis,
              yPiTag: baseLineYAxis,
              xunit:xUnit,
              yunit:yUnit
            });
          });
          series.push(bdata);
        });
      }

      let maxLimitDataPoints = [];
      if(curveData && curveData["maxTorqueLimitCurve"] && curveData["maxTorqueLimitCurve"]["curvesData"]){
        maxLimitDataPoints = curveData["maxTorqueLimitCurve"]["curvesData"];
      }

      if (maxLimitDataPoints && maxLimitDataPoints[0]) {
        let curveName = "Max Torque Limit Curve "
          let bdata = {
            name: curveName,
            type: 'spline',
            data: [],
          };
          
          let curveDataPoints = maxLimitDataPoints[0].data  
          curveDataPoints.forEach((dataPoint) => {
            let xValue = dataPoint[baseLineXAxis].design
            let yValue = dataPoint[baseLineYAxis].design
            let xUnit = dataPoint[baseLineXAxis].unit
            let yUnit = dataPoint[baseLineYAxis].unit

            bdata.data.push({
              name: curveName,
              x: parseFloat(getLumpSumValue(Number(xValue))),
              y: parseFloat(getLumpSumValue(Number(yValue))),
              xPiTag: baseLineXAxis,
              yPiTag: baseLineYAxis,
              xunit:xUnit,
              yunit:yUnit
            });
          });
          series.push(bdata);
      }
    }
    else if (gdata.type === 'Radial Inflow Gas Expander') {
      const baseLineXAxis = global.xAxisBaselineTag
      const baseLineYAxis = global.yAxisBaselineTag
      let curveData = gdata.baselineCurvesData["curves"]

      
      if (curveData && curveData[0]) {
        curveData.forEach((element) => {
          let curveName = "Speed - " + getLumpSumValue(Number(element.identifier_value))
          let bdata = {
            name: curveName,
            type: 'spline',
            data: [],
          };
          
          let curveDataPoints = element.data  
          curveDataPoints.forEach((dataPoint) => {
            let xValue = dataPoint[baseLineXAxis]
            let yValue = dataPoint[baseLineYAxis]

            bdata.data.push({
              name: curveName,
              x: parseFloat(getLumpSumValue(Number(xValue))),
              y: parseFloat(getLumpSumValue(Number(yValue))),
              xPiTag: baseLineXAxis,
              yPiTag: baseLineYAxis,
            });
          });
          series.push(bdata);
        });
      }

    }
  }
  return series;
};

export const transformRunCardData = (gdata, global) => {
  let series = {};
  if (global && global.y) {
    const limit = global.y.length;

    for (let i = 0; i < limit; i++) {
      series = {
        ...series,
        [global.y[i].value]: {
          name: `${global.y[i].variableName} (${global.y[i].value})`,
          data: [],
          tooltip: {},
        },
      };
    }

    const obj = {};

    gdata.length &&
      gdata.forEach((run) => {
        run.data.length &&
          run.data.forEach((point) => {
            if (!obj[point.tag]) {
              Object.assign(obj, { [point.tag]: [] });
            }
          });
      });
    gdata.length &&
      gdata.forEach((run, index) => {
        Object.keys(obj).forEach((tag) => {
          obj[tag].push(null);
        });
        run.data.length &&
          run.data.forEach((point) => {
            obj[point.tag][index + 1] = _.isFinite(parseFloat(point.value))
              ? +getLumpSumValue(parseFloat(point.value))
              : null;
            const tooltip = point.unit ? ` ${point.unit}` : '';
            if (series[point.tag]) {
              series[point.tag].tooltip = { valueSuffix: tooltip };
            }
          });
      });

    series = Object.keys(series).map((tag) => ({
      name: series[tag].name,
      data: obj[tag] ? obj[tag] : [],
      tooltip: series[tag].tooltip ? series[tag].tooltip : '',
    }));
  }

  return _.values(series);
};

const keyExchange = {
  'load': 'Load',
  'efficiency': 'Efficiency',
  'flowCoefficient': 'Flow Coefficient',
  'headCoefficient': 'Head Coefficient',
  'headDrop': 'Head Drop',
  'volumetricFlow': 'Volumetric Flow',
  'workInput': 'Work Input',
  'volumeReduction': 'Volume Reduction',
  'adiabaticEfficiency': 'Adiabatic Efficiency',
  'inletVolume': 'Inlet Volume',
}