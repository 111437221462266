import React, { useState, useEffect } from 'react';
import { Button, Layout, Row, Col, Modal, Switch, Tabs, Empty } from 'antd';
import { MANAGE_PI_TAGS_CONSTANTS } from '../../../../constants/variables';
import { piTagColumns, iowTimers, datePick } from './iow.constants';
import FormFields from '../../../../common/FormFields/FormFields';
import Notification, {
  Types,
} from '../../../../common/Notification/Notification';
import { getTimeInFormat } from '../../../../libs/valueFormatter';
import _ from 'lodash';
import Tour from './touriow';

const { Header, Content } = Layout;
const { TabPane } = Tabs;

const TagDetails = (tagInfo, form, renderTagsData, isLoaded, filterData, iowTagListUpdate) => {
  const [editModal, setEditModal] = useState(false);
  const [iowSwitch, setIOWSwitch] = useState(false);
  const [criticalLimitLow, setCriticalLimitLow] = useState('');
  const [criticalLimitHigh, setCriticalLimitHigh] = useState('');
  const [standardLimitHigh, setStandardLimitHigh] = useState('');
  const [standardLimitLow, setStandardLimitLow] = useState('');
  const [optimalTarget, setOptimalTarget] = useState('');
  const [referenceStartDate, setreferenceStartDate] = useState('');
  const [criticalLimitLowTime, setcriticalLimitLowTime] = useState('');
  const [criticalLimitHighTime, setcriticalLimitHighTime] = useState('');
  const [standardLimitLowTime, setstandardLimitLowTime] = useState('');
  const [standardLimitHighTime, setstandardLimitHighTime] = useState('');
  const [optimalTimeRange, setOptimalTimeRange] = useState('');
  const [runStatusCheckIow, setrunStatusCheckIow] = useState(true);
  const [fromActiveKey, setFormActiveKey] = useState('1')
  const [editedKeys, setEditedKeys] = useState(new Set());

  const disableEditForm = () => {
    window.dispatchEvent(new Event('form-edit-false'));
  };

  useEffect(() => {
    if (tagInfo) {
      disableEditForm();
      form.setFieldsValue({
        criticalLimitLow: tagInfo?.criticalLimitLow,
        criticalLimitHigh: tagInfo?.criticalLimitHigh,
        standardLimitLow: tagInfo?.standardLimitLow,
        standardLimitHigh: tagInfo?.standardLimitHigh,
        optimalTarget: tagInfo?.optimalTarget,
        referenceStartDate: tagInfo?.referenceStartDate,
        optimalTimeRange: tagInfo?.optimalTimeRange
        >= 0 ? _.round(tagInfo?.optimalTimeRange / 3600, 3)
        : '',
        criticalLimitLowTime: tagInfo?.criticalLimitLowTime
        >= 0 ? _.round(tagInfo?.criticalLimitLowTime / 3600, 3)
        : '',
        criticalLimitHighTime: tagInfo?.criticalLimitHighTime
        >= 0 ? _.round(tagInfo?.criticalLimitHighTime / 3600, 3)
          : '',
        standardLimitLowTime: tagInfo?.standardLimitLowTime
        >= 0 ? _.round(tagInfo?.standardLimitLowTime / 3600, 3)
          : '',
        standardLimitHighTime: tagInfo?.standardLimitHighTime
        >= 0 ? _.round(tagInfo?.standardLimitHighTime / 3600, 3)
          : '',
      });
      const tempCriticalLimitHighTime = tagInfo?.criticalLimitHighTime
      >= 0 ? _.round(tagInfo?.criticalLimitHighTime / 3600, 3)
        : '';
      const tempCriticalLimitLowTime = tagInfo?.criticalLimitLowTime
      >= 0 ? _.round(tagInfo?.criticalLimitLowTime / 3600, 3)
        : '';
      const tempStandardLimitLowTime = tagInfo?.standardLimitLowTime
      >= 0 ? _.round(tagInfo?.standardLimitLowTime / 3600, 3)
        : '';
      const tempStandardLimitHighTime = tagInfo?.standardLimitHighTime
      >= 0 ? _.round(tagInfo?.standardLimitHighTime / 3600, 3)
        : '';
      const tempOptimalTimeRange = tagInfo?.optimalTimeRange
      >= 0 ? _.round(tagInfo?.optimalTimeRange / 3600, 3)
        : '';
      setCriticalLimitLow(tagInfo?.criticalLimitLow);
      setCriticalLimitHigh(tagInfo?.criticalLimitHigh);
      setStandardLimitHigh(tagInfo?.standardLimitHigh);
      setStandardLimitLow(tagInfo?.standardLimitLow);
      setOptimalTarget(tagInfo?.optimalTarget);
      setreferenceStartDate(tagInfo?.referenceStartDate);
      setcriticalLimitHighTime(tempCriticalLimitHighTime);
      setcriticalLimitLowTime(tempCriticalLimitLowTime);
      setstandardLimitLowTime(tempStandardLimitLowTime);
      setstandardLimitHighTime(tempStandardLimitHighTime);
      setOptimalTimeRange(tempOptimalTimeRange);
      setIOWSwitch(tagInfo?.enableIOW);
      setrunStatusCheckIow(tagInfo?.runStatusCheckIow);
    }
    // eslint-disable-next-line
  }, [tagInfo, setIOWSwitch]);

  const validateForm = (values) => {
    if (
      (!_.isEmpty(values['criticalLimitLow']) &&
        !(
          _.isNumber(_.toNumber(values['criticalLimitLow'])) &&
          !_.isNaN(_.toNumber(values['criticalLimitLow']))
        )) ||
      (!_.isEmpty(values['standardLimitLow']) &&
        !(
          _.isNumber(_.toNumber(values['standardLimitLow'])) &&
          !_.isNaN(_.toNumber(values['standardLimitLow']))
        )) ||
      (!_.isEmpty(values['standardLimitHigh']) &&
        !(
          _.isNumber(_.toNumber(values['standardLimitHigh'])) &&
          !_.isNaN(_.toNumber(values['standardLimitHigh']))
        )) ||
      (!_.isEmpty(values['optimalTarget']) &&
        !(
          _.isNumber(_.toNumber(values['optimalTarget'])) &&
          !_.isNaN(_.toNumber(values['optimalTarget']))
        )) ||
      (!_.isEmpty(values['criticalLimitHigh']) &&
        !(
          _.isNumber(_.toNumber(values['criticalLimitHigh'])) &&
          !_.isNaN(_.toNumber(values['criticalLimitHigh']))
        ))
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_VALUES_CORRECTLY
      );
      return false;
    }

    if (
      (_.isNull(criticalLimitLow) || criticalLimitLow === '') &&
      (_.isNull(criticalLimitHigh) || criticalLimitHigh === '') &&
      iowSwitch
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMITS_MANDATORY
      );
      return false;
    }
    if (
      parseFloat(values['criticalLimitLow']) >=
        parseFloat(values['standardLimitLow']) ||
      parseFloat(values['criticalLimitLow']) >=
        parseFloat(values['optimalTarget']) ||
      parseFloat(values['criticalLimitLow']) >=
        parseFloat(values['standardLimitHigh']) ||
      parseFloat(values['criticalLimitLow']) >=
        parseFloat(values['criticalLimitHigh'])
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_CRITICAL_LIMIT_LOW_CORRECTLY
      );
      return false;
    }
    if (
      parseFloat(values['criticalLimitHigh']) <=
        parseFloat(values['standardLimitHigh']) ||
      parseFloat(values['criticalLimitHigh']) <=
        parseFloat(values['optimalTarget']) ||
      parseFloat(values['criticalLimitHigh']) <=
        parseFloat(values['standardLimitLow']) ||
      parseFloat(values['criticalLimitHigh']) <=
        parseFloat(values['criticalLimitLow'])
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_CRITICAL_LIMIT_HIGH_CORRECTLY
      );
      return false;
    }
    if (
      parseFloat(values['standardLimitLow']) >=
        parseFloat(values['optimalTarget']) ||
      parseFloat(values['standardLimitLow']) >=
        parseFloat(values['standardLimitHigh'])
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_STANDARD_LIMIT_LOW_CORRECTLY
      );
      return false;
    }
    if (
      parseFloat(values['optimalTarget']) >=
        parseFloat(values['standardLimitHigh']) ||
      parseFloat(values['optimalTarget']) >=
        parseFloat(values['criticalLimitHigh'])
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_OPTIMAL_TARGET_CORRECTLY
      );
      return false;
    }
    if (
      parseFloat(values['standardLimitHigh']) >
      parseFloat(values['criticalLimitHigh'])
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_STANDARD_LIMIT_HIGH_CORRECTLY
      );
      return false;
    }
    if (
      parseFloat(values['optimalTimeRange']) < 0 ||
      parseFloat(values['criticalLimitLowTime']) < 0 ||
      parseFloat(values['criticalLimitHighTime']) < 0 || 
      parseFloat(values['standardLimitHighTime']) < 0 ||
      parseFloat(values['standardLimitLowTime']) < 0
    ) {
      Notification.show(
        Types.Error,
        MANAGE_PI_TAGS_CONSTANTS.ENTER_VALUES_CORRECTLY
      );
      return false;
    }
    return true;
  };

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        Notification.show(Types.Error, err);
      }
      const isValid = validateForm(values);
      if (isValid) {
        const payload = {
          piTag: tagInfo['piTag'],
          criticalLimitLow: parseFloat(values['criticalLimitLow']),
          criticalLimitHigh: parseFloat(values['criticalLimitHigh']),
          standardLimitLow: parseFloat(values['standardLimitLow']),
          standardLimitHigh: parseFloat(values['standardLimitHigh']),
          optimalTarget: parseFloat(values['optimalTarget']),
          referenceStartDate: referenceStartDate,
          enableIOW: iowSwitch,
          runStatusCheckIow: runStatusCheckIow
        };
        const specificKeys = [
        "criticalLimitLowTime",
        "criticalLimitHighTime",
        "optimalTimeRange",
        "standardLimitLowTime",
        "standardLimitHighTime"
         ];
        let payloadTimers = {
          tag: tagInfo['_id'],
          equipment_id: tagInfo['equipment_id'] || tagInfo['equipmentId'][0],
        };

       for (const propName of specificKeys) {
         const originalValueInSeconds = form.getFieldValue(propName) * 3600;
         if (Math.abs((tagInfo[propName] === undefined ? 0 : tagInfo[propName]) - originalValueInSeconds) > 3) {
           payloadTimers[propName] = originalValueInSeconds;
         }
       }

        const facilityId = tagInfo['facilityId']['_id'];
        let changedInLimits = false,changedInTimers = false;
        for (const value of editedKeys) {
          if (specificKeys.includes(value)) changedInTimers = true;
          else changedInLimits = true;
        }
        renderTagsData(payload, facilityId,payloadTimers,changedInLimits, changedInTimers);
        setEditedKeys(new Set())
        setEditModal(false);
      }
    });
  };

  const dateChange = (value) => {
    setreferenceStartDate(value);
    setEditedKeys(prevSet => new Set([...prevSet, "referenceStartDate"]));
  };

  const handleChange = (value, key) => {
    const iowValue = value ? true : false;
  
    if (key === 'iowSwitch' && iowValue) {
      if (
        !_.isNumber(tagInfo.criticalLimitHigh) &&
        !_.isNumber(tagInfo.criticalLimitLow)
      ) {
        Notification.show(
          Types.Error,
          MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMITS_MANDATORY
        );
      } else {
        const payload = {
          piTag: tagInfo['piTag'],
          enableIOW: iowValue,
        };
        const facilityId = tagInfo['facilityId']['_id'];
        setIOWSwitch(iowValue);
        renderTagsData(payload, facilityId);
        
        if (filterData !== '' && filterData !== iowValue)
          iowTagListUpdate(payload)
      }
    } else if (key === 'iowSwitch' && !iowValue) {
      const payload = {
        piTag: tagInfo['piTag'],
        enableIOW: iowValue,
      };
      const facilityId = tagInfo['facilityId']['_id'];
      setIOWSwitch(iowValue);
      renderTagsData(payload, facilityId);

      if (filterData !== '' && filterData !== iowValue)
         iowTagListUpdate(payload)
          
    } else if (key === 'criticalLimitLow') {
      setCriticalLimitLow(value.target.value);
    } else if (key === 'criticalLimitHigh') {
      setCriticalLimitHigh(value.target.value);
    } else if (key === 'standardLimitLow') {
      setStandardLimitLow(value.target.value);
    } else if (key === 'standardLimitHigh') {
      setStandardLimitHigh(value.target.value);
    } else if (key === 'optimalTarget') {
      setOptimalTarget(value.target.value);
    } else if (key === 'criticalLimitLowTime') {
      setcriticalLimitLowTime(value.target.value);
    } else if (key === 'criticalLimitHighTime') {
      setcriticalLimitHighTime(value.target.value);
    } else if (key === 'standardLimitLowTime') {
      setstandardLimitLowTime(value.target.value);
    } else if (key === 'standardLimitHighTime') {
      setstandardLimitHighTime(value.target.value);
    } else if (key === 'optimalTimeRange') {
      setOptimalTimeRange(value.target.value);
    } else if(key === 'runStatusCheckIow') {
      const payload = {
        piTag: tagInfo['piTag'],
        runStatusCheckIow: iowValue,
      };
      const facilityId = tagInfo['facilityId']['_id'];
      setrunStatusCheckIow(iowValue);
      renderTagsData(payload, facilityId);
    }
    setEditedKeys(prevSet => new Set([...prevSet, key]));
  };

  const handleCancel = () => {
    setEditModal(false);
    form.setFieldsValue({
      criticalLimitLow: tagInfo?.criticalLimitLow,
      criticalLimitHigh: tagInfo?.criticalLimitHigh,
      standardLimitLow: tagInfo?.standardLimitLow,
      standardLimitHigh: tagInfo?.standardLimitHigh,
      optimalTarget: tagInfo?.optimalTarget,
      referenceStartDate: tagInfo?.referenceStartDate,
      optimalTimeRange: tagInfo?.optimalTimeRange
       >=0 ? _.round(tagInfo?.optimalTimeRange / 3600, 3)
        : '',
      criticalLimitLowTime: tagInfo?.criticalLimitLowTime
      >=0 ? _.round(tagInfo?.criticalLimitLowTime / 3600, 3)
        : '',
      criticalLimitHighTime: tagInfo?.criticalLimitHighTime
      >=0 ? _.round(tagInfo?.criticalLimitHighTime / 3600, 3)
        : '',
      standardLimitLowTime: tagInfo?.standardLimitLowTime
      >=0 ? _.round(tagInfo?.standardLimitLowTime / 3600, 3)
        : '',
      standardLimitHighTime: tagInfo?.standardLimitHighTime
      >=0 ? _.round(tagInfo?.standardLimitHighTime / 3600, 3)
        : '',
    });
    setreferenceStartDate(tagInfo?.referenceStartDate);
  };

  const handleIOWTabChange = (key) => {
    setFormActiveKey(key)
  }

  const iowForm = () => {
    return (
      <Modal
        visible={editModal}
        title="IOW"
        okText={'Submit'}
        onCancel={() => handleCancel()}
        onOk={() => handleOk()}
      >
        <Tabs type="card" activeKey={fromActiveKey} onChange={(key) => handleIOWTabChange(key)}>
          <TabPane tab="IOW Limits" key="1">
            <div className="iowForm">
              <FormFields
                formFields={piTagColumns({
                  tagData: tagInfo,
                  criticalLimitLow: criticalLimitLow,
                  criticalLimitHigh: criticalLimitHigh,
                  standardLimitLow: standardLimitLow,
                  standardLimitHigh: standardLimitHigh,
                  optimalTarget: optimalTarget,
                  change: handleChange,
                })}
                form={form}
              />
            </div>
          </TabPane>
          <TabPane tab="IOW Exposure Timers" key="2">
            <div className="iowForm">
              {datePick({
                change: dateChange,
                referenceStartDate: referenceStartDate,
              })}
              <FormFields
                formFields={iowTimers({
                  tagData: tagInfo,
                  referenceStartDate: referenceStartDate,
                  criticalLimitLowTime: criticalLimitLowTime,
                  criticalLimitHighTime: criticalLimitHighTime,
                  standardLimitHighTime: standardLimitHighTime,
                  standardLimitLowTime: standardLimitLowTime,
                  optimalTimeRange: optimalTimeRange,
                  change: handleChange,
                })}
                form={form}
              />
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    );
  };

  const handleEdit = () => {
    form.setFieldsValue({
      optimalTimeRange:
        tagInfo?.optimalTimeRange >= 0
          ? _.round(tagInfo?.optimalTimeRange / 3600, 3)
          : '',
      criticalLimitLowTime:
        tagInfo?.criticalLimitLowTime >= 0
          ? _.round(tagInfo?.criticalLimitLowTime / 3600, 3)
          : '',
      criticalLimitHighTime:
        tagInfo?.criticalLimitHighTime >= 0
          ? _.round(tagInfo?.criticalLimitHighTime / 3600, 3)
          : '',
      standardLimitLowTime:
        tagInfo?.standardLimitLowTime >= 0
          ? _.round(tagInfo?.standardLimitLowTime / 3600, 3)
          : '',
      standardLimitHighTime:
        tagInfo?.standardLimitHighTime >= 0
          ? _.round(tagInfo?.standardLimitHighTime / 3600, 3)
          : '',
    });
    setEditModal(true);
  };

  return (
     <>
    {Tour(isLoaded, setEditModal, handleIOWTabChange)}
    {tagInfo ? (
    <Layout
      className="site-layout"
      style={{
        background: 'white',
      }}
    >
      <Header
        className="trends-header"
        style={{ marginTop: '5px', padding: 0, background: 'white' }}
      >
        {/* <Row>
          <Col span={4}> */}
        <div className="edit-button">
          <Button type="primary" shape="round" onClick={handleEdit}>
            Edit
          </Button>
        </div>
        {/* </Col>
        </Row> */}
      </Header>
      <div style={{ padding: 10 }}> </div>
      <Content style={{ overflow: 'initial' }} className="run-details">
        <div className="detail-content KPIDetails">
          <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>Tag Info</b>
            </h>
          </div>
          <Row>
            <Col span={18} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.VARIABLE_TAG}:
              <span>
                {'  '}
                {tagInfo?.piTag}
              </span>
            </Col>
            <Col className="switch" span={6} style={{ textAlign: 'right', padding: 10 }}>
              Enable IOW:
              <span>
                {'  '}
                <Switch
                  disabled={false}
                  className="user-management-switch"
                  checked={tagInfo?.enableIOW}
                  onChange={(e) => {
                    handleChange(e, 'iowSwitch');
                  }}
                />
              </span>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={18} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.VARIABLE_NAME}:
              <span>
                {'  '}
                {tagInfo?.piVariableName}
              </span>
            </Col>
            <Col span={6} style={{ textAlign: 'right', padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.RUN_STATUS_CHECK_IOW}:
              <span>
                {'  '}
                <Switch
                  disabled={false}
                  className="user-management-switch"
                  checked={
                    tagInfo?.runStatusCheckIow === undefined
                      ? true
                      : tagInfo?.runStatusCheckIow
                  }
                  onChange={(e) => {
                    handleChange(e, 'runStatusCheckIow');
                  }}
                />
              </span>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={24} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.MECHADEMY_UNIT}:
              <span>
                {'  '}
                {tagInfo?.mechademyUnits}
              </span>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={24} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.VARIABLE_MAX_VALUE}:
              <span>
                {'  '}
                {tagInfo?.maxThreshold}
              </span>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={24} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.VARIABLE_MIN_VALUE}:
              <span>
                {'  '}
                {tagInfo?.minThreshold}
              </span>
            </Col>
          </Row>
        </div>
        <div style={{ padding: 10 }}> </div>
        <div className="detail-content KPIDetails limits">
          <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>IOW Limits</b>
            </h>
          </div>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_LOW}:
              <span>
                {'  '}
                {tagInfo?.criticalLimitLow}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIMIT_HIGH}:
              <span>
                {'  '}
                {tagInfo?.criticalLimitHigh}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_LOW}:
              <span>
                {'  '}
                {tagInfo?.standardLimitLow}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIMIT_HIGH}:
              <span>
                {'  '}
                {tagInfo?.standardLimitHigh}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TARGET}:
              <span>
                {'  '}
                {tagInfo?.optimalTarget}
              </span>
            </Col>
          </Row>
        </div>
        <div style={{ padding: 10 }}> </div>
        <div className="detail-content KPIDetails exposure">
          <div className="detail-heading" style={{ padding: 10 }}>
            <h>
              <b>IOW Exposure Timers</b>
            </h>
          </div>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.REFERENCE_START_DATE}:
              <span>
                {'  '}
                {getTimeInFormat(tagInfo?.referenceStartDate)}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.OPTIMAL_TIME_RANGE}:
              <span>
                {'  '}
                {tagInfo?.optimalTimeRange
                  >=0 ? _.round(tagInfo.optimalTimeRange / 3600, 3)
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_LOW_TIME}:
              <span>
                {'  '}
                {tagInfo?.criticalLimitLowTime
                  >=0 ? _.round(tagInfo?.criticalLimitLowTime / 3600, 3)
                  : ""}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.CRITICAL_LIM_HIGH_TIME}:
              <span>
                {'  '}
                {tagInfo?.criticalLimitHighTime
                  >=0 ? _.round(tagInfo?.criticalLimitHighTime / 3600, 3)
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_LOW_TIME}:
              <span>
                {'  '}
                {tagInfo?.standardLimitLowTime
                  >=0 ? _.round(tagInfo?.standardLimitLowTime / 3600, 3)
                  : ""}
              </span>
            </Col>
            <Col span={12} style={{ padding: 10 }}>
              {MANAGE_PI_TAGS_CONSTANTS.STANDARD_LIM_HIGH_TIME}:
              <span>
                {'  '}
                {tagInfo?.standardLimitHighTime
                 >=0 ? _.round(tagInfo?.standardLimitHighTime / 3600, 3)
                  : ""}
              </span>
            </Col>
          </Row>
        </div>
      </Content>
      {iowForm()}
    </Layout>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 50,
      }}
      description={
        <span>
          <b>Empty Tags</b>
        </span>
      }
    ></Empty>
    )}
  </>
  )
};

export default TagDetails;
