import { Modal, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import JoyRide from "react-joyride";
import tourPages from "../../tourConstant";
import "./RunsDetail.scss"
import { setTourState } from "../../../../redux/actions/runTags.action";
import {
  addTourPage, 
  userRole
} from "../../../../redux/actions/login.action";
import ReactPlayer from "react-player";

const Tour = (isLoaded) => {
  const user = useSelector((state) => state.LoginReducer.apiState);
  const dispatch = useDispatch();
  const [askTour, setAskTour] = useState(true);
  const [startTour, setStartTour] = useState(false);
  const [modalPopup, setModalPopup] = useState(false);
  const [disableScroll, setDisableScroll] = useState(true)
  const [PIPEnabled, setPIPEnabled] = useState(false)

  useEffect(() => {
    if (user.tourTaken) {
      for (let i = 0; i < user.tourTaken.length; i++) {
        if (user.tourTaken[i] === tourPages.runPage) {
          setAskTour(false);
          setStartTour(false);
        }
      }
    }
  }, [user]); // eslint-disable-line

  
  const handleGif = (step) => {
    const recalcBtn = () => {
      let btnColor = ""
      if(PIPEnabled === false){
        btnColor = "btn-default autoRecalc close-btn"
        return btnColor
      }
      else{
        btnColor = "btn-disabled autoRecalc "
        return btnColor
      }
    }
    if (modalPopup && step === "addRun") {
      return (
        <Modal visible={true}
          style={{
            maxWidth: 'inherit'
          }}
          title="Runs page tour"
          destroyOnClose={() => { setModalPopup(false); setAskTour(false); }}
          onCancel={() => { setModalPopup(false); setAskTour(false); }}
          className = "runDetails"
          footer={[
            <div className="run-gif-popup">
              <Button className = {recalcBtn()} onClick={() => { setAskTour(false); setModalPopup(false) }} disabled = {PIPEnabled}> 
              {(PIPEnabled)? "Close Pic-in-Pic mode first": "Close"}
              </Button>
            </div>
          ]}
          maskClosable = {false}
          closable={false}
        >
          <ReactPlayer url="https://mechademy-tour-bucket.s3.us-east-2.amazonaws.com/Runs-Page/Add+Run.mp4" playing={false} controls={true} width="110vh" height="55vh" 
          onEnablePIP = {() => {setPIPEnabled(true)}} onDisablePIP = {() => {setPIPEnabled(false)}}
          />
          <br />
        </Modal>
      )
    }
    if (modalPopup && step === "recalculate") {
      return (
        <Modal visible={true}
          style={{
            maxWidth: 'inherit'
          }}
          closable={false}
          title="Runs page tour"
          className = "runDetails"
          destroyOnClose={() => { setModalPopup(false); setAskTour(false); }}
          onCancel={() => { setModalPopup(false); setAskTour(false); }}
          footer={[
            <div className="run-gif-popup">
               <Button className = {recalcBtn()} onClick={() => { setAskTour(false); setModalPopup(false) }} disabled = {PIPEnabled}> 
              {(PIPEnabled)? "Close Pic-in-Pic mode first": "Close"}
              </Button>
            </div>
          ]}
          maskClosable = {false}
        >
          <ReactPlayer url="https://mechademy-tour-bucket.s3.us-east-2.amazonaws.com/Runs-Page/RecalculateRun.mp4" playing={false} controls={true} width="110vh" height="55vh" 
          onEnablePIP = {() => {setPIPEnabled(true)}} onDisablePIP = {() => {setPIPEnabled(false)}}
          />
          <br />
        </Modal>
      )
    }
    if (modalPopup && step === "edit") {
      return (
        <Modal visible={true}
          closable={false}
          style={{
            maxWidth: 'inherit'
          }}
          title="Runs page tour"
          destroyOnClose={() => { setModalPopup(false); setAskTour(false); }}
          onCancel={() => { setModalPopup(false); setAskTour(false); }}
          className = "runDetails"
          footer={[
            <div className="run-gif-popup">
               <Button className = {recalcBtn()} onClick={() => { setAskTour(false); setModalPopup(false) }} disabled = {PIPEnabled}> 
              {(PIPEnabled)? "Close Pic-in-Pic mode first": "Close"}
              </Button>
            </div>
          ]}
          maskClosable = {false}
        >
          <ReactPlayer url="https://mechademy-tour-bucket.s3.us-east-2.amazonaws.com/Runs-Page/Edit+Run.mp4" playing={false} controls={true} width="110vh" height="55vh" 
          onEnablePIP = {() => {setPIPEnabled(true)}} onDisablePIP = {() => {setPIPEnabled(false)}}
          />
          <br />
        </Modal>
      )
    }
  }
  const stepText = (text, tourNumber) => {
    if (tourNumber === 2) {
      return (
        <>
          <div className="runDetails">
            Click here to<span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span>after adding, editing or deleting Runs.<br></br>
            <button className="tour-btn" onClick={() => setModalPopup(true)}>See how to do it</button>
            {handleGif("recalculate")}
          </div>
        </>
      );
    }
    else if (tourNumber === 3) {
      return (
        <>
          <div className="runDetails">
            <span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span> a new Run by clicking this button. <br></br>
            <button className="tour-btn" onClick={() => setModalPopup(true)}>See how to do it</button>
          </div>
          {handleGif("addRun")}
        </>
      );
    }
    else if (tourNumber === 4) {
      return (
        <div className="runDetails">
          <span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span>  an existing Run by clicking this button.<br></br>
          <button className="tour-btn" onClick={() => setModalPopup(true)}>See how to do it</button>
          {handleGif("edit")}
        </div>
      );
    }
    else if (tourNumber === 5) {
      return (
        <>
          <span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span>the selected Run by clicking this button
        </>
      );
    }
    else if (tourNumber === 6) {
      return (
        <>
          Click here to change the<span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span>
        </>
      );
    }
    else if (tourNumber === 7) {
      return (
        <>
          This section contains various<span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span> for the selected Run
        </>
      );
    }
    else if (tourNumber === 8) {
      return (
        <>
          This section contains<span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span> for the selected Run
        </>
      );
    }
    else if (tourNumber === 9) {
      return (
        <>
          <span style={{ fontSize: "1.1em", fontWeight: "bold" }}>{text}</span>for the selected Run here. These comments shall be visible to all users who have access to the equipment data.
        </>
      );
    }
  };

  const TOUR_STEPS = [
    {
      target: ".ant-tabs-tab-active",
      title: "Runs Page",
      content: "Our new Runs Tab feature is available at the equipment level.",
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".menuStyle",
      title: "Runs Panel",
      content: "This section contains historical Runs, tabulated in reverse chronological order. The list is paginated for ease of access.",
      disableBeacon: true,
      placement: "right-start",
    },
    {
      target: ".newRun",
      title: "Add Run",
      content: stepText("Manually Add", 3),
      disableBeacon: true,
    },

    {
      target: ".editRun",
      title: "Edit Run",
      content: stepText("Manually Edit", 4),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".deleteRun",
      title: "Delete Run",
      content: stepText("Manually Delete ", 5),
      disableBeacon: true,
      placement: "left",
    },
    {
      target: ".autoRecalc",
      title: "Auto Recalculate KPIs ON or OFF",
      content: stepText(" KPI Recalculate Mode.", 6),
      disableBeacon: true,
    },
    {
      target: ".reacalulate",
      title: "Recalculate KPIs",
      content: stepText(" manually Recalculate KPIs ", 2),
      disableBeacon: true,
    },
    {
      target: ".filterRun",
      title: "Filter Runs",
      content: "Filter the Runs for a desired period by selecting a date range and clicking the filter button",
      disableBeacon: true,
    },
    {
      target: ".KPIDetails",
      title: "Run KPIs",
      content: stepText(" Run based Key Performance Indicators", 7),
      disableBeacon: true,
      placement: "left"
    },
    {
      target: ".fail-main",
      title: "Failure/Maintainence Info",
      content: stepText(" Failure and Maintenance related information ", 8),
      disableBeacon: true,
      placement: "top",
    },
    {
      target: ".comment-section",
      title: "Comments",
      content: stepText(" Add, Edit or Delete Comments ", 9),
      disableBeacon: true,
      placement: "top",
    },
  ];


  const handleJoyrideCallback = data => {
    if (data.action === "skip") {
      setStartTour(false);
      setAskTour(false);
      dispatch(setTourState(false))
      dispatch(addTourPage(user.userId, tourPages.runPage))
      dispatch(userRole())
    }
    if (data.action === "reset") {
      setStartTour(false);
      setAskTour(false);
      dispatch(setTourState(false))
      dispatch(addTourPage(user.userId, tourPages.runPage))
      dispatch(userRole())
    }
    if (data.action === "close") {
      setStartTour(false);
      setAskTour(false);
      dispatch(setTourState(false))
      dispatch(addTourPage(user.userId, tourPages.runPage))
      dispatch(userRole())
    }
    if (data.index === 7) {
      setDisableScroll(false)
    }
  };


  const demo = () => {
    if (askTour && isLoaded === true) {
      return (
        <Modal visible={true}
          closable={false}
          title="Alert"
          className="Trends"
          destroyOnClose={() => { setStartTour(false); setAskTour(false); }}
          onCancel={() => { setStartTour(false); setAskTour(false); }}
          footer={[
            <Button key="1" onClick={() => {
              setStartTour(false); setAskTour(false); dispatch(setTourState(false));
              dispatch(addTourPage(user.userId, tourPages.runPage))
            }}>No</Button>,
            <span className="trends-tour-popup">
              <Button key="2" onClick={() => { setStartTour(false); setAskTour(false); }}>Maybe Later</Button>,
              <Button key="3" type="primary" onClick={() => { setStartTour(true); setAskTour(false); dispatch(setTourState(true)) }}>
                Yes
              </Button>
            </span>
          ]}
        >
          <div> Would you like to take a tour of the new Runs page ?</div>
          <br />
        </Modal>
      );
    }
    else if (startTour) {
      return (
        <div className="runDetails">
          <JoyRide
            steps={TOUR_STEPS}
            continuous={true}
            showSkipButton={true}
            locale={{
              last: "End tour",
              skip: "Skip",
            }}
            styles={{
              tooltipContainer: {
                textAlign: "center",
                letterSpacing: "1px"
              },
              buttonNext: {
                backgroundColor: "#363b4e",
                fontSize: "0.9em"
              },
              buttonBack: {
                marginRight: 20,
                color: "black",
                fontSize: "0.9em"
              },
              options: {
                backgroundColor: "white",
                arrowColor: "rgb(54, 59, 78)"
              },
              tooltip: {
                fontSize: "0.9em",
                fontStyle: "None"
              },
              tooltipTitle: {
                fontSize: "1.2em",
                fontStyle: "None"
              },
              buttonSkip: {
                fontSize: "0.9em"
              }
            }}
            scrollOffset={100}
            showProgress={true}
            debug={true}
            callback={handleJoyrideCallback}
            scrollToFirstStep={true}
            disableCloseOnEsc={false}
            disableScrolling={disableScroll}
            disableOverlayClose={true}
            className="tour-class"
          />
        </div>
      );
    }
    return (
      <></>
    );

  };
  return (
    <>
      {demo()}
    </>
  );
};
export default Tour;