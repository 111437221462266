import { Empty, Switch, message } from 'antd';
import _ from 'lodash';
import './Trends.scss';
import moment from 'moment';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import Notification, { Types } from '../../../../common/Notification/Notification';
import { addNewCardMessages, SOCKET_MSG } from '../../../../constants/messages';
import { getPlantTime, getBatchTime, clearBatchState, clearPlantState } from "../../../../redux/actions/plantTime.action";
import {
  clearAddNewCardState,
  clearDeleteKpiState,
  clearUpdataKpiState,
  deleteKpiById,
  onUpdateKpi,
  bulkUpdateKpi,
} from '../../../../redux/AddNewCard/addNewCard.action';
import { deleteIDB } from '../../../../services/indexedDB';
import { NewKPISocketListner, RemoveListeningNewKpi } from '../../../../socket/alerts/alerts.listner';
import AddNewCard from '../../AddNewCard/AddNewCard';
import { cardTypeConfigMapper } from '../../AddNewCard/AddNewCard.constants';
import {
  getParticularTrendDataByKPIId,
  getTrendByKPIId,
  getTrendsByLevelId,
  getTrendsById,
  clearTrendsState,
  searchKPI,
  setInspectionModeOff,
} from './../../../../redux/actions/trends.action';
import PropType from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Row, Col } from 'antd';
import { CalendarOutlined, PercentageOutlined } from '@ant-design/icons';
import { getTimeInFormat } from '../../../../libs/valueFormatter';
import Search from 'antd/lib/input/Search';
import Tour from './tourTrends2';
import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';



const { Sider, Content, Header } = Layout;

const Trends2 = (props) => {
    const {
      info,
      getTrendsByLevelId,
      userRole,
      activeKey,
      trends,
      deleteKpiById,
      onUpdateKpi,
      getParticularTrendDataByKPIId,
      getPlantTime,
      getTrendByKPIId,
      clearDeleteKpiState,
      getTrendsById,
      clearUpdataKpiState,
      clearTrendsState,
      plantTimeReducerData,
      TrendsReducerAddNewCardStatus,
      TrendsReducerIsSuccessEdit,
      plantTimeReducerIsSuccess,
      TrendsReducerStateIsSuccess,
      trendsData,
      trendsAddNewCardData,
      trendsAddNewCardError,
      isUpdateKPISuccess,
      isUpdateKPIError,
      updateKPIErrorMessage,
      isDeleteKpiReducerSuccess,
      isDeleteKpiReducerError,
      deleteKpiReducerMessage,
      deleteKpiId,
      getBatchTime,
      batchTimeIsSuccess,
      batchTimeData,
      clearBatchState,
      clearPlantState,
      TrendsDataProcessed,
      bulkUpdateKpi,
      searchKPI,
      trendsError,
      trendsErrorMessage,
      globalInspectionMode,
      setInspectionModeOff,
    } = props;
    const [visible, setVisible] = useState(false);
    const [editableCard, setEditableCard] = useState(null);
    const [kpiIdToDelete, setKpiIdToDelete] = useState('');
    const [plantTime, setPlantTime] = useState(null);
    const [kpiIdToUpdate, setKpiIdToUpdate] = useState('');
    const [facilityId, setFacilityId] = useState('');
    const [selectedKPI, setSelectedKPI] = useState(null);
    const [batchTime, setBatchTime] = useState(null);
    const [selectedKey, setSelectedKey] = useState(['0']);
    const [dateFromLabel, setDateFromLabel] = useState('');
    const [dateToLabel, setDateToLabel] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [inspectionModeOp, setInspectionModeOp] = useState(false);
    const [isXAxisVisible, setXAxisVisibility] = useState(true);
    const [isYAxisVisible, setYAxisVisibility] = useState(true);
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
    const [zoomOutDateFromLabel, setZoomOutDateFromLabel] = useState('');
    const [zoomOutDateToLabel, setZoomOutDateToLabel] = useState('');
    const [zoomIn, setZoomIn] = useState(false);
    const [numberOfDays, setNumberOfDays] = useState('');
    const [metaData, setMetaData] = useState(false);
    const [globalTimeRange, setGlobalTimeRange] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const currentKPI = useRef(null);

    const getDashboardInfo = useCallback(() => {
        if (info && info.dataRef && activeKey === '7') {
            const presentFacilityId = info && info.type === 'facility' ? info['dataRef']['_id'] :
                info && (info.type !== 'facility') ? info['dataRef']['facilityId'] : '';
            setFacilityId(presentFacilityId);
            getBatchTime();
            getTrendsByLevelId(
                info.type,
                info.dataRef._id ? info.dataRef._id : info.dataRef.info._id
            );
        }
    }, [info, getTrendsByLevelId, getBatchTime, activeKey]);

    const onNewKPIUpdate = useCallback(() => {
      if (info && info.dataRef && activeKey === '7') {
        getBatchTime();
      }
    }, [info, getBatchTime, activeKey]);    
    
    const initKPIListener = useCallback(
      () =>
        NewKPISocketListner((_event) => {
          if (!currentKPI.current?.inspection?.mode) {
            Notification.show(Types.Success, SOCKET_MSG.KPI_MSG);
            onNewKPIUpdate();
          }
          // eslint-disable-next-line
        }),
      [onNewKPIUpdate]
    );

    // useEffect cases

    // acts as componentDidMount
    useEffect(() => {
            getDashboardInfo();
        // Attach New KPI Listener at component Mount
        initKPIListener();
        // Remove KPI Listener and cached data in Index DB at unmount
        return () => {
            RemoveListeningNewKpi();
            deleteIDB();
        };
    }, [getDashboardInfo, initKPIListener]);

    useEffect(() => {
        getDashboardInfo();
    }, [getDashboardInfo, info]);

    useEffect(() => {
        if (plantTimeReducerIsSuccess) {
            clearPlantState();
            const plantTime = plantTimeReducerData && plantTimeReducerData.to;
            setPlantTime(plantTime);
        }
    }, [plantTimeReducerIsSuccess, plantTimeReducerData, clearPlantState]);

    useEffect(() => {
        if (batchTimeIsSuccess && activeKey === '7') {
            clearBatchState();
            const batchTime = batchTimeData && batchTimeData.to;
            setBatchTime(batchTime);
        }
    }, [batchTimeIsSuccess, batchTimeData, clearBatchState, activeKey]);

    useEffect(() => {
        if (trends && !TrendsReducerIsSuccessEdit) {
            const getDashboardData = () => {
                if (trends && trends.length > 0) {
                    let trendsKPI;
                    if (selectedKPI === null) {
                        trendsKPI = trends[0];
                        setSelectedKPI(trends[0])
                    } else  {
                      trendsKPI = selectedKPI;
                    }
                    const currentKpiId = trendsKPI && trendsKPI['_id'];
                    trendsKPI =
                      trendsKPI &&
                      trends &&
                      trends.find((i) => {
                        return i._id === currentKpiId;
                      });
                    let dateFrom = null, dateTill = null;
                    if( !trendsKPI?.inspection?.mode )  {
                        const currentDateTime = moment(batchTime * 1000);
                        const currentDateTime2 = _.cloneDeep(currentDateTime);
                        const duration = trendsKPI?.timeDuration ? trendsKPI.timeDuration : 0;
                        switch (duration) {
                            case 1:
                                dateFrom = currentDateTime.subtract(4, "hours").valueOf();
                                dateTill = currentDateTime2.valueOf();
                                break;
                            case 2:
                                dateFrom = currentDateTime.subtract(8, "hours").valueOf();
                                dateTill = currentDateTime2.valueOf();
                                break;
                            case 3:
                                dateFrom = currentDateTime.subtract(1, "days").valueOf();
                                dateTill = currentDateTime2.valueOf();
                                break;
                            case 4:
                                dateFrom = currentDateTime.subtract(7, "days").valueOf();
                                dateTill = currentDateTime2.valueOf();
                                break;
                            case 5:
                                dateFrom = currentDateTime.subtract(1, "months").valueOf();
                                dateTill = currentDateTime2.valueOf();
                                break;
                            case 6:
                              dateFrom = currentDateTime.subtract(3, "months").valueOf();
                              dateTill = currentDateTime2.valueOf();
                              break;
                            case 7:
                              dateFrom = currentDateTime.subtract(6, "months").valueOf();
                              dateTill = currentDateTime2.valueOf();
                              break;
                            default:
                                dateFrom = currentDateTime.subtract(2, "hours").valueOf();
                                dateTill = currentDateTime2.valueOf();
                                break;
                        }
                    }
                    else    {
                        dateFrom = parseInt(trendsKPI.inspection.viewDateFrom);
                        dateTill = parseInt(trendsKPI.inspection.viewDateTill);
                    }
                    setDateFromLabel(dateFrom);
                    setDateToLabel(dateTill);
                    const numberOfDays =
                      ((dateTill - dateFrom) / (24 * 3600) / 1000);
                    setNumberOfDays(numberOfDays);
                    if (
                      dateFrom !== trendsData[trendsKPI?._id]?.viewDateFrom ||
                      dateTill !== trendsData[trendsKPI?._id]?.viewDateTill
                    )   {
                      getTrendByKPIId(trendsKPI?._id, dateFrom, dateTill);
                    }
                }
            };
            getDashboardData();
            getPlantTime();
            getBatchTime();
        }
        // eslint-disable-next-line
    }, [TrendsReducerStateIsSuccess, getTrendByKPIId, trends, getPlantTime, selectedKPI, getBatchTime, batchTime]);

    useEffect(() => {
        if (TrendsReducerAddNewCardStatus) {
            const cardId = trendsAddNewCardData['_id'];
            const currentDateTime1 = moment(batchTime*1000);
            const currentDateTime2 = currentDateTime1;
            let dateTill = currentDateTime2.valueOf();
            let dateFrom = currentDateTime1.subtract(2, "hours").valueOf();
            getTrendByKPIId(cardId, dateFrom, dateTill);
            setSelectedKPI(trendsAddNewCardData);
            setSelectedKey([(trends.length - 1).toString()]);
        }
    }, [TrendsReducerAddNewCardStatus, getTrendByKPIId, trendsAddNewCardData, trends, batchTime]);

    useEffect(() => {
        if (trendsAddNewCardError) {
            Notification.show(Types.Error, 'Card not added');
        }
    }, [trendsAddNewCardError]);

    useEffect(() => {
        if (deleteKpiId === kpiIdToDelete && isDeleteKpiReducerError) {
            const message = deleteKpiReducerMessage;
            Notification.show(Types.Error, `${message}`);
            clearDeleteKpiState();
            setKpiIdToDelete('');
        }
    }, [isDeleteKpiReducerError, deleteKpiReducerMessage, clearDeleteKpiState, kpiIdToDelete, deleteKpiId]);

    useEffect(() => {
        if (isDeleteKpiReducerSuccess) {
            Notification.show(Types.Success, `${addNewCardMessages['DELETED']}`);
            clearDeleteKpiState();
            let trendsKey = trends && trends?.length > 0 ? '0' : '';
            let trendsKPI = trends && trends?.length > 0 ? trends[0] : null;
            setSelectedKPI(trendsKPI);
            setSelectedKey(trendsKey);
            setKpiIdToDelete('')
        }
    }, [isDeleteKpiReducerSuccess, clearDeleteKpiState, trends]);

    useEffect(() => {
        if (isUpdateKPISuccess) {
            setVisible(false);
            clearUpdataKpiState();
            Notification.show(Types.Success, `${addNewCardMessages['UPDATED']}`);
            if (kpiIdToUpdate) {
                getTrendsById(kpiIdToUpdate);
            }
        }
    }, [isUpdateKPISuccess, clearUpdataKpiState, getTrendsById, kpiIdToUpdate]);

    useEffect(() => {
        if (isUpdateKPIError) {
            const message = updateKPIErrorMessage;
            Notification.show(Types.Error, `${message}`);
            clearUpdataKpiState();
        }
    }, [isUpdateKPIError, clearUpdataKpiState, updateKPIErrorMessage]);

    useEffect(() => {
        if(TrendsReducerIsSuccessEdit && kpiIdToUpdate) {
            clearTrendsState();
        }
        if (TrendsReducerIsSuccessEdit && kpiIdToUpdate&& editMode) {
            const updatedKPI = trends.find((item) => {
                return item._id === kpiIdToUpdate;
            });
            setSelectedKPI(updatedKPI);
            setEditMode(false);
            const dateFrom = trendsData[kpiIdToUpdate]?.viewDateFrom;
            const dateTill = trendsData[kpiIdToUpdate]?.viewDateTill;
            const cardType = 'trends';
            const id = kpiIdToUpdate;
            if (dateFrom && dateTill && editMode && !inspectionModeOp) {
                getParticularTrendDataByKPIId({ id, cardType, dateFrom: dateFrom, dateTill: dateTill });
            }
            setInspectionModeOp(false);
        }
    }, [TrendsReducerIsSuccessEdit, trendsData, kpiIdToUpdate, getParticularTrendDataByKPIId, clearTrendsState, trends, editMode, inspectionModeOp]);

    useEffect(() => {
        if (trends && trends.length > 0 && activeKey === '7') {
          setSelectedKPI(trends[0]);
          setSelectedKey(['0']);
        }
    }, [info, trends, activeKey]);

    useEffect(() => {
      setSearchKeyword('');
    }, [info]);

    useEffect(() => {
        currentKPI.current = selectedKPI;
        setMetaData(false);
        setZoomIn(false);
    },[selectedKPI])

    useEffect(() => {
    if(selectedKPI && TrendsDataProcessed)  {
        const numberOfDays = (dateToLabel - dateFromLabel) / (24 * 3600) / 1000;
        setNumberOfDays(numberOfDays);
        clearTrendsState();
    }
    }, [TrendsDataProcessed, selectedKPI, zoomIn, trendsData, clearTrendsState, dateFromLabel, dateToLabel]);

    useEffect(() => {
        if(trendsError) {
            Notification.show(Types.Error, trendsErrorMessage);
            clearTrendsState();
        }
    }, [trendsError, clearTrendsState, trendsErrorMessage]);

    useEffect(()=>  {
      if(globalInspectionMode)  {
        setIsDatePickerVisible(true);
        setGlobalTimeRange(true);
        setInspectionModeOff();
      }
    }, [globalInspectionMode, setInspectionModeOff])

    const onDelete = kpiId => {
        setKpiIdToDelete(kpiId);
        deleteKpiById(kpiId, 'trends');
    };

    const onTimeChange = (kpiData, duration, type) => {
        const payload = {
            timeDuration: duration
        };
        if(type === "now")  {
            setZoomIn(false);
        }
        const inspectionMode= selectedKPI?.inspection?.mode;
        const kpiId = kpiData.id;
        inspectionMode && toggleMenu(false);
        onUpdateKpi(kpiId, payload, 'trends');
        setDateFromLabel(kpiData.dateFrom);
        setDateToLabel(kpiData.dateTill);
        setKpiIdToUpdate(kpiId);
        getParticularTrendDataByKPIId(kpiData);
    };

    const getDateRange = (durationIndex, viewDateFrom, viewDateTill, args) => {
        const offset = args === 'next' ? 1000 : (-1000);
        let val, quant;
        switch (durationIndex) {
            case 1:
                [val, quant] = [4, "hours"];
                break;
            case 2:
                [val, quant] = [8, "hours"];
                break;
            case 3:
                [val, quant] = [1, "days"];
                break;
            case 4:
                [val, quant] = [1, "week"];
                break;
            case 5:
                [val, quant] = [1, "months"];
                break;
            case 6:
                [val, quant] = [3, "months"];
                break;
            case 7:
                [val, quant] = [6, "months"];
                break;
            default:
                [val, quant] = [2, "hours"];
                break;
        };
        let dateFrom, dateTill;
        if (args === 'next') {
            dateFrom = moment(viewDateFrom).add(val, quant).valueOf() - offset;
            dateTill = moment(viewDateTill).add(val, quant).valueOf() - offset;
        }
        else {
            dateFrom = moment(viewDateFrom).subtract(val, quant).valueOf() - offset;
            dateTill = moment(viewDateTill).subtract(val, quant).valueOf() - offset;
        }
        return { dateFrom, dateTill };
    };

    const showPreviousData = (id, cardType, viewDateFrom, viewDateTill, currentTime, durationIndex) => {
        const { dateFrom, dateTill } = getDateRange(durationIndex, dateFromLabel, dateToLabel, 'previous');
        getParticularTrendDataByKPIId({ id, cardType, dateFrom: dateFrom, dateTill: dateTill });
        setDateFromLabel(dateFrom);
        setDateToLabel(dateTill);
        toggleMenu(true, dateFrom, dateTill);
    };

    const showNextData = (id, cardType, viewDateFrom, viewDateTill, currentTime, durationIndex) => {
        const inspectionMode= selectedKPI?.inspection?.mode;
        const { dateFrom, dateTill } = getDateRange(durationIndex, dateFromLabel, dateToLabel, 'next');
        getParticularTrendDataByKPIId({ id, cardType, dateFrom: dateFrom, dateTill: dateTill });
        setDateFromLabel(dateFrom);
        setDateToLabel(dateTill);
        inspectionMode && toggleMenu(true, dateFrom, dateTill);
    };

    const onEdit = kpiId => {
        let cardToEdit = trends.find(item => item._id === kpiId);
        setKpiIdToUpdate(kpiId);
        setEditableCard(cardToEdit);
        setVisible(true);
        setEditMode(true)
    };

    const handleSwitch = (kpiId, value) => {
        const payload = {
            isVisibleToClient: value
        };
        setKpiIdToUpdate(kpiId);
        onUpdateKpi(kpiId, payload);
    };

    const onSelectScale = (data, manualScale, kpiId, cardDetails) => {
        let payload = {};
        if(cardDetails.cardType.cardType === 'Multiple X-Y Plot')   {
            const tempSeries = cardDetails.axis.series.map((item)=>{
                let x = {};
                let y = {};
                data.forEach((element)=>{
                    if(element.value === item.x.value)  {
                        x = {
                            ...item.x,
                            minScale: manualScale ? element.minScale: "",
                            maxScale: manualScale ? element.maxScale: ""
                        }
                    }
                    if(element.value === item.y.value)  {
                        y = {
                            ...item.y,
                            minScale: manualScale ? element.minScale: "",
                            maxScale: manualScale ? element.maxScale: ""
                        }
                    }
                });
                delete x['_id'];
                delete y['_id'];
                delete x['createdAt'];
                delete y['createdAt'];
                delete x['updatedAt'];
                delete y['updatedAt'];
                return  { seriesTitle: item?.seriesTitle,  x, y }
            });
            payload = {
                axis:   {
                    series: tempSeries,
                    x: [],
                    y: []
                },
                manualScale: manualScale
            };
        }
        else    {
            const yAxisPayload = data.map((item) => {
                let min = "", max = "";
                if (manualScale) { min = item.minScale; max = item.maxScale }
                return { minScale: min, maxScale: max, variableName: item.variableName, type: item.type, value: item.value }
            });
            payload = {
                axis: {
                    y: yAxisPayload
                },
                manualScale: manualScale
            }
        }
        setEditMode(true);
        setKpiIdToUpdate(kpiId);
        onUpdateKpi(kpiId, payload, 'trends');
    }

    const zoom = (id, viewDateFrom, viewDateTill, cardType, type) => {
      const dateFrom = moment(dateFromLabel).valueOf() + 1000;
      const dateTill = moment(dateToLabel).valueOf() + 1000;
      const numberOfHours = (dateTill - dateFrom) / 3600 / 1000;
      if (type === 'in') {
        viewDateFrom =
          moment(dateFromLabel)
            .add(numberOfHours / 2, 'hours')
            .valueOf() + 1000;
      } else if (type === 'out') {
        viewDateFrom =
          moment(dateFromLabel).subtract(numberOfHours, 'hours').valueOf() +
          1000;
      }
      viewDateTill = dateToLabel;
      const numberOfDays = (viewDateTill - viewDateFrom) / (24 * 3600) / 1000;
      if (numberOfDays > 14 && cardType === 'Multiple X-Y Plot') {
        viewDateFrom =
          moment(dateToLabel).subtract(14, 'days').valueOf() + 1000;
      }
      setDateFromLabel(viewDateFrom);
      setDateToLabel(viewDateTill);
      getParticularTrendDataByKPIId({
        id,
        cardType,
        dateFrom: viewDateFrom,
        dateTill: viewDateTill,
      });
    };

    const renderEditCardModal = () => {
        if (visible) {
            return (
                <AddNewCard
                    visible={visible}
                    handleCancel={() => setVisible(false)}
                    info={info}
                    activeKey={"6"}
                    mode={"edit"}
                    cardData={editableCard}
                />
            )
        }
    }

    const timeSelect = (startTime, endTime, id, cardType) => {
      if (startTime && endTime && id) {
        let dateFrom = startTime._d.valueOf();
        const dateTill = endTime._d.valueOf();
        const type = 'trends';
        let numberOfDays = (dateTill - dateFrom) / (24 * 3600) / 1000;
        if (numberOfDays > 14) {
          dateFrom = endTime.subtract(14, 'days').valueOf();
        }
        getParticularTrendDataByKPIId({
          id,
          type,
          dateFrom:
            cardType === 'Multiple X-Y Plot'
              ? dateFrom
              : startTime._d.valueOf(),
          dateTill: dateTill,
        });
        if(cardType === 'Multiple X-Y Plot' && numberOfDays > 14)  {
          setNumberOfDays(14); 
          setDateFromLabel(dateFrom);
        } else  {
          setNumberOfDays(numberOfDays);
          setDateFromLabel(startTime._d.valueOf())
        }
        setDateToLabel(dateTill);
        if (globalTimeRange) {
          setGlobalTimeRange(false);
          const trendsIds = trends.map((i) => {
            return i._id;
          });
          const payload = {
            kpiId: trendsIds,
            inspection: {
              mode: true,
              viewDateFrom: startTime._d.valueOf().toString(),
              viewDateTill: dateTill.toString(),
            },
          };
          bulkUpdateKpi(payload, dateFrom);
          const messageToDisplay = 'Inspection Mode turned ON.';
          message.info(messageToDisplay);
        } else {
          dateFrom = cardType === 'Multiple X-Y Plot' ? dateFrom : startTime._d.valueOf();
          toggleMenu(true, dateFrom, dateTill);
        }
      }
    };

    const toggleXAxis = (value) =>   {
        setXAxisVisibility(value);
    }

    const toggleYAxis = (value) =>   {
        setYAxisVisibility(value);
    }

    const toggleMenu = (e, dateFrom = null, dateTo = null) => {
        const inspectionMode= selectedKPI?.inspection?.mode;
        const messageToDisplay = e ? 'Inspection Mode turned ON.' : 'Auto-refresh is now suspended.';
        const messageDisplay = e ? !inspectionMode ? true : false : true;
        const kpiId = selectedKPI['_id'];
        if(messageDisplay)   { message.info(messageToDisplay)  }
        const payload = {
            inspection: {
                mode: e,
                viewDateFrom: !_.isNull(dateFrom) ? dateFrom.toString() : dateFromLabel.toString(),
                viewDateTill: !_.isNull(dateTo) ? dateTo.toString() : dateToLabel.toString()
            }      
        }
        setEditMode(true);
        setInspectionModeOp(true);
        setKpiIdToUpdate(kpiId);
        onUpdateKpi(kpiId, payload, 'trends');
    };

    const transformSeries = (series) => {
        series.forEach((piTag)=>{
            if(metaData)    {
                piTag.data = piTag.metaMaxMinPercentileData;
            }   
            else    {
                piTag.data = piTag.metaMaxMinData;
            }

        });
        return series;
    }

    const toggleMetaData = (value) =>   {
        setMetaData(value);
    }

    const fetchData = (id, dateFrom, dateTill)  =>  {
        if(!zoomIn) {
            setZoomOutDateFromLabel(dateFromLabel);
            setZoomOutDateToLabel(dateToLabel);
            setZoomIn(true);
        }
        setDateFromLabel(dateFrom);
        setDateToLabel(dateTill);
        const cardType = 'trends';
        getParticularTrendDataByKPIId({
          id,
          cardType,
          dateFrom: dateFrom,
          dateTill: dateTill,
        });
    }

    const resetZoom = (id) => {
        setZoomIn(false);
        const cardType = 'trends';
        setDateFromLabel(zoomOutDateFromLabel);
        setDateToLabel(zoomOutDateToLabel);
        getParticularTrendDataByKPIId({id, cardType, dateFrom: zoomOutDateFromLabel, dateTill: zoomOutDateToLabel});
    }

    const onSearch = (value) => {
        if (info && info.dataRef) {
          let searchValue = "";
          for(let i = 0; i<value.length; i++) {
            if(value[i] >= "0" && value[i] <= "9")  {
              searchValue+=value[i];
            }
            else if (value[i] >= 'a' && value[i] <= 'z') {
              searchValue += value[i];
            }
            else if (value[i] >= 'A' && value[i] <= 'Z') {
              searchValue += value[i];
            }
            else  {
              searchValue+='\\'
              searchValue+=value[i];
            }
          }
          const payload = {
            keyword: searchValue,
          };
          searchKPI(info.type,info.dataRef._id
              ? info.dataRef._id
              : info.dataRef.info._id, payload);
        }
    } 
    
    const change = (value) => {
      setSearchKeyword(value);
    };

    const renderTrendsCard = () => {
        if (selectedKPI && trends && trends.length > 0) {
            let graph = cardTypeConfigMapper[selectedKPI.cardType.cardType];
            const kpiId = selectedKPI['_id'];
            const KPITitle = selectedKPI.cardTitle ? selectedKPI.cardTitle : 'UNTITLED';
            const numberOfDays = (((trendsData[kpiId]?.viewDateTill - trendsData[kpiId]?.viewDateFrom) / (24 * 3600)) / 1000);
            let series = null;
            if(trendsData && trendsData[kpiId] && trendsData[kpiId].data)  {
                series = _.cloneDeep(trendsData[kpiId]?.data);
            }
            if (
              numberOfDays >= 3 && numberOfDays < 45 &&
              selectedKPI?.cardType.cardType === 'Time series plot'
            ) {
              series = transformSeries(series);
            }
            return kpiId && graph && graph.viewCard ? (
              <React.Fragment>
                <graph.viewCard
                  id={kpiId}
                  key={kpiId}
                  title={KPITitle}
                  time={plantTime * 1000}
                  delete={onDelete}
                  edit={onEdit}
                  item={selectedKPI}
                  cardType={selectedKPI.cardType.cardType}
                  changeVisibleStatus={(value) => {
                    handleSwitch(kpiId, value);
                  }}
                  isLoader={true}
                  onTimeChange={onTimeChange}
                  isChangetime={true}
                  updatedAt={trendsData[kpiId] ? trendsData[kpiId].data : ''}
                  height={'fit-content'}
                  cardData={trendsData[kpiId]}
                  activeKey={activeKey}
                  showPreviousData={showPreviousData}
                  showNextData={showNextData}
                  selectScale={onSelectScale}
                  cardDetails={selectedKPI}
                  userInfo={userRole}
                  duration={selectedKPI['timeDuration'] || 0}
                  zoom={zoom}
                  facilityId={facilityId}
                  timeSelect={timeSelect}
                  batchTime={batchTime}
                  trends2={true}
                  pickCustom={true}
                  isDatePickerVisible={isDatePickerVisible}
                  setIsDatePickerVisible={setIsDatePickerVisible}
                  getBatchTime={getBatchTime}
                  toggleXAxis={toggleXAxis}
                  toggleYAxis={toggleYAxis}
                  isXAxisVisible={isXAxisVisible}
                  isYAxisVisible={isYAxisVisible}
                  zoomIn={zoomIn}
                  resetZoom={resetZoom}
                >
                  {graph.viewGraph ? (
                    <graph.viewGraph
                      id={kpiId}
                      series={_.cloneDeep(series)}
                      viewDateFrom={
                        !trendsData[kpiId]?.xyPlot
                          ? _.cloneDeep(trendsData[kpiId]?.viewDateFrom)
                          : undefined
                      }
                      viewDateTill={
                        !trendsData[kpiId]?.xyPlot
                          ? _.cloneDeep(trendsData[kpiId]?.viewDateTill)
                          : undefined
                      }
                      cardDetails={selectedKPI}
                      trends2={trendsData[kpiId]?.xyPlot ? false : true}
                      numberOfDays={numberOfDays}
                      xyPlot={trendsData[kpiId]?.xyPlot}
                      isXAxisVisible={isXAxisVisible}
                      isYAxisVisible={isYAxisVisible}
                      fetchData={fetchData}
                      metaData={
                        numberOfDays >= 3 &&
                        numberOfDays < 45 &&
                        selectedKPI?.cardType.cardType === 'Time series plot'
                          ? true
                          : false
                      }
                      compressedData={
                        numberOfDays > 45 &&
                        selectedKPI?.cardType.cardType === 'Time series plot'
                        ? true
                        : false
                      }
                    />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 50,
                      }}
                      description={
                        <span>
                          <b>ADD CARDS HERE</b>
                        </span>
                      }
                    ></Empty>
                  )}
                </graph.viewCard>
              </React.Fragment>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  height: 50,
                }}
                description={
                  <span>
                    <b>ADD CARDS HERE</b>
                  </span>
                }
              ></Empty>
            );

        }
    }

    return (
      <div className="Trends">
        <Layout className="Trends">
          <Sider
            style={{
              overflow: 'auto',
              height: 'auto',
              left: 0,
              background: 'white',
              maxHeight: '100vh',
            }}
            className="menuStyle trends-container trend-sider"
            width="25%"
          >
            <Search
              placeholder="Search Here"
              onSearch={(value) => {
                onSearch(value);
              }}
              onChange={(e) => {
                change(e.target.value);
              }}
              value={searchKeyword}
              allowClear={true}
              style={{ padding: '5px' }}
            />
            <Menu mode="vertical" selectedKeys={selectedKey}>
              {trends?.length ? (
                trends.map((kpi, index) => {
                  let num;
                  const title = kpi.cardTitle ? kpi.cardTitle : 'UNTITLED';
                  if (index % 2 === 0) {
                    num = 'menu-color0';
                  } else {
                    num = 'menu-color1';
                  }
                  return (
                    <Menu.Item
                      key={index}
                      icon={<UserOutlined />}
                      className={num}
                      onClick={() => {
                        setSelectedKPI(kpi);
                        setSelectedKey([index.toString()]);
                        setDateFromLabel(dateFromLabel);
                        setDateToLabel(dateToLabel);
                      }}
                    >
                      <div className="runs-menu">
                        <Row>
                          <Col span={10} style={{ width: '100%' }}>
                            <div className="text">{title}</div>
                          </Col>
                        </Row>
                      </div>
                    </Menu.Item>
                  );
                })
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 50,
                  }}
                  description={
                    <span>
                      <b>ADD CARDS HERE</b>
                    </span>
                  }
                ></Empty>
              )}
            </Menu>
          </Sider>
          <Layout className="trends-container">
            <Header
              className="trends-header"
              style={{ marginTop: '5px', background: '#fff' }}
            >
              {trends?.length > 0 && (
                <div style={{ cursor: 'pointer' }}>
                  <span
                    className="calendar-trends"
                    onClick={() => setIsDatePickerVisible(true)}
                  >
                    <CalendarOutlined style={{ marginRight: '10px' }} />
                    {dateFromLabel && dateToLabel
                      ? getTimeInFormat(dateFromLabel) +
                        ' - ' +
                        getTimeInFormat(dateToLabel)
                      : null}
                  </span>
                </div>
              )}
              {trends?.length > 0 && (
                <div className="inspection-trends">
                  <span style={{ marginLeft: '0.5%' }}>
                    <b>{'INSPECTION MODE: '}</b>
                    <Switch
                      checked={selectedKPI?.inspection?.mode}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onClick={(value) => {
                        toggleMenu(value);
                      }}
                    ></Switch>
                  </span>
                  {numberOfDays >= 3 && numberOfDays < 45 &&
                  selectedKPI?.cardType?.cardType === 'Time series plot' ? (
                    <span style={{ marginLeft: '0.5%' }}>
                      <Switch
                        checked={metaData}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onClick={(value) => {
                          toggleMetaData(value);
                        }}
                      ></Switch>
                      <b>
                        <PercentageOutlined />
                      </b>
                    </span>
                  ) : null}
                </div>
              )}
            </Header>
            <Content style={{ padding: '50px' }}>{renderTrendsCard()}</Content>
          </Layout>

          {renderEditCardModal()}
          {Tour()}
        </Layout>
      </div>
    );
}

const mapStateToProps = (state) => {
    return {
      userRole: state.LoginReducer.apiState.userRole,
      trends: state.trendsReducer.ids?.data,
      trendsData: state.trendsReducer.kpisDataState,
      TrendsReducerAddNewCardStatus: state.trendsReducer.addNewCardSuccess,
      TrendsReducerIsSuccessEdit: state.trendsReducer.isSuccessEdit,
      TrendsReducerStateIsSuccess: state.trendsReducer.isSuccess,
      trendsAddNewCardError: state.trendsReducer.addNewCardError,
      trendsAddNewCardData: state.trendsReducer.addNewCardData,
      plantTimeReducerData: state.plantTimeReducer.data,
      plantTimeReducerIsSuccess: state.plantTimeReducer.isSuccess,
      isUpdateKPISuccess: state.UpdateKpiByIdReducer.isSuccess,
      isUpdateKPIError: state.UpdateKpiByIdReducer.isError,
      updateKPIErrorMessage: state.UpdateKpiByIdReducer.message,
      isDeleteKpiReducerSuccess: state.DeleteKpiByIdReducer.isSuccess,
      isDeleteKpiReducerError: state.DeleteKpiByIdReducer.isError,
      deleteKpiId: state.DeleteKpiByIdReducer.kpiId,
      deleteKpiReducerMessage: state.DeleteKpiByIdReducer.message,
      batchTimeIsSuccess: state.batchTimeReducer.isSuccess,
      batchTimeData: state.batchTimeReducer.data,
      batchTimeIsError: state.batchTimeReducer.isError,
      TrendsDataProcessed: state.trendsReducer.dataProcessed,
      trendsError: state.trendsReducer.isError,
      trendsErrorMessage: state.trendsReducer.errorMsg,
      globalInspectionMode: state.trendsReducer.inspectionMode,
    };
};

const mapDispatchToProps = {
  getTrendsByLevelId,
  getTrendByKPIId,
  deleteKpiById,
  clearDeleteKpiState,
  onUpdateKpi,
  getParticularTrendDataByKPIId,
  clearAddNewCardState,
  getPlantTime,
  clearUpdataKpiState,
  getTrendsById,
  clearTrendsState,
  getBatchTime,
  clearBatchState,
  clearPlantState,
  bulkUpdateKpi,
  searchKPI,
  setInspectionModeOff,
};

Trends2.propTypes = {
  info: PropType.object,
  getTrendsByLevelId: PropType.func,
  userRole: PropType.string,
  activeKey: PropType.string,
  trends: PropType.array,
  deleteKpiById: PropType.func,
  onUpdateKpi: PropType.func,
  getParticularTrendDataByKPIId: PropType.func,
  getPlantTime: PropType.func,
  getTrendByKPIId: PropType.func,
  clearDeleteKpiState: PropType.func,
  getTrendsById: PropType.func,
  clearUpdataKpiState: PropType.func,
  clearTrendsState: PropType.func,
  plantTimeReducerData: PropType.object,
  TrendsReducerAddNewCardStatus: PropType.bool,
  TrendsReducerIsSuccessEdit: PropType.bool,
  plantTimeReducerIsSuccess: PropType.bool,
  TrendsReducerStateIsSuccess: PropType.bool,
  getBatchTime: PropType.func,
  clearBatchState: PropType.func,
  clearPlantState: PropType.func,
  bulkUpdateKpi: PropType.func,
  setInspectionModeOff: PropType.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Trends2);
