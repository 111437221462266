import GraphCard from "../../../common/Cards/GraphCard/GraphCard";
import TableCard from "../../../common/Cards/TableCard";
import TableView from "../../../common/Cards/TableCard/TableView";
import VariableCard from "../../../common/Cards/VariableCard";
import VariableCardDetails from "../../../common/Cards/VariableCard/VariableCardDetails";
import BarChart from "../../../common/Graphs/BarChart/BarChart";
// import MixedGraph from "../../../common/Graphs/MixedGraph/MixedGraph";
import MultiTimeVarientLineGraph from "../../../common/Graphs/MultiTimeVariantLineGraph";
import PieChart from "../../../common/Graphs/PieChart/PieChart";
// import PointGraph from "../../../common/Graphs/PointGraph/PointGraph";
import TornadoGraph from "../../../common/Graphs/TornadoGraph/TornadoGraph";
import cardTypeMapper from "../../../constants/cardTypes";
import { addNewCardMessages } from "../../../constants/messages";
import { Name } from "../../../constants/pattern";
import StdDeviationCardDetails from "../../../common/Cards/VariableCard/StdDeviationCardDetails";
import { runStatsConstant } from "../DashboardInfo/Runs/DetailsConstants";

export const cardTypeConfigMapper = {
   [cardTypeMapper["Time series plot"]]: {
      viewScreen: "plots",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["X-Y Plot"]]: {
      viewScreen: "plots",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["X-Y Plot with baseline curve"]]: {
      viewScreen: "plots",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["Alert by status bar chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: BarChart,
   },
   [cardTypeMapper["Alert by priority bar chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: BarChart,
   },
   [cardTypeMapper["Alert by status pie chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: PieChart,
   },
   [cardTypeMapper["Alert by priority pie chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: PieChart,
   },
   [cardTypeMapper["Alert status Analytics tornado graph"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: TornadoGraph,
   },
   [cardTypeMapper["Alert priority Analytics tornado graph"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: TornadoGraph,
   },
   [cardTypeMapper["Operating Statistics"]]: {
      viewScreen: "overview",
      viewCard: null,
      viewGraph: null,
   },
   [cardTypeMapper["Runtime / Reliability Analytics plot"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: null,
   },
   [cardTypeMapper["Active alert summary card"]]: {
      viewScreen: "overview",
      viewCard: TableCard,
      viewGraph: TableView,
   },
   [cardTypeMapper["Alert by type pie chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: null,
   },
   [cardTypeMapper["Aggregate Values"]]: {
      viewScreen: "overview",
      viewCard: null,
      viewGraph: null,
   },
   [cardTypeMapper["Variable card"]]: {
      viewScreen: "overview",
      viewCard: VariableCard,
      viewGraph: VariableCardDetails,
   },
   [cardTypeMapper["Runtime / Reliability analytics card"]]: {
      viewScreen: "overview",
      viewCard: null,
      viewGraph: null,
   },
   [cardTypeMapper["Run status Card"]]: {
      viewScreen: "overview",
      viewCard: TableCard,
      viewGraph: TableView,
   },
   [cardTypeMapper["Equipment Run Plot"]]: {
      viewScreen: "plots",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["Failure cause pie chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: PieChart,
   },
   [cardTypeMapper["Failure bar chart"]]: {
      viewScreen: "overview",
      viewCard: GraphCard,
      viewGraph: TornadoGraph,
      viewGraphType: BarChart,
   },
   [cardTypeMapper["trends"]]: {
      viewScreen: "Trends",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["Standard deviation card"]]: {
      viewScreen: "overview",
      viewCard: VariableCard,
      viewGraph: StdDeviationCardDetails,
   },
   [cardTypeMapper["Run Statistic"]]: {
      viewScreen: "overview",
      viewCard: VariableCard,
      viewGraph: VariableCardDetails,
   },
   [cardTypeMapper["Multiple X-Y Plot"]]: {
      viewScreen: "Trends",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["Alert Plot"]]: {
      viewScreen: "Alert",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   },
   [cardTypeMapper["Transient Analysis Plot"]]: {
      viewScreen: "Transient Analysis",
      viewCard: GraphCard,
      viewGraph: MultiTimeVarientLineGraph,
   }
};

const getScrewCurves = (cardType) => {
   let curveData = []
   switch (cardType) {
      case "Adiabatic Efficiency":
         curveData = [
            { name: 'Slide Valve Position', type: 'slideValvePosition' },
            { name: 'Adiabatic Efficiency', type: 'adiabaticEfficiency' },
         ]
         return baselineVariables['screwCompressor'] = curveData
      case "Capacity Control":
         curveData = [
            { name: 'Slide Valve Position Capacity Control', type: 'dischargeSlideValvePosition' },
            { name: 'Volumetric Capacity Multiplier', type: 'volumetricCapacityMultiplier' },
         ]
         return baselineVariables['screwCompressor'] = curveData
      case "Volume Ratio":
         curveData = [
            { name: 'Slide Valve Position Volume Ratio', type: 'suctionSlideValvePosition' },
            { name: 'Volumetric Ratio Multiplyer', type: 'volumetricRatioMultiplier' },
         ]
         return baselineVariables['screwCompressor'] = curveData
      default:
         return baselineVariables['screwCompressor'] = curveData
   }
}

const baselineVariables = {
   motor: [
      { name: 'Load', type: 'load' },
      { name: 'Efficiency', type: 'efficiency' },
      { name: 'Power Factor', type: 'powerFactor' },
      { name: 'Current', type: 'current' },
      { name: 'Slip', type: 'slip' },
   ],
   syncMotor: [
      { name: 'Load', type: 'load' },
      { name: 'Efficiency', type: 'efficiency' },
      { name: 'Power Factor', type: 'powerFactor' },
      { name: 'Current', type: 'current' },
   ],
   recipCompressor: [
      { name: 'Volume Reduction', type: 'volumeReduction' },
      { name: 'Adiabatic Efficiency', type: 'adiabaticEfficiency' },
      { name: 'Inlet Volume', type: 'inletVolume' }
   ],
   screwCompressor: [],
   hydraulicVariableSpeedGearX:[
      {name: 'Output Speed', type: 'outputSpeed'},
      {name: 'Speed Ratio', type: 'speedRatio'},
      {name: 'Wheel Speed', type: 'wheelSpeed'},
      {name: 'Intermediate Speed', type: 'intermediateSpeed'},
   ],
   hydraulicVariableSpeedGearY:[
      {name: 'Output Torque', type: 'outputTorque'},
      {name: 'Output Power', type: 'outputPower'},

   ],
   radialInflowGasExpanderX:[
      {name: 'Mass Flow', type: 'mass_flow'},
      
   ],
   radialInflowGasExpanderY:[
      { name: 'Efficiency', type: 'efficiency' },
      {name: 'Power', type: 'power'},
   ],
   other: [
      { name: 'Efficiency', type: 'efficiency' },
      { name: 'Flow Coefficient', type: 'flowCoefficient' },
      { name: 'Head Coefficient', type: 'headCoefficient' },
      { name: 'Head Drop', type: 'headDrop' },
      { name: 'Volumetric Flow', type: 'volumetricFlow' },
      { name: 'Work Input', type: 'workInput' },
   ],
};

const variableMapper = {
   load: 'Load',
   efficiency: 'Efficiency',
   powerFactor: 'Power Factor',
   current: 'Current',
   slip: 'Slip',
   flowCoefficient: 'Flow Coefficient',
   headCoefficient: 'Head Coefficient',
   headDrop: 'Head Drop',
   volumetricFlow: 'Volumetric Flow',
   workInput: 'Work Input',
   volumeReduction: 'Volume Reduction',
   adiabaticEfficiency: 'Adiabatic Efficiency',
   inletVolume: 'Inlet Volume',
};

const curveTypes = [
   { "name": "DA" },
   { "name": "SACE" },
   { "name": "SAHE" }
];

const ScrewCurveTypes = [
   { name: 'Adiabatic Efficiency' },
   { name: "Capacity Control" },
   { name: "Volume Ratio" },
];

const findBaslineVar = (componentList, componentId, cardData) => {
   let componentType = '',
      xBaselineValue = '',
      yBaselineValue = '';
   if (componentList !== []) {
      componentList.forEach((component) => {
         if (component._id === componentId) {
            if (component.type === 'Electric motors') {
               componentType = 'motor';
               xBaselineValue =
                  variableMapper[cardData.axis.xAxisBaselineTag] ||
                  variableMapper['load'];
               yBaselineValue =
                  variableMapper[cardData.axis.yAxisBaselineTag] ||
                  variableMapper['current'];
            } else if (component.type === 'Reciprocating Compressor') {
               componentType = 'recipCompressor';
               xBaselineValue = variableMapper[cardData.axis.xAxisBaselineTag];
               yBaselineValue = variableMapper[cardData.axis.yAxisBaselineTag];
            } else if (component.type === 'Synchronous motors') {
               componentType = 'syncMotor';
               xBaselineValue =
                  variableMapper[cardData.axis.xAxisBaselineTag] ||
                  variableMapper['load'];
               yBaselineValue =
                  variableMapper[cardData.axis.yAxisBaselineTag] ||
                  variableMapper['current'];
            } else if (component.type === 'Screw Compressor') {
               componentType = 'screwCompressor';
               xBaselineValue = cardData.axis.xAxisBaselineTag;
               yBaselineValue = cardData.axis.yAxisBaselineTag;
               baselineVariables['screwCompressor'].forEach(element => {
                  if (element.type === cardData.axis.xAxisBaselineTag) {
                     xBaselineValue = element.name
                  }
                  if (element.type === cardData.axis.yAxisBaselineTag) {
                     yBaselineValue = element.name
                  }
               })
            }
            else if (component.type === 'Hydraulic Variable Speed Gear') {
               componentType = 'hydraulicVariableSpeedGear';
               xBaselineValue = cardData.axis.xAxisBaselineTag;
               yBaselineValue = cardData.axis.yAxisBaselineTag;
               baselineVariables['hydraulicVariableSpeedGearX'].forEach(element => {
                  if (element.type === cardData.axis.xAxisBaselineTag) {
                     xBaselineValue = element.name;
                  }
               })
               baselineVariables['hydraulicVariableSpeedGearY'].forEach(element => {
                  if (element.type === cardData.axis.yAxisBaselineTag) {
                     yBaselineValue = element.name;
                  }
               })
            }
            else if (component.type === 'Radial Inflow Gas Expander') {
               componentType = 'radialInflowGasExpander';
               xBaselineValue = cardData.axis.xAxisBaselineTag;
               yBaselineValue = cardData.axis.yAxisBaselineTag;
               baselineVariables['radialInflowGasExpanderX'].forEach(element => {
                  if (element.type === cardData.axis.xAxisBaselineTag) {
                     xBaselineValue = element.name;
                  }
               })
               baselineVariables['radialInflowGasExpanderY'].forEach(element => {
                  if (element.type === cardData.axis.yAxisBaselineTag) {
                     yBaselineValue = element.name;
                  }
               })
            }
            else {
               componentType = 'other';
               xBaselineValue =
                  variableMapper[cardData.axis.xAxisBaselineTag] ||
                  variableMapper['headCoefficient'];
               yBaselineValue =
                  variableMapper[cardData.axis.yAxisBaselineTag] ||
                  variableMapper['flowCoefficient'];
            }
         }
      });
      return { x: xBaselineValue, y: yBaselineValue, type: componentType };
   }
};

const trendsCardTypes = [
   { name: "Trends", cardType: "trends" },
   { name: "Multiple X-Y Plot", cardType: "Multiple X-Y Plot" }
];

export const formFieldsTrendsValue = (config) => {
   let formArray = [
      {
         label: "Card Title",
         key: "cardTitle",
         max: 70,
         value: config.data.cardTitleValue,
         change: config.changeTrendsTitle,
         pattern: [Name.regx, Name.message],
      },
      {
         label: "Card Type",
         key: "cardType",
         type: config.mode === "edit" ? "text" : "dropdown",
         valueKey: "cardType",
         titleKey: "name",
         searchKey: "name",
         value: config.data.cardTypeName,
         disabled: config.mode === "edit" ? true : false,
         data: trendsCardTypes,
         change: config.onCardTypeChange,
         required: [true, addNewCardMessages.REQUIRED_FIELD],
      },
   ];
   return formArray;
};

export const trendsForm = (config) => {
   let formArray = [];
   if (config.cardType === 'Multiple X-Y Plot') {
      formArray.push(
         {
            label: 'Card Title',
            key: 'cardTitle',
            max: 70,
            value: config.data.cardTitleValue,
            change: config.changeTrendsTitle,
            pattern: [Name.regx, Name.message],
         },
         {
            label: 'Card Type',
            key: 'cardType',
            type: config.mode === 'edit' ? 'text' : 'dropdown',
            valueKey: 'cardType',
            titleKey: 'name',
            searchKey: 'name',
            value: config.data.trendsCardType,
            disabled: config.mode === 'edit' ? true : false,
            data: trendsCardTypes,
            change: config.onCardTypeChange,
            required: [true, addNewCardMessages.REQUIRED_FIELD],
         }
      );
      for (let i = 0; i < config.length; i++) {
         formArray.push(
            {
               label: 'Series Title',
               key: `seriesTitle${i}`,
               max: 70,
               change: config.changeSeriesTitle,
               value: config.data.seriesTitleList[i] || [],
               pattern: [Name.regx, Name.message],
            },
            {
               label: 'x-axis',
               key: `xAxis${i}`,
               type: 'dropdown',
               data: config.data ? config.data.tags : [],
               valueKey: '_id',
               titleKey: 'variableNameTag',
               searchKey: 'variableNameTag',
               change: config.onXAxisChange,
               value: config.data.xname[i] || [],
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               autoClearSearchValue: false,
            },
            {
               label: 'y-axis',
               key: `yAxis${i}`,
               type: 'dropdown',
               data: config.data ? config.data.tags : [],
               valueKey: '_id',
               titleKey: 'variableNameTag',
               searchKey: 'variableNameTag',
               change: config.onYAxisChange,
               value: config.data.yname[i] || [],
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               autoClearSearchValue: false,
            }
         );
         formArray.push();
      }
   }
   else if (config.mode !== "edit") {
      formArray.push(
         {
            label: "Card Title",
            key: "cardTitle",
            max: 70,
            value: config.data.cardTitleValue,
            change: config.changeTrendsTitle,
            pattern: [Name.regx, Name.message],
         },
         {
            label: "Card Type",
            key: "cardType",
            type: config.mode === "edit" ? "text" : "dropdown",
            valueKey: "cardType",
            titleKey: "name",
            searchKey: "name",
            value: config.data.cardTypeName,
            disabled: config.mode === "edit" ? true : false,
            data: trendsCardTypes,
            change: config.onCardTypeChange,
            required: [true, addNewCardMessages.REQUIRED_FIELD],
         },
         {
            label: "y-axis",
            key: "yAxis",
            type: "multiDropdown",
            data: config.data ? config.data.tags : [],
            valueKey: "_id",
            titleKey: "variableNameTag",
            searchKey: "variableNameTag",
            change: config.onYAxisChange,
            value: config.data.yname || [],
            required: [true, addNewCardMessages.REQUIRED_FIELD, "array"],
            validator: config.validateMultiSelection,
            autoClearSearchValue: false,
            // disabled: config.cardType === '' ? true : false
         });
   }
   else {
      formArray.push(
         {
            label: 'Card Title',
            key: 'cardTitle',
            max: 70,
            value: config.data.cardTitleValue,
            change: config.changeTrendsTitle,
            pattern: [Name.regx, Name.message],
         },
         {
            label: 'y-axis',
            key: 'yAxis',
            type: 'multiDropdown',
            data: config.data ? config.data.tags : [],
            valueKey: '_id',
            titleKey: 'variableNameTag',
            searchKey: 'variableNameTag',
            change: config.onYAxisChange,
            value: config.data.yname || [],
            required: [true, addNewCardMessages.REQUIRED_FIELD, 'array'],
            validator: config.validateMultiSelection,
            autoClearSearchValue: false,
            // disabled: config.cardType === '' ? true : false
         }
      );
   }
   return formArray;

}

export const formFieldsValueData = (config) => {
   return [
      {
         label: "Card Title",
         key: "cardTitle",
         max: 70,
         pattern: [Name.regx, Name.message],
      },
      {
         label: "Visible for Client",
         key: "isVisibleToClient",
         type: "checkbox",
         valuePropName: "checked",
      },
      {
         label: "Card Type",
         key: "cardType",
         type: config.mode === "edit" ? "text" : "dropdown",
         valueKey: "_id",
         titleKey: "name",
         searchKey: "name",
         value: config.data.cardTypeName,
         disabled: config.mode === "edit" ? true : false,
         data: config.data && config.data.cardTypes,
         change: config.onCardTypeChange,
         required: [true, addNewCardMessages.REQUIRED_FIELD],
      },
   ];
};

export const getCardTypeForm = (cardType, config) => {
   switch (cardType) {
      case cardTypeMapper["X-Y Plot"]:
         return [
            {
               label: "x-axis",
               key: "xAxis",
               type: "dropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onXAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               disabled: config.cardTypeId ? false : true,
               style: { minWidth: "100%" }
            },
            {
               label: "y-axis",
               key: "yAxis",
               type: "dropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               disabled: config.cardTypeId ? false : true,
               style: { minWidth: "100%" }
            },
         ];
      case cardTypeMapper["X-Y Plot with baseline curve"]:
         const formArray = [
            {
               label: 'Equipment',
               key: 'equipment',
               type: 'dropdown',
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: '_id',
               titleKey: 'name',
               searchKey: 'name',
               change: config.onDeployedEquipmentChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: 'Component',
               key: 'component',
               type: 'dropdown',
               data: config.data ? config.data.deployedComponentList : [],
               valueKey: '_id',
               titleKey: 'name',
               searchKey: 'name',
               change: config.onDeployedComponentChange,
               disabled: config.deployedEquipmentId ? false : true,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: 'x-axis',
               key: 'xAxis',
               type: 'dropdown',
               data: config.data ? config.data.tags : [],
               valueKey: '_id',
               titleKey: 'variableNameTag',
               searchKey: 'variableNameTag',
               change: config.onXAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               disabled: config.deployedComponentId ? false : true,
               style: { minWidth: "100%" }
            },
            {
               label: 'y-axis',
               key: 'yAxis',
               type: 'dropdown',
               data: config.data ? config.data.tags : [],
               valueKey: '_id',
               titleKey: 'variableNameTag',
               searchKey: 'variableNameTag',
               change: config.onYAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               disabled: config.deployedComponentId ? false : true,
               style: { minWidth: "100%" }
            },
         ];
         let baselineXDropdownData = baselineVariables[config.componentType]
         let baselineYDropdownData = baselineVariables[config.componentType]

         if(config.componentType === 'hydraulicVariableSpeedGear' || config.componentType === 'radialInflowGasExpander'){
            baselineXDropdownData = baselineVariables[config.componentType + "X"]
            baselineYDropdownData = baselineVariables[config.componentType+ "Y"]
         }
         
         formArray.push(
            {
              label: "x-axis (Baseline)",
              key: "xAxisBaseline",
              type: "dropdown",
              data: baselineXDropdownData,
              valueKey: "type",
              titleKey: "name",
              searchKey: "name",
              change: config.onBaselineXAxisChange,
              required: [true, addNewCardMessages.REQUIRED_FIELD],
              disabled: config.deployedComponentId ? false : true,
            },
            {
              label: "y-axis (Baseline)",
              key: "yAxisBaseline",
              type: "dropdown",
              data: baselineYDropdownData,
              valueKey: "type",
              titleKey: "name",
              searchKey: "name",
              change: config.onBaselineYAxisChange,
              required: [true, addNewCardMessages.REQUIRED_FIELD],
              disabled: config.deployedComponentId ? false : true,
            }
         );
         if (config.componentType === 'recipCompressor') {
            formArray.push({
               label: 'Curve Type',
               key: 'curveType',
               type: 'multiDropdown',
               data: curveTypes,
               valueKey: 'name',
               titleKey: 'name',
               searchKey: 'name',
               change: config.changeCurveTypes,
               value: config.data.yname || [],
               required: [true, addNewCardMessages.REQUIRED_FIELD, 'array'],
               autoClearSearchValue: false,
               disabled: config.componentType === 'recipCompressor' ? false : true,
            });
         }
         else if (config.componentType === 'screwCompressor') {
            formArray.splice(2, 0, {
               label: 'Curve Type',
               key: 'curveType',
               type: 'dropdown',
               data: ScrewCurveTypes,
               valueKey: 'name',
               titleKey: 'name',
               searchKey: 'name',
               change: (value) => { config.changeScrewCurveType(value); getScrewCurves(value) },
               value: config.data.yname || [],
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               autoClearSearchValue: false,
               disabled: config.componentType === 'screwCompressor' ? false : true,
            })
         }
         return formArray;
      case cardTypeMapper["Time series plot"]:
         return [
            {
               label: "y-axis",
               key: "yAxis",
               type: "multiDropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD, "array"],
               validator: config.validateMultiSelection,
               autoClearSearchValue: false,
               style: { minWidth: "100%" },
            },
         ];
      case cardTypeMapper["Variable card"]:
      case cardTypeMapper["Standard deviation card"]:
         let options = [
            {
               label: "Card Value",
               key: "cardValue",
               type: "dropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
         ];
         if (cardType === cardTypeMapper["Variable card"]) {
            options.push({
               label: "Panel Type",
               key: "panelTypeValue",
               type: "dropdownWithAdd",
               data: config.data ? config.data.panelTypes : [],
               valueKey: "name",
               titleKey: "name",
               searchKey: "name",
               value: config.data ? config.data.selectedPanel : "",
               change: config.onPanelTypeChange,
               addMessage: "Add Panel Type",
               openAddModal: config.openAddModal,
            });
         }
         return options;
      case cardTypeMapper["Alert by status bar chart"]:
      case cardTypeMapper["Alert by priority bar chart"]:
      case cardTypeMapper["Alert by status pie chart"]:
      case cardTypeMapper["Alert by priority pie chart"]:
         return [
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               change: config.onDeployedEquipmentChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Show Client's Data",
               key: "toViewClientData",
               type: "checkbox",
               valuePropName: "checked",
               isVisible: true,
            },
         ];
      case cardTypeMapper["Alert status Analytics tornado graph"]:
      case cardTypeMapper["Alert priority Analytics tornado graph"]:
         return [
            {
               label: "Show Client's Data",
               key: "toViewClientData",
               type: "checkbox",
               valuePropName: "checked",
               isVisible: true,
            },
         ];
      case cardTypeMapper["Equipment Run Plot"]:
         return [
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               change: config.onDeployedEquipmentChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "y-axis",
               key: "yAxis",
               type: "multiDropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD, "array"],
               validator: config.validateMultiSelection,
               autoClearSearchValue: false,
            },
         ];
      case cardTypeMapper["Failure cause pie chart"]:
         return [
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               change: config.onDeployedEquipmentChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
         ];
      case cardTypeMapper["Run Statistic"]:
         return [
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               change: config.onDeployedEquipmentChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Card Value",
               key: "Tag",
               type: "dropdown",
               data: runStatsConstant,
               valueKey: "_id",
               titleKey: "Tag",
               searchKey: "Tag",
               change: config.onXAxisChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               disabled: config.cardTypeId ? false : true,
            },
         ];
      case cardTypeMapper["Failure bar chart"]:
         return config.levelName === "equipment"
            ? [
               {
                  label: "Equipment",
                  key: "equipment",
                  type: "dropdown",
                  data: config.data ? config.data.deployedEquipmentList : [],
                  valueKey: "_id",
                  titleKey: "name",
                  searchKey: "name",
                  change: config.onDeployedEquipmentChange,
                  required: [true, addNewCardMessages.REQUIRED_FIELD],
               },
            ]
            : [];
      default:
         return [];
   }
};

export const editCard = (cardType, config) => {
   switch (cardType) {
      case cardTypeMapper["X-Y Plot"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "x-axis",
               key: "xAxis",
               type: "dropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onXAxisChange,
               value: config.data.xname,
               disabled: config.cardTypeId ? false : true,
               style: { minWidth: "100%" }
            },
            {
               label: "y-axis",
               key: "yAxis",
               type: "dropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               value: config.data.yname,
               disabled: config.cardTypeId ? false : true,
               style: { minWidth: "100%" },
            },
         ];
      case cardTypeMapper["X-Y Plot with baseline curve"]:
         const baselineVar = findBaslineVar(
            config.data.deployedComponentList,
            config.data.deployedComponentId,
            config.data.cardData
         );
         if (config.data.cardData.axis &&
            config.data.cardData.axis.curveType[0] &&
            config.data.cardData.axis.curveType[0].name) {
            const curveName = config.data.cardData.axis.curveType[0].name
            getScrewCurves(curveName)
         }
         const formArray = [
            {
               label: 'Card Title',
               key: 'cardTitle',
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: 'Card Type',
               key: 'cardType',
               valueKey: '_id',
               titleKey: 'name',
               searchKey: 'name',
               value: config.data.cardTypeName,
               disabled: config.mode === 'edit' ? true : false,
               data: config.data && config.data.cardTypes,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: 'Equipment',
               key: 'equipment',
               type: 'dropdown',
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: '_id',
               titleKey: 'name',
               searchKey: 'name',
               value: config.data.deployedEquipmentValue,
               change: config.onDeployedEquipmentChange,
               disabled: config.mode === 'edit' ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: 'Component',
               key: 'component',
               type: 'dropdown',
               data: config.data ? config.data.deployedComponentList : [],
               valueKey: '_id',
               titleKey: 'name',
               searchKey: 'name',
               value: config.data.deployedComponentValue,
               change: config.onDeployedComponentChange,
               disabled: config.mode === 'edit' ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: 'x-axis',
               key: 'xAxis',
               type: 'dropdown',
               data: config.data ? config.data.tags : [],
               valueKey: '_id',
               titleKey: 'variableNameTag',
               searchKey: 'variableNameTag',
               change: config.onXAxisChange,
               value: config.data.xname,
               disabled: config.deployedComponentId ? false : true,
               style: { minWidth: "100%" }
            },
            {
               label: 'y-axis',
               key: 'yAxis',
               type: 'dropdown',
               data: config.data ? config.data.tags : [],
               valueKey: '_id',
               titleKey: 'variableNameTag',
               searchKey: 'variableNameTag',
               change: config.onYAxisChange,
               value: config.data.yname,
               disabled: config.deployedComponentId ? false : true,
               style: { minWidth: "100%" }
            }
         ];

         let baselineXDropdownData = baselineVariables[baselineVar.type]
         let baselineYDropdownData = baselineVariables[baselineVar.type]

         if(baselineVar.type === 'hydraulicVariableSpeedGear' || baselineVar.type === 'radialInflowGasExpander'){
            baselineXDropdownData = baselineVariables[baselineVar.type + "X"]
            baselineYDropdownData = baselineVariables[baselineVar.type + "Y"]
         }

         formArray.push({
            label: 'x-axis (Baseline)',
            key: 'xAxisBaseline',
            type: 'dropdown',
            data: baselineXDropdownData,
            valueKey: 'type',
            titleKey: 'name',
            searchKey: 'name',
            value: baselineVar.x,
            change: config.onBaselineXAxisChange,
            required: [true, addNewCardMessages.REQUIRED_FIELD],
            disabled: config.deployedComponentId ? false : true,
         },
         {
            label: 'y-axis (Baseline)',
            key: 'yAxisBaseline',
            type: 'dropdown',
            data: baselineYDropdownData,
            valueKey: 'type',
            titleKey: 'name',
            searchKey: 'name',
            value: baselineVar.y,
            change: config.onBaselineYAxisChange,
            required: [true, addNewCardMessages.REQUIRED_FIELD],
            disabled: config.deployedComponentId ? false : true,
         }
         )
         

         if (baselineVar.type === 'screwCompressor') {
            config.curveType = config.curveType[0] ? config.curveType[0] : ""
            formArray.splice(4, 0, {
               label: 'Curve Type',
               key: 'curveType',
               type: 'dropdown',
               data: ScrewCurveTypes,
               valueKey: 'name',
               titleKey: 'name',
               searchKey: 'type',
               change: (value) => { config.changeScrewCurveType(value) },
               value: config.curveType,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               autoClearSearchValue: false,
               disabled: true,
            })
         }
         else if (config.curveType.length > 0 && baselineVar.type !== 'screwCompressor') {
            formArray.push({
               label: 'Curve Type',
               key: 'curveType',
               type: 'multiDropdown',
               data: curveTypes,
               valueKey: 'name',
               titleKey: 'name',
               searchKey: 'name',
               change: config.changeCurveTypes,
               value: config.curveType || [],
               required: [true, addNewCardMessages.REQUIRED_FIELD, 'array'],
               validator: config.validateMultiSelection,
               autoClearSearchValue: false,
            });
         }

         return formArray;
      case cardTypeMapper["Time series plot"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "y-axis",
               key: "yAxis",
               type: "multiDropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               value: config.data.yname,
               required: [true, addNewCardMessages.REQUIRED_FIELD, "array"],
               validator: config.validateMultiSelection,
               autoClearSearchValue: false,
               style: { minWidth: "100%" },
            },
         ];
      case cardTypeMapper["Variable card"]:
      case cardTypeMapper["Standard deviation card"]:
         let options = [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Card Value",
               key: "cardValue",
               type: "dropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               value: config.data.variableTagValue,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
         ];
         if (cardType === cardTypeMapper["Variable card"]) {
            options.push({
               label: "Panel Type",
               key: "panelTypeValue",
               type: "dropdownWithAdd",
               data: config.data ? config.data.data : [],
               valueKey: "name",
               titleKey: "name",
               searchKey: "name",
               value: config.data ? config.data.selectedPanel : "",
               change: config.onPanelTypeChange,
               addMessage: "Add Panel Type",
               openAddModal: config.openAddModal,
            });
         }
         return options;
      case cardTypeMapper["Run Statistic"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.deployedEquipmentValue,
               change: config.onDeployedEquipmentChange,
               disabled: config.mode === "edit" ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Card Value",
               key: "Tag",
               type: "dropdown",
               data: runStatsConstant,
               valueKey: "_id",
               titleKey: "Tag",
               searchKey: "Tag",
               change: config.onXAxisChange,
               value: config.data.cardData.cardValue.variableName,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
               disabled: config.cardTypeId ? false : true,
            },
         ];
      case cardTypeMapper["Alert by status bar chart"]:
      case cardTypeMapper["Alert by priority bar chart"]:
      case cardTypeMapper["Alert by status pie chart"]:
      case cardTypeMapper["Alert by priority pie chart"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.deployedEquipmentValue,
               change: config.onDeployedEquipmentChange,
               disabled: config.mode === "edit" ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
         ];
      case cardTypeMapper["Alert status Analytics tornado graph"]:
      case cardTypeMapper["Alert priority Analytics tornado graph"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
         ];
      case cardTypeMapper["Equipment Run Plot"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.deployedEquipmentValue,
               change: config.onDeployedEquipmentChange,
               disabled: config.mode === "edit" ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "y-axis",
               key: "yAxis",
               type: "multiDropdown",
               data: config.data ? config.data.tags : [],
               valueKey: "_id",
               titleKey: "variableNameTag",
               searchKey: "variableNameTag",
               change: config.onYAxisChange,
               value: config.data.yname,
               required: [true, addNewCardMessages.REQUIRED_FIELD, "array"],
               validator: config.validateMultiSelection,
               autoClearSearchValue: false,
            },
         ];
      case cardTypeMapper["Failure cause pie chart"]:
         return [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
            {
               label: "Card Type",
               key: "cardType",
               type: config.mode === "edi" ? "text" : "dropdown",
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.cardTypeName,
               disabled: config.mode === "edit" ? true : false,
               data: config.data && config.data.cardTypes,
               change: config.onCardTypeChange,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
            {
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.deployedEquipmentValue,
               change: config.onDeployedEquipmentChange,
               disabled: config.mode === "edit" ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            },
         ];
      case cardTypeMapper["Failure bar chart"]:
         let displayItem = [
            {
               label: "Card Title",
               key: "cardTitle",
               max: 70,
               value: config.data.cardTitleValue,
               pattern: [Name.regx, Name.message],
            },
         ];
         if (config.levelName === "equipment") {
            displayItem.push({
               label: "Equipment",
               key: "equipment",
               type: "dropdown",
               data: config.data ? config.data.deployedEquipmentList : [],
               valueKey: "_id",
               titleKey: "name",
               searchKey: "name",
               value: config.data.deployedEquipmentValue,
               change: config.onDeployedEquipmentChange,
               disabled: config.mode === "edit" ? true : false,
               required: [true, addNewCardMessages.REQUIRED_FIELD],
            });
         }
         return displayItem;
      default:
         return [];
   }
};

export const customAlertPlot = (config) => {
   return [
      {
         label: 'Card Title',
         key: 'cardTitle',
         max: 70,
         value: config.data.cardTitleValue,
         change: config.changeTrendsTitle,
         pattern: [Name.regx, Name.message],
      },
      {
         label: 'y-axis',
         key: 'yAxis',
         type: 'multiDropdown',
         data: config.data ? config.data.tags : [],
         valueKey: '_id',
         titleKey: 'variableNameTag',
         searchKey: 'variableNameTag',
         change: config.onYAxisChange,
         value: config.data.yname || [],
         required: [true, addNewCardMessages.REQUIRED_FIELD, 'array'],
         validator: config.validateMultiSelection,
         autoClearSearchValue: false,
      },
   ];
}
