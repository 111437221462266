import React from 'react';
import { Icon, Input } from 'antd';
import _ from 'lodash';
import {
  curvesColumnsTitle,
  curvesColumnsName,
} from '../../../../../constants/variables';
import { newValidateNumber } from '../../../../../libs/validator';
import { CurvesMessages } from '../../../../../constants/messages';
import * as Pattern from '../../../../../constants/pattern';
import {
  validatePositiveFloat,
  validateNonNegativeFloat,
} from '../../../../../common/methods';
import { workCoefficient } from '../../../../../libs/coefficient';

export const asTestedCurves = 'asTestedCurves';
export const operationalBaseLineCurves = 'operationalBaseLineCurves';

export const COMPONENT = {
  EXPANDER: 'Centrifugal liquid expander',
  COMPRESSOR: 'Centrifugal compressor',
  PUMP: 'Centrifugal pump',
  STEAM_TURBINE: 'Steam Turbine'
};

/**
 *
 */
export const APPLICATIONS = ['Fixed Speed', 'Variable Speed'];

/**
 *
 */
export const PROCESSES = ['Polytropic', 'Adiabatic'];

/**
 *
 */
export const curvesMapper = {
  curve1: 'Curve 1',
  curve2: 'Curve 2',
  curve3: 'Curve 3',
  curve4: 'Curve 4',
  curve5: 'Curve 5',
  curve6: 'Curve 6',
  curve7: 'Curve 7',
  curve8: 'Curve 8',
};

/**
 *
 */
export const DataUnit = [
  'Speed',
  'Volumetric Flow',
  'Head Drop',
  'Efficiency',
  'Diameter',
  'Throttle steam mass flow',
  'Output Power',
  'IGV',
  'Work Input'
];

/**
 *
 */
export const dataUnitsVariables = {
  speed: 'Speed',
  volumetricFlow: 'Volumetric Flow',
  headDrop: 'Head Drop',
  efficiency: 'Efficiency',
  diameter: 'Diameter',
  identifier: 'identifier',
  igv: 'IGV',
  work: 'Work Input'
};

export const variablesMapper = {
  value: 'value',
  speed: 'speed',
  speedOperational: 'speedOperational',
  volumetricFlow: 'volumetricFlow',
  headDrop: 'headDrop',
  efficiency: 'efficiency',
  flowCoefficient: 'flowCoefficient',
  headCoefficient: 'headCoefficient',
  workInput: 'workInput',
};

/**
 *
 */
export const curvesLabel = {
  operationBaselineCurve: 'Operational Baseline Curve',
  selectUnits: 'Select Units',
  asTestedCurve: 'AS-Tested Curve',
};

/**
 *
 */
export const graphDetails = {
  title: 'Curve Plots',
  xAxis: 'Flow Coefficient',
  yAxis: 'Head Coefficient',
  requiredMessage: 'Mininum three data is required',
  asTestedCurve: 'AS-Tested Curve',
  operationalBasedCurve: 'Operational Baseline Curve',
  efficiency: 'Efficiency',
  flowCoefficient: 'Flow Coefficient',
  headCoefficient: 'Head Coefficient',
  headDrop: 'Head Drop',
  volumetricFlow: 'Volumetric Flow',
  workInput: 'Work Input',
  throttleSteamMassFlow: 'Throttle Steam Mass Flow',
  outputPower: 'Output Power',
  suctionSlideValvePosition: "Suction Slide Valve Position",
  volumetricCapacityMultiplier: "Volumetric Capacity Multiplier",
  volumeRatioMultiplier: "Volume Ratio Multiplier",
  tipSpeed: "Tip Speed",
  adiabaticEfficiency: "Adiabatic Efficiency",
  copyDataToOperational: "Copy AS-Tested data to Operational",
  slideValvePosition: "Slide Valve Position"
};

export const identifierDataOption = ["Speed", "IGV", "DGV"];

/**
 *
 * @param {*} units
 * @param {*} key
 */
export const getUnit = (units, key) => {
  if (units && key) {
    for (let i = 0; i < units.length; i += 1) {
      if (units[i].dataVariable === key) {
        return units[i].units;
      }
    }
  }
  return [];
};

/**
 *
 * @param {*} units
 * @param {*} config
 */
export const AsTesteCurveFormField = (units, config) => {
  let formArray = [
    {
      label: 'Speed',
      key: 'speed',
      type: 'textWithDrop',
      required: [true, CurvesMessages.REQUIRED_FIELD],
      pattern: [Pattern.PositiveNumber, Pattern.NegativeNumberMessage],
      value: '0',
      dropDownKey: 'unitSpeed',
      dropDownData: getUnit(units, 'Speed'),
      dropDownDefaultValue: getUnit(units, 'Speed')[1],
      dropDownchange: (e) => {
        config.change(e, DataUnit[0]);
      },
      change: (e) => {
        config.change(e, DataUnit[0]);
      },
      onKeyDown: (e) => {
        newValidateNumber(e, e.target.value);
      },
      disabled: !config.isCurveEditable,
      style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
              : ((config.identifierKey === 'Speed') ? { width: "20%" }
              : { width: "18%" })
    },
    {
      label: 'Volumetric Flow',
      key: 'volumetricFlow',
      type: 'dropdown',
      required: [true, ''],
      value: getUnit(units, 'Volumetric Flow')[0],
      data: getUnit(units, 'Volumetric Flow'),
      change: (e) => {
        config.change(e, DataUnit[1]);
      },
      disabled: !config.isCurveEditable,
      style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
              : ((config.identifierKey === 'Speed') ? { width: "20%" }
              : { width: "16%" })
    },
    {
      label:
        config.name === COMPONENT.COMPRESSOR
          ? curvesColumnsTitle.compressorHead
          : config.name === COMPONENT.PUMP
            ? 'Head'
            : curvesColumnsTitle.headDrop,
      key: 'headDrop',
      type: 'dropdown',
      required: [true, ''],
      value: getUnit(units, 'Head Drop')[0],
      data: getUnit(units, 'Head Drop'),
      change: (e) => {
        config.change(e, DataUnit[2]);
      },
      disabled: !config.isCurveEditable,
      style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
              : ((config.identifierKey === 'Speed') ? { width: "20%" }
              : { width: "16%" })
    },
    {
      label:
        config.name === COMPONENT.COMPRESSOR
          ? curvesColumnsTitle.compressorEfficiency
          : curvesColumnsTitle.efficiency,
      key: 'efficiency',
      type: 'dropdown',
      required: [true, ''],
      value: getUnit(units, 'Efficiency')[0],
      data: getUnit(units, 'Efficiency'),
      change: (e) => {
        config.change(e, DataUnit[3]);
      },
      disabled: !config.isCurveEditable,
      style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
              : ((config.identifierKey === 'Speed') ? { width: "20%" }
              : { width: "16%" })
    },
  ];
  if(config.name === COMPONENT.COMPRESSOR)  {
    formArray.push({
        label: 'Work Input',
        key: 'workInput',
        type: 'dropdown',
        required: [true, ''],
        value: getUnit(units, 'Work Input')[0],
        data: getUnit(units, 'Work Input'),
        change: (e) => {
          config.change(e, DataUnit[8]);
        },
        disabled: !config.isCurveEditable,
        style: ((config.identifierKey === 'Speed') ? { width: "20%" }
        : { width: "16%" })
      })
    if(config.identifierKey !== 'Speed')  {
      formArray.splice(1,0,
        {
          label: 'IGV/DGV Value',
          key: 'identifierValue',
          type: 'textWithDrop',
          required: [true, CurvesMessages.REQUIRED_FIELD],
          pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
          value: config?.identifierValue?.value || '0',
          dropDownKey: 'unitIdentifier',
          dropDownData: getUnit(units, 'IGV'),
          dropDownDefaultValue: getUnit(units, 'IGV')[0],
          style: { width: "18%" }
        });
    }
  }
  return formArray;
}

/**
 *
 * @param {*} units
 * @param {*} config
 */
export const AsTesteCurveFormFieldSteamTurbine = (units, config) => [
  {
    label: 'Speed',
    key: 'speed',
    type: 'textWithDrop',
    required: [true, CurvesMessages.REQUIRED_FIELD],
    pattern: [Pattern.PositiveNumber, Pattern.NegativeNumberMessage],
    value: '0',
    dropDownKey: 'unitSpeed',
    dropDownData: getUnit(units, 'Speed'),
    dropDownDefaultValue: getUnit(units, 'Speed')[1],
    dropDownchange: (e) => {
      config.change(e, DataUnit[0]);
    },
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    onKeyDown: (e) => {
      newValidateNumber(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: 'Throttle Steam Mass Flow',
    key: 'throttleSteamMassFlow',
    type: 'dropdown',
    required: [true, ''],
    // TBF
    value: getUnit(units, 'Throttle steam mass flow')[0],
    data: getUnit(units, 'Throttle steam mass flow'),
    change: (e) => {
      config.change(e, DataUnit[5]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: 'Output Power',
    key: 'outputPower',
    type: 'dropdown',
    required: [true, ''],
    // TBF
    value: getUnit(units, 'Output Power')[0],
    data: getUnit(units, 'Output Power'),
    change: (e) => {
      config.change(e, DataUnit[6]);
    },
    disabled: !config.isCurveEditable,
  },
];

/**
 *
 * @param {*} units
 * @param {*} config
 */
export const AsTesteCurveFormFieldOperational = (units, config) => {
  let formArray = [{
    label: 'Speed',
    key: 'speedOperational',
    type: 'textWithDrop',
    required: [true, CurvesMessages.REQUIRED_FIELD],
    pattern: [Pattern.PositiveNumber, Pattern.NegativeNumberMessage],
    value: '0',
    dropDownKey: 'unitSpeed',
    dropDownData: getUnit(units, 'Speed'),
    dropDownDefaultValue: getUnit(units, 'Speed')[1],
    dropDownchange: (e) => {
      config.change(e, DataUnit[0]);
    },
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    onKeyDown: (e) => {
      newValidateNumber(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
    style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
            : ((config.identifierKey === 'Speed') ? { width: "20%" }
            : { width: "18%" })
  },
  {
    label: 'Volumetric Flow',
    key: 'volumetricFlow',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'Volumetric Flow')[0],
    data: getUnit(units, 'Volumetric Flow'),
    change: (e) => {
      config.change(e, DataUnit[1]);
    },
    disabled: !config.isCurveEditable,
    style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
            : ((config.identifierKey === 'Speed') ? { width: "20%" }
            : { width: "16%" })
  },
  {
    label:
      config.name === COMPONENT.COMPRESSOR
        ? curvesColumnsTitle.compressorHead
        : config.name === COMPONENT.PUMP
          ? 'Head'
          : curvesColumnsTitle.headDrop,
    key: 'headDrop',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'Head Drop')[0],
    data: getUnit(units, 'Head Drop'),
    change: (e) => {
      config.change(e, DataUnit[2]);
    },
    disabled: !config.isCurveEditable,
    style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
            : ((config.identifierKey === 'Speed') ? { width: "20%" }
            : { width: "16%" })
  },
  {
    label:
      config.name === COMPONENT.COMPRESSOR
        ? curvesColumnsTitle.compressorEfficiency
        : curvesColumnsTitle.efficiency,
    key: 'efficiency',
    type: 'dropdown',
    required: [true, ''],
    value: getUnit(units, 'Efficiency')[0],
    data: getUnit(units, 'Efficiency'),
    change: (e) => {
      config.change(e, DataUnit[3]);
    },
    disabled: !config.isCurveEditable,
    style: config.name !== COMPONENT.COMPRESSOR ? { width: "25%" }
            : ((config.identifierKey === 'Speed') ? { width: "20%" }
            : { width: "16%" })
  },
  ];
  if (config.name === COMPONENT.COMPRESSOR) {
    formArray.push({
        label: 'Work Input',
        key: 'workInput',
        type: 'dropdown',
        required: [true, ''],
        value: getUnit(units, 'Work Input')[0],
        data: getUnit(units, 'Work Input'),
        change: (e) => {
          config.change(e, DataUnit[8]);
        },
        disabled: !config.isCurveEditable,
        style: ((config.identifierKey === 'Speed') ? { width: "20%" }
        : { width: "16%" })
      })
    if(config.identifierKey !== 'Speed')  {
      formArray.splice(1, 0,
        {
          label: 'IGV/DGV Value',
          key: 'identifierValue',
          type: 'textWithDrop',
          required: [true, CurvesMessages.REQUIRED_FIELD],
          pattern: [Pattern.FloatNumber, Pattern.NegativeNumberMessage],
          value: config?.identifierValue?.value || '0',
          dropDownKey: 'unitIdentifier',
          dropDownData: getUnit(units, 'IGV'),
          dropDownDefaultValue: getUnit(units, 'IGV')[0],
          style: { width: "18%" }
        });
    }
  }
  return formArray;
}

/**
 *
 * @param {*} units
 * @param {*} config
 */
export const AsTesteCurveFormFieldOperationalSteamTurbine = (units, config) => [
  {
    label: 'Speed',
    key: 'speedOperational',
    type: 'textWithDrop',
    required: [true, CurvesMessages.REQUIRED_FIELD],
    pattern: [Pattern.PositiveNumber, Pattern.NegativeNumberMessage],
    value: '0',
    dropDownKey: 'unitSpeed',
    dropDownData: getUnit(units, 'Speed'),
    dropDownDefaultValue: getUnit(units, 'Speed')[1],
    dropDownchange: (e) => {
      config.change(e, DataUnit[0]);
    },
    change: (e) => {
      config.change(e, DataUnit[0]);
    },
    onKeyDown: (e) => {
      newValidateNumber(e, e.target.value);
    },
    disabled: !config.isCurveEditable,
  },
  {
    label: 'Throttle Steam Mass Flow',
    key: 'throttleSteamMassFlow',
    type: 'dropdown',
    required: [true, ''],
    // TBF
    value: getUnit(units, 'Throttle steam mass flow')[0],
    data: getUnit(units, 'Throttle steam mass flow'),
    change: (e) => {
      config.change(e, DataUnit[5]);
    },
    disabled: !config.isCurveEditable,
  },
  {
    // label: config.name === 'Centrifugal pump' ? 'Head' :'Head Drop',
    label: 'Output Power',
    key: 'outputPower',
    type: 'dropdown',
    required: [true, ''],
    //TBF
    value: getUnit(units, 'Output Power')[0],
    data: getUnit(units, 'Output Power'),
    change: (e) => {
      config.change(e, DataUnit[6]);
    },
    disabled: !config.isCurveEditable,
  },
];

/**
 *
 * @param {*} config
 */

function truncateDecimals(num, decimals) {
  const multiplier = Math.pow(10, decimals);
  return Math.floor(num * multiplier) / multiplier;
}

export const curvesTableColumns = (config) => [
  {
    title: curvesColumnsTitle.volumetricFlow,
    dataIndex: curvesColumnsName.volumetricFlow,
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.volumetricFlow}
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.onChange(row, col, e.target);
          }}
          disabled={editable}
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
          className={
            !_.isEmpty(value) && validateNonNegativeFloat(value)
              ? 'not-error'
              : 'error'
          }
        />
      );
    },
  },
  {
    title:
      config.name === COMPONENT.COMPRESSOR
        ? curvesColumnsTitle.compressorHead
        : curvesColumnsTitle.headDrop,
    dataIndex: curvesColumnsName.headDrop,
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.headDrop}
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.onChange(row, col, e.target);
          }}
          disabled={editable}
          autoComplete="off"
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
          className={
            !_.isEmpty(value) && validatePositiveFloat(value)
              ? 'not-error'
              : 'error'
          }
        />
      );
    },
  },
  {
    title:
      config.name === COMPONENT.COMPRESSOR
        ? curvesColumnsTitle.compressorEfficiency
        : curvesColumnsTitle.efficiency,
    dataIndex: curvesColumnsName.Efficiency,
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.Efficiency}
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.onChange(row, col, e.target);
          }}
          disabled={editable}
          autoComplete="off"
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
          className={
            !_.isEmpty(value) && validateNonNegativeFloat(value)
              ? 'not-error'
              : 'error'
          }
        />
      );
    },
  },
  {
    title: curvesColumnsTitle.flowCoefficient,
    dataIndex: curvesColumnsName.flowCoefficient,
    render: (value) => (
      // Added Later
      <div>{isFinite(value) && truncateDecimals(value, 6)}</div>
    ),
  },
  {
    title: curvesColumnsTitle.headCoefficient,
    dataIndex: curvesColumnsName.headCoefficient,
    render: (value) => (
      // Added Later
      <div>{isFinite(value) && truncateDecimals(value, 6)}</div>
    ),
  },
  {
    title: curvesColumnsTitle.workInputCoefficient,
    dataIndex: curvesColumnsName.workInput,
    render: (value) => (
      // Added Later
      <div>{isFinite(value) && truncateDecimals(value, 6)}</div>
    ),
  },
  {
    title: curvesColumnsTitle.work,
    dataIndex: curvesColumnsName.work,
    render: (value) => (
      <div>{isFinite(value) && truncateDecimals(value, 6)}</div>
    ),
  },
  config.isCurveEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={() => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];
/**
 *
 * @param {*} config
 */
export const curvesTableColumnsSteamTurbine = (config) => [
  {
    title: curvesColumnsTitle.throttleSteamFlow,
    dataIndex: curvesColumnsName.throttleSteamFlow,
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.throttleSteamFlow}
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.onChange(row, col, e.target);
          }}
          disabled={editable}
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
          className={
            !_.isEmpty(value) && validateNonNegativeFloat(value)
              ? 'not-error'
              : 'error'
          }
        />
      );
    },
  },
  {
    title: curvesColumnsTitle.outputPower,
    dataIndex: curvesColumnsName.outputPower,
    render: (value, row, col, editable) => {
      value = _.isNil(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.outputPower}
          defaultValue={value}
          value={value}
          onChange={(e) => {
            config.onChange(row, col, e.target);
          }}
          disabled={editable}
          autoComplete="off"
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
          className={
            !_.isEmpty(value) && validatePositiveFloat(value)
              ? 'not-error'
              : 'error'
          }
        />
      );
    },
  },
  config.isCurveEditable && {
    title: 'Actions',
    dataIndex: 'actions',
    render: (value, row, col, disabled) => (
      <div className="more-items">
        {disabled ? (
          <button
            onClick={() => {
              config.edit(row);
            }}
          >
            <Icon type="edit" />
          </button>
        ) : null}
        {!disabled ? (
          <button
            onClick={(e) => {
              config.undo(row);
            }}
          >
            <Icon type="undo" />
          </button>
        ) : null}
        {
          <button
            onClick={(e) => {
              config.deleteRow(row);
            }}
          >
            <Icon type="delete" />
          </button>
        }
      </div>
    ),
  },
];

/**
 *
 * @param {*} actions
 */
export const curvesTableAddRow = (actions) => [
  {
    title: curvesColumnsTitle.volumetricFlow,
    dataIndex: curvesColumnsName.volumetricFlow,
    render: (value, row, col, editable) => {
      value = _.isNil(value) || _.isNaN(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.volumetricFlow}
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            actions.onChange(row, col, e.target);
          }}
          value={value}
          className={
            actions.isError[curvesColumnsName.volumetricFlow]
              ? 'error'
              : 'not-error'
          }
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
        />
      );
    },
  },
  {
    title: curvesColumnsTitle.headDrop,
    dataIndex: curvesColumnsName.headDrop,
    render: (value, row, col, editable) => {
      value = _.isNil(value) || _.isNaN(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.headDrop}
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            actions.onChange(row, col, e.target);
          }}
          value={value}
          className={
            actions.isError[curvesColumnsName.headDrop] ? 'error' : 'not-error'
          }
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
        />
      );
    },
  },
  {
    title: curvesColumnsTitle.efficiency,
    dataIndex: curvesColumnsName.Efficiency,
    render: (value, row, col) => {
      value = _.isNil(value) || _.isNaN(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.Efficiency}
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            actions.onChange(row, col, e.target);
          }}
          value={value}
          className={
            actions.isError[curvesColumnsName.Efficiency]
              ? 'error'
              : 'not-error'
          }
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
        />
      );
    },
  },
  {
    title: curvesColumnsTitle.flowCoefficient,
    dataIndex: curvesColumnsName.flowCoefficient,
  },
  {
    title: curvesColumnsTitle.headCoefficient,
    dataIndex: curvesColumnsName.headCoefficient,
  },
  {
    title: curvesColumnsTitle.workInputCoefficient,
    dataIndex: curvesColumnsName.workInput,
  },
  {
    title: curvesColumnsTitle.work,
    dataIndex: curvesColumnsName.work,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: () => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];
/**
 *
 * @param {*} actions
 */
export const curvesTableAddRowSteamTurbine = (actions) => [
  {
    title: curvesColumnsTitle.throttleSteamFlow,
    dataIndex: curvesColumnsName.throttleSteamFlow,
    render: (value, row, col, editable) => {
      value = _.isNil(value) || _.isNaN(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.throttleSteamFlow}
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            actions.onChange(row, col, e.target);
          }}
          value={value}
          className={
            actions.isError[curvesColumnsName.throttleSteamFlow]
              ? 'error'
              : 'not-error'
          }
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
        />
      );
    },
  },
  {
    title: curvesColumnsTitle.outputPower,
    dataIndex: curvesColumnsName.outputPower,
    render: (value, row, col, editable) => {
      value = _.isNil(value) || _.isNaN(value) ? '' : value.toString();
      return (
        <Input
          name={curvesColumnsName.outputPower}
          defaultValue={value}
          autoComplete="off"
          onChange={(e) => {
            actions.onChange(row, col, e.target);
          }}
          value={value}
          className={
            actions.isError[curvesColumnsName.outputPower]
              ? 'error'
              : 'not-error'
          }
          onKeyDown={(e) => {
            newValidateNumber(e, e.target.value);
          }}
        />
      );
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: () => (
      <div>
        <button
          className="btn-default btn-small"
          onClick={(e) => {
            actions.submit(e.target);
          }}
        >
          Add
        </button>
      </div>
    ),
  },
];

/**
 *
 * @param {*} name
 * @param {*} application
 */
export const getMaxCurves = (name, application, identifierKey=null) => {
  if (application === 'Fixed Speed' && identifierKey === 'Speed') {
    return 1;
  }
  switch (name) {
    case COMPONENT.COMPRESSOR:
    case COMPONENT.STEAM_TURBINE:
      return 8;
    case COMPONENT.PUMP:
      return 6;
    default:
      return 1;
  }
};

/**
 *
 * @param {*} name
 * @param {*} configData
 */
export const getCurveData = (name, configData) => {
  if (name === COMPONENT.EXPANDER) {
    if (
      configData &&
      configData.curvesData &&
      configData.curvesData.asTestedCurves &&
      configData.curvesData.asTestedCurves.length &&
      configData.curvesData.operationalBaseLineCurves &&
      configData.curvesData.operationalBaseLineCurves.length
    ) {
      const { curvesData } = configData;
      const { asTestedCurves, operationalBaseLineCurves } = curvesData;

      const speed = {
        value: asTestedCurves[0].speed.value,
        unit: asTestedCurves[0].speed.unit,
      };
      const speedOperational = {
        value: operationalBaseLineCurves[0].speed.value,
        unit: operationalBaseLineCurves[0].speed.unit,
      };
      const dataUnit = {
        volumetricFlow: asTestedCurves[0].volumetricFlow.unit,
        headDrop: asTestedCurves[0].headDrop.unit,
        efficiency: asTestedCurves[0].efficiency.unit,
      };
      const data = asTestedCurves.map((i) => ({
        volumetricFlow: i.volumetricFlow.value,
        headDrop: i.headDrop.value,
        efficiency: i.efficiency.value,
        flowCoefficient: i.flowCoefficient.value,
        headCoefficient: i.headCoefficient.value,
        workInput: i.workInput.value,
      }));
      const dataOperational = operationalBaseLineCurves.map((i) => ({
        volumetricFlow: i.volumetricFlow.value,
        headDrop: i.headDrop.value,
        efficiency: i.efficiency.value,
        flowCoefficient: i.flowCoefficient.value,
        headCoefficient: i.headCoefficient.value,
        workInput: i.workInput.value,
      }));
      const percentData =!_.isUndefined(
        operationalBaseLineCurves[0].percentageDeviation
        ) ?
        operationalBaseLineCurves[0].percentageDeviation.lastOperationalBaseLineCurvesData
        : [];

      return {
        curve1: {
          asTestedCurves: {
            speed,
            dataUnit,
            data,
          },
          operationalBaseLineCurves: {
            speed: speedOperational,
            dataUnit,
            data: dataOperational,
             percentageDeviation:{
              lastOperationalBaseLineCurvesData:percentData
             }            
          },
        },
      };
    }
    return null;
  }
  else if(name === COMPONENT.STEAM_TURBINE)
  {
    if (
      configData?.curvesData?.designGuaranteeCurve?.curvesData?.curve1?.asTestedCurves &&
      configData?.curvesData?.designGuaranteeCurve?.curvesData?.curve1?.operationalBaseLineCurves
    ) {
        return configData?.curvesData?.designGuaranteeCurve?.curvesData
    }
  }
  return configData && configData.curvesData && configData.curvesData.curvesData
    ? configData.curvesData.curvesData
    : null;
};

/**
 *
 * @param {*} name
 * @param {*} configData
 */
export const getImpelerData = (name, configData) => {
  if (name === COMPONENT.EXPANDER) {
    return configData && configData.runnerData && configData.runnerData.data
      ? configData.runnerData.data
      : [];
  }
  return configData && configData.impellerData && configData.impellerData.data
    ? configData.impellerData.data
    : [];
};

/**
 *
 * @param {*} name
 * @param {*} formFieldVal
 * @param {*} curveId
 * @param {*} application
 * @param {*} process
 * @param {*} asTestedCurves
 * @param {*} operationalBaseLineCurves
 */
export const createCurvePayload = (
  name,
  formFieldVal,
  curveId,
  application,
  process,
  asTestedCurves = [],
  operationalBaseLineCurves = [],
  percentageDeviation,
  identifierKey
) => {

  if (name === COMPONENT.EXPANDER) {
    const { speed, speedOperational, unitSpeed, ...dataUnit } = formFieldVal;
    asTestedCurves = asTestedCurves.map((i) => ({
      application,
      speed: {
        value: parseFloat(speed),
        unit: unitSpeed,
      },
      volumetricFlow: {
        value: parseFloat(i.volumetricFlow),
        unit: dataUnit.volumetricFlow,
      },
      headDrop: {
        value: parseFloat(i.headDrop),
        unit: dataUnit.headDrop,
      },
      efficiency: {
        value: parseFloat(i.efficiency),
        unit: dataUnit.efficiency,
      },
      flowCoefficient: {
        value: parseFloat(i.flowCoefficient),
        // unit
      },
      headCoefficient: {
        value: parseFloat(i.headCoefficient),
        // unit
      },
      workInput: {
        value: parseFloat(i.workInput),
        // unit
      },
    }));
    operationalBaseLineCurves = operationalBaseLineCurves.map((i) => ({
      speed: {
        value: parseFloat(speedOperational),
        unit: unitSpeed,
      },
      volumetricFlow: {
        value: parseFloat(i.volumetricFlow),
        unit: dataUnit.volumetricFlow,
      },
      headDrop: {
        value: parseFloat(i.headDrop),
        unit: dataUnit.headDrop,
      },
      efficiency: {
        value: parseFloat(i.efficiency),
        unit: dataUnit.efficiency,
      },
      flowCoefficient: {
        value: parseFloat(i.flowCoefficient),
        // unit
      },
      headCoefficient: {
        value: parseFloat(i.headCoefficient),
        // unit
      },
      workInput: {
        value: parseFloat(i.workInput),
        // unit
      },
      percentageDeviation

    }));
    return {
      application,
      asTestedCurves,
      operationalBaseLineCurves,
      
    };
  } else if (name === 'Steam Turbine') {
    const { speed, speedOperational, unitSpeed, ...dataUnit } = formFieldVal;
    const asTestedArray = asTestedCurves.map((item) => {
      return {
        throttleSteamMassFlow: parseFloat(item.throttleSteamMassFlow),
        outputPower: parseFloat(item.outputPower),
      };
    });
    const operationalArray = operationalBaseLineCurves.map((item) => {
      return {
        throttleSteamMassFlow: parseFloat(item.throttleSteamMassFlow),
        outputPower: parseFloat(item.outputPower),
      };
    });
    let payload = {
      application,
      curveId,
      asTestedCurves: {
        speed: {
          unit: unitSpeed,
          value: parseFloat(speed) ,
        },
        dataUnit: {
          throttleSteamMassFlow: dataUnit.throttleSteamMassFlow,
          outputPower: dataUnit.outputPower,
        },
        data: asTestedArray,
      },
      operationalBaseLineCurves: {
        speed: {
          unit: unitSpeed,
          value: parseFloat(speedOperational),
        },
        dataUnit: {
          throttleSteamMassFlow: dataUnit.throttleSteamMassFlow,
          outputPower: dataUnit.outputPower,
        },
        data: operationalArray,
      },
    };
    return { designGuaranteeCurve: payload, correctionCurves: {} };
  }
  const { speed, speedOperational, unitSpeed, identifierValue, unitIdentifier, ...dataUnit } = formFieldVal;
  const dataAsTestedCurves = asTestedCurves.map((item) => {
    const {
      volumetricFlow,
      headDrop,
      efficiency,
      flowCoefficient,
      headCoefficient,
      workInput,
      work
    } = item;
    return {
      volumetricFlow: parseFloat(volumetricFlow),
      headDrop: parseFloat(headDrop),
      efficiency: parseFloat(efficiency),
      flowCoefficient: parseFloat(flowCoefficient),
      headCoefficient: parseFloat(headCoefficient),
      workInput: parseFloat(workInput),
      work: _.isFinite(work) ? parseFloat(work) : workCoefficient(headDrop, efficiency)
    };
  });

  const dataBaseLineCurves = operationalBaseLineCurves.map((item) => {
    const {
      volumetricFlow,
      headDrop,
      efficiency,
      flowCoefficient,
      headCoefficient,
      workInput,
      work
    } = item;
    return {
      volumetricFlow: parseFloat(volumetricFlow),
      headDrop: parseFloat(headDrop),
      efficiency: parseFloat(efficiency),
      flowCoefficient: parseFloat(flowCoefficient),
      headCoefficient: parseFloat(headCoefficient),
      workInput: parseFloat(workInput),
      work: _.isFinite(work) ? parseFloat(work) : workCoefficient(headDrop, efficiency)
    };
  });
  let finalPayload = {
    curveId,
    process,
    application,
    asTestedCurves: {
      speed: {
        value: parseFloat(speed),
        unit: unitSpeed,
      },
      dataUnit,
      data: dataAsTestedCurves,
    },
    operationalBaseLineCurves: {
      speed: {
        value: parseFloat(speedOperational),
        unit: unitSpeed,
      },
      dataUnit,
      data: dataBaseLineCurves,
    },
  }
  if(name === COMPONENT.COMPRESSOR || name === COMPONENT.PUMP)  {
    finalPayload['operationalBaseLineCurves']['percentageDeviation'] = percentageDeviation;
    if(name === COMPONENT.COMPRESSOR) {
      percentageDeviation = seedWorkInput(percentageDeviation);
      finalPayload['identifierKey'] = identifierKey;
      finalPayload['asTestedCurves']['identifier'] = {
        value: identifierKey === 'Speed' ? 0 : parseFloat(identifierValue),
        unit: identifierKey === 'Speed' ? "%/100" : unitIdentifier
      };
      finalPayload['operationalBaseLineCurves']['identifier'] = {
        value: identifierKey === 'Speed' ? 0 : parseFloat(identifierValue),
        unit: identifierKey === 'Speed' ? "%/100" : unitIdentifier
      };
    }
  }
  return finalPayload;
};

/**
 *
 */
export const initialValue = (units) => ({
  asTestedCurves: {
    speed: {
      value: '0',
      unit: units && units.items && getUnit(units.items, 'Speed')[0],
    },
    identifier: {
      value: '0',
      unit: units && units.items && getUnit(units.items, 'IGV')[0]
    },
    dataUnit: {
      volumetricFlow:
        units && units.items && getUnit(units.items, 'Volumetric Flow')[0],
      headDrop: units && units.items && getUnit(units.items, 'Head Drop')[0],
      efficiency: units && units.items && getUnit(units.items, 'Efficiency')[0],
      outputPower: units && units.items && getUnit(units.items, 'Output Power')[0],
      throttleSteamMassFlow: units && units.items && getUnit(units.items, 'Throttle steam mass flow')[0],
      workUnit: units && units.items && getUnit(units.items, 'Work Input')[0],
    },
    data: [],
  },
  operationalBaseLineCurves: {
    speed: {
      value: '0',
      unit: units && units.items && getUnit(units.items, 'Speed')[0],
    },
    dataUnit: {
      volumetricFlow:
        units && units.items && getUnit(units.items, 'Volumetric Flow')[0],
      headDrop: units && units.items && getUnit(units.items, 'Head Drop')[0],
      efficiency: units && units.items && getUnit(units.items, 'Efficiency')[0],
      outputPower: units && units.items && getUnit(units.items, 'Output Power')[0],
      throttleSteamMassFlow: units && units.items && getUnit(units.items, 'Throttle steam mass flow')[0],
      workUnit: units && units.items && getUnit(units.items, 'Work Input')[0],
    },
    data: [],
    percentageDeviation:{
      lastOperationalBaseLineCurvesData:[]
    }
  },
});

export const curveInput = (data, xAxisValue, yAxisValue, config) => {
  return [
    {
      label: 'X Axis',
      key: 'xAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: xAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.changeX(e);
      },
    },
    {
      label: 'Y Axis',
      key: 'yAxis',
      type: 'dropdown',
      required: [true, ''],
      data: data,
      value: yAxisValue,
      style: { width: '200px', padding: '10px' },
      change: (e) => {
        config.changeY(e);
      },
    },
  ];
};

export const curveVariables = {
  'Efficiency': 'efficiency',
  'Flow Coefficient': 'flowCoefficient',
  'Head Coefficient': 'headCoefficient',
  'Head Drop': 'headDrop',
  'Volumetric Flow': 'volumetricFlow',
  'Work Input': 'workInput',
  'Throttle Steam Mass Flow': 'throttleSteamMassFlow',
  'Output Power': 'outputPower'
};

export const fillFixedSpeedCurve = (configData, units) => ({
  asTestedCurves: {
    speed: {
      value: configData.curvesData.curvesData.curve1.asTestedCurves.speed.value,
      unit: configData.curvesData.curvesData.curve1.asTestedCurves.speed.unit,
    },
    identifier: {
      value: '0',
      unit: units && units.items && getUnit(units.items, 'IGV')[0]
    },
    dataUnit: {
      volumetricFlow:
        units && units.items && getUnit(units.items, 'Volumetric Flow')[0],
      headDrop: units && units.items && getUnit(units.items, 'Head Drop')[0],
      efficiency: units && units.items && getUnit(units.items, 'Efficiency')[0],
      outputPower: units && units.items && getUnit(units.items, 'Output Power')[0],
      throttleSteamMassFlow: units && units.items && getUnit(units.items, 'Throttle steam mass flow')[0]
    },
    data: [],
  },
  operationalBaseLineCurves: {
    speed: {
      value: configData.curvesData.curvesData.curve1.operationalBaseLineCurves.speed.value,
      unit: configData.curvesData.curvesData.curve1.operationalBaseLineCurves.speed.unit,
    },
    dataUnit: {
      volumetricFlow:
        units && units.items && getUnit(units.items, 'Volumetric Flow')[0],
      headDrop: units && units.items && getUnit(units.items, 'Head Drop')[0],
      efficiency: units && units.items && getUnit(units.items, 'Efficiency')[0],
      outputPower: units && units.items && getUnit(units.items, 'Output Power')[0],
      throttleSteamMassFlow: units && units.items && getUnit(units.items, 'Throttle steam mass flow')[0]
    },
    data: [],
  },
});

const seedWorkInput = (deviationData) =>  {
  deviationData.lastOperationalBaseLineCurvesData.forEach((items)=>{
    if(!items.work) {
      items.work = workCoefficient(items.headDrop, items.efficiency);
      items.work = parseFloat(items.work.toFixed(3));
    }
    return items;
  });
  return deviationData;
}
